import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useLocation,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Button, Grid, Badge, Chip, Hidden} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PetsIcon from '@material-ui/icons/Pets';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import withWidth from '@material-ui/core/withWidth';

import MobileNav from './MobileNavNew'
import Confirmation from './Confirmation';
import { Offline, Online } from "react-detect-offline";
import Settings from './Settings'
import Attendance from './Attendance'

import { database, auth } from '../firebase';

const useStyles = makeStyles({
  sideNav:{
    background: "linear-gradient(40deg, rgba(105,68,156,1) 0%, rgba(56,54,129,1) 54%, rgba(81,84,217,1) 100%)",
    width: "238px",
    height: "100vh",
    position: "fixed",
    color: "white",
    textAlign: "left",
    top: "0px",
    paddingTop: 40,
    zIndex: 1
  },
  sideLogo:{
    width: "100%"
  },
  buttonActive:{
    margin: "8px",
    width: "calc( 100% - 16px)",
    background: "white",
    color: Settings.mainBackgroundColor,
    fontSize: "16px",
    borderRadius: "40px",
    "&:hover":{
      background: "white",
    }
  },
  button:{
    margin: "8px",
    width: "calc( 100% - 16px)",
    background: "#ffffff00",
    color: "white",
    boxShadow: "unset",
    fontSize: "16px",
    borderRadius: "40px",
    "&:hover":{
      background: "white",
      color: Settings.mainBackgroundColor
    }
  },
});

function TopBar(props) {
  const classes = useStyles()
  const { width, userRole } = props;
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [attandanceOpen, setAttendanceOpen] = useState(false)

  const handleClose = () => setOpen(false)
  const handleAttendanceClose = () => setAttendanceOpen(false)

  useEffect(() => {
    console.log(userRole)
  })

  return(
    <Grid 
      container 
      style={{
        // padding: 8, 
        paddingLeft: width === "xs" || width === "sm" ? 0 : 236,
        paddingTop: width === "xs" || width === "sm" ? 0 : 8
      }}
    >
      <Hidden smUp>
        <Grid item xs={12} style={{backgroundColor: Settings.mainBackgroundColor, color: Settings.mainColor, textAlign: "center", padding: "4px 0px"}}>
          <p>狗寶寶 Doggie Bo Bo</p>
        </Grid>
      
        <Grid 
          item xs={12}
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 999,
            width: "100%",
            height: 52,
            paddingTop: 2,
            borderTop: "solid 1px #ededed",
            backgroundColor: "white"
          }}
        >
          <MobileNav userRole={userRole}/>
        </Grid>
      </Hidden>

      <Grid item xs={8} style={{marginTop: 6}}>
        <Online>
          <Chip
            icon={<PetsIcon style={{color: "white"}}/>}
            label={props.user.email + "@" + props.userRole}
            style={{marginLeft: 8, backgroundColor: Settings.mainBackgroundColor, color: "white"}}
          />
        </Online>
        <Offline>
          <Chip
            icon={<CancelRoundedIcon />}
            label="Disconnected"
            style={{marginLeft: 8}}
            color="secondary"
          />
        </Offline>
      </Grid>
      <Grid item xs={4} style={{textAlign:"right", paddingTop: 8}}>
        {/* <Button onClick={() => auth.signOut()}> */}
        <Button onClick={() => setAttendanceOpen(true)}>
          <AccessAlarmsIcon font="small" style={{marginRight: 6}}/>
          {width === "xs" || width === "sm" ? "" : "Attendance"}
        </Button>

        <Attendance 
          open={attandanceOpen}
          handleAttendanceClose={handleAttendanceClose}
          user={props.user}
          userRole={userRole}
        />

        <Button onClick={() => {
          setMode("logout")
          setOpen(true)
        }}>
          <LockOpenIcon font="small" style={{marginRight: 6}}/>
          {width === "xs" || width === "sm" ? "" : "Logout"}
        </Button>
        {open && <Confirmation 
          handleClose={handleClose}
          mode={mode}
          open={open}
        />}
      </Grid>
    </Grid>
  )
}

export default withWidth()(TopBar)