import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useRouteMatch, Route, useLocation, useParams } from 'react-router-dom';
import {Paper, Grid} from '@material-ui/core/';
import MaterialTable from 'material-table';

import { database, auth } from '../firebase';

function BookingRecordTable(props) {
  const {customerKey} = props
  const [sortedBookings, setSortedBookings] = useState([])

  useEffect(()=>{
    console.log(customerKey)
    database.ref(`newCustomers/${customerKey}/BookingRecord`).once("value", (snapshot) =>{
      let record = snapshot.val()
      //["2103-1-6", "213-1-5"].map(...)
      console.log(record)
      if(!!record){
        setSortedBookings(Object.keys(record).sort((a,b) => parseInt(b) - parseInt(a)).map(v => record[v]))
      }
      else{
        setSortedBookings([])
      }
      console.log(record)
    })

    // let ids = [1,4]
    // let bookings = ids.map(id => new Promise((resolve, reject) =>{
    //   database.ref(`/bookings/${id}`).once("value", (snapshot) => {
    //     resolve(snapshot.val())
    //   })
    // }))
    // let d1_bookings = new Promise((resolve, reject) =>{
    //   database.ref(`/bookings/d1`).once("value", (snapshot) => {
    //     resolve(snapshot.val())
    //   })
    // })
    // Promise.all([bookings]).then((values) =>{
    //   console.log(values)
    // })
    // Promise.all([d1_bookings]).then((values) =>{
    //   console.log(values[0])
    // })
  }, [customerKey])
  
  
  return (
    <>
    <Grid item xs={12}>
      <h3 style={{textAlign: "center"}}>Booking Records</h3>
    </Grid>
    <MaterialTable
      title=""
      style={{
        width: "100%"
      }}
      components={{
        Container: props => <Paper {...props} elevation={0}/>
      }}
      columns={[
        { 
          title: 'Booked Date', 
          field: 'Date'
        },
        { 
          title: 'Pet Name', 
          field: 'PetName',
          render: rowData => {
            return(
              rowData.PetName?.split(",").map(v => <p style={{margin: "4px 0px", borderBottom: "solid 1px #ededed"}}>{v}</p>)
            )
          }
        },
        { 
          title: 'Pet Breed', 
          field: 'PetBreed',
          render: rowData => {
            return(
              rowData.PetBreed?.split(",").map(v => <p style={{margin: "4px 0px", borderBottom: "solid 1px #ededed"}}>{v}</p>)
            )
          }
        },
        { 
          title: 'Items', 
          field: 'Items',
          render: rowData => {
            return(
              rowData.Items?.split(",").map(v => <p style={{margin: "4px 0px", borderBottom: "solid 1px #ededed"}}>{v}</p>)
            )
          }
        },
        { 
          title: 'Price', 
          field: 'Price',
          render: rowData => {
            return(
              rowData.Price?.split(",").map(v => <p style={{margin: "4px 0px", borderBottom: "solid 1px #ededed"}}>{v}</p>)
            )
          }
        },
        { title: 'Notes', field: 'Notes' },
        { title: 'Handler', field: 'Handler' }
      ]}
      data={sortedBookings}        
      options={{
        exportButton: true,
        padding: "dense"
      }}
    />
    </>
  )
}

export default BookingRecordTable