import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useRouteMatch, Route, useLocation, useParams } from 'react-router-dom';
import {Paper, Grid} from '@material-ui/core/';
import MaterialTable from 'material-table';

import { database, auth } from '../firebase';

function OrderRecordTable(props) {
  const {customerKey} = props
  const [sortedOrders, setSortedOrders] = useState([])

  useEffect(()=>{
    database.ref(`newCustomers/${customerKey}`).once("value", (snapshot) =>{
      if(!!snapshot.val().OrderRecord){
      console.log(snapshot.val().OrderRecord)
      let record = snapshot.val().OrderRecord
      //["2103-1-6", "213-1-5"].map(...)
        setSortedOrders(Object.keys(record).sort((a,b) => record[b].Timestamp - record[a].Timestamp).map(v => {
          return(
            {"orderID": v, ...record[v]}
          )
        }))
      }
    })
  }, [customerKey])
  
  
  return (
    <>
    <Grid item xs={12}>
      <h3 style={{textAlign: "center"}}>Order Records</h3>
    </Grid>
    <MaterialTable
      title=""
      style={{
        width: "100%"
      }}
      components={{
        Container: props => <Paper {...props} elevation={0}/>
      }}
      columns={[
        { 
          title: 'Created', 
          field: 'Timestamp',
          render: rowData => <p>{new Date(rowData.Timestamp).toLocaleString() === "Invalid Date" ? rowData.Date : new Date(rowData.Timestamp).toLocaleString()}</p>
        },
        { title: 'Order ID', field: 'orderID' },
        { 
          title: 'Items', 
          field: 'Items',
          render: rowData => {
            return(
              rowData.Items.split(/\n/g).map((v,i) => <p key={i} style={{margin: "4px 0px", borderBottom: "solid 1px #ededed"}}>{v}</p>)
            )
          }
        },
        { title: 'Amount', field: 'Amount' },
        { title: 'Handler', field: 'Handler' }
      ]}
      data={sortedOrders}        
      options={{
        exportButton: true,
        padding: "dense"
      }}
    />
    </>
  )
}

export default OrderRecordTable