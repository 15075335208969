import React, { useState, useEffect, memo, useMemo } from 'react';
import { useRouteMatch, Route, useLocation } from 'react-router-dom';
import "../style.css";
import { Button, Grid, Avatar, Chip, Paper, Zoom, Backdrop, CircularProgress, ButtonGroup, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Card, CardContent, CardActions, Typography } from '@material-ui/core';
import GoogleLogin from 'react-google-login';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import withWidth from '@material-ui/core/withWidth';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import PetsIcon from '@material-ui/icons/Pets';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import { motion } from "framer-motion";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// components
import Confirmation from '../components/Confirmation';
import Settings from '../components/Settings'
import Chatbot from '../components/Chatbot'

// import Notify from '../components/Notify';
import { database, auth } from '../firebase';


const useStyles = makeStyles((theme) => ({
  activeShopBtn: {
    background: "#5e5ecc",
    color: "white"
  },
  cardRoot:{
    width: "100%",
    boxShadow: "0 5px 10px #8abfd629, 0 15px 40px rgba(166,173,201,.2)",
    // height: 288
  },
  cardRootNoBooking:{
    width: "100%",
    boxShadow: "0 5px 10px #ff050529, 0 15px 40px #ff191933"
  },
}));

const afterDays = (viewDate, days) => {
  return {
    timestamp: (new Date(viewDate).getTime()) + 86400000 * days + Math.abs(new Date().getTimezoneOffset() / 60) * 3600000,
    dateObj: new Date((new Date(viewDate).getTime()) + 86400000 * days + Math.abs(new Date().getTimezoneOffset() / 60) * 3600000)
  }
}

const toChineseDays = {
  0: "日",
  1: "一",
  2: "二",
  3: "三",
  4: "四",
  5: "五",
  6: "六"
}

const BookingsTable = memo((props) => {
  const {shop, viewDate, childChangeDate, openModal} = props
  const [lists, setLists] = useState([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [backdropOpen, setBackdropOpen] = useState(false)
  let initialDataLoaded = false
  const location = useLocation()
  const classes = useStyles();
  
  useEffect(() => {
    // console.log(lists)
  }, [lists])

  useEffect(() => {
    // console.log(shop)
    setBackdropOpen(true)
    let dataSnap = []
    let threeDaysData = []
    let loopingDate = ""

    database.ref(`bookings/d${shop}/${viewDate.toISOString().substring(0,10)}`).endAt().limitToLast(1).on("child_added", (data) => {
      // console.log(data.val())
      // console.log(auth.currentUser.email)
      if(initialDataLoaded && data.val().Account !== auth.currentUser.email){
        console.log(data.val().Account !== auth.currentUser.email)
        enqueueSnackbar(`A booking for ${data.val().Pet}(${data.val().Breed}) was added by ${data.val().Handler}`, { 
          variant: 'info',
        })
      }
    })

    database.ref(`bookings/d${shop}/${viewDate.toISOString().substring(0,10)}`).endAt().limitToLast(1).on("child_removed", (data) => {
      // console.log(data.val())
      // console.log(auth.currentUser.email)
      if(initialDataLoaded && data.val().Account !== auth.currentUser.email){
        console.log(data.val().Account !== auth.currentUser.email)
        enqueueSnackbar(`A booking for ${data.val().Pet}(${data.val().Breed}) was deleted`, { 
          variant: 'warning',
        })
      }
    })

    let onBooking = () => database.ref(`bookings`).on("value", (data) =>{
      console.log("Bookinggg")
      dataSnap = []
      threeDaysData = []
      for(let day = 0; day < 4; day++){
        dataSnap = []
        loopingDate = afterDays(viewDate, day).dateObj.toISOString().substring(0,10)
        database.ref(`bookings/d${shop}/${afterDays(viewDate, day).dateObj.toISOString().substring(0,10)}`).once("value", (snapshot) => {
          // console.log(afterDays(viewDate, day).dateObj.toISOString().substring(0,10))
          if(!!snapshot.val()){
            dataSnap = Object.values(snapshot.val()).sort((a, b) => a.Time.localeCompare(b.Time)).map((v) => {
              return { 
                Customer: v.Customer,
                CustomerId: v.CustomerId,
                BookNumber: v.BookNumber,
                Time: v.Time,
                Pet: v.Pet,
                Breed: v.Breed,
                Items: v.Items,
                Phone: v.Phone,
                Notes: v.Notes,
                Handler: v.Handler ? v.Handler : v.Person,
                Price: v.Price? v.Price : "0",
                Valid: v.Valid === undefined || v.Valid ? "Valid" : "Not Valid"
              }
            })
            threeDaysData = [...threeDaysData, {[loopingDate]: dataSnap}]
            initialDataLoaded = true
            setBackdropOpen(false)
          }
          else{
            // threeDaysData.push({[afterDays(viewDate, day).dateObj.toISOString().substring(0,10)]: [{Time: "No Booking"}]})
            threeDaysData = [...threeDaysData, {[loopingDate]: [{
              Customer: "No Booking",
              BookNumber: 2342342342,
              Time: "No",
              Pet: "Yet",
              Breed: "NoBooking",
              Items: "Bookings"
            }]}]
            // console.log("No Booking on " + afterDays(viewDate, day).dateObj.toISOString().substring(0,10))
            initialDataLoaded = true
            setBackdropOpen(false)
          }
        })
        if(day === 3){
          // console.log(threeDaysData)
          setLists(threeDaysData)
        }
      }
    })
    
    onBooking()

    return () => {
      database.ref(`bookings/d${shop}/${viewDate.toISOString().substring(0,10)}`).endAt().limitToLast(1).off()
      // database.ref(`bookings/d${shop}/${viewDate.toISOString().substring(0,10)}`).off()
      database.ref("bookings").off()
    }
  }, [location, shop, viewDate, open])

  const callFunction = () =>{
    // console.log("called function")
  }

  const ThreeDaysBookingTable = useMemo(() => 
    lists.map((v,i) => i !== 0 && 
      <Grid item xs={12} md={4} style={{padding: 8}} key={i}>
        <div style={{textAlign: "center"}}>
          <Button 
            variant="outlined"
            style={{margin:"6px 0px"}}
            onClick={() => childChangeDate((new Date(Object.keys(v)[0])))}
          >
            {`${Object.keys(v)[0]} (星期${toChineseDays[(new Date(Object.keys(v)[0]).getDay())]})`}
          </Button>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{background: "#353535", color: "white"}} align="center">#</TableCell>
                  <TableCell style={{background: "#353535", color: "white"}} align="center">Time</TableCell>
                  <TableCell style={{background: "#353535", color: "white"}} align="center">Items</TableCell>
                  <TableCell style={{background: "#353535", color: "white"}} align="center">Pet</TableCell>
                  <TableCell style={{background: "#353535", color: "white"}} align="center">Breed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(v)[0].map((booking, bookingIndex) => (
                  <TableRow 
                    style={{
                      background: booking.Breed === "NoBooking" ? "#c58c8c" : "#484848",
                      display: booking.Valid === undefined || booking.Valid === "Not Valid" ? "none" : "auto"
                    }} 
                    key={booking.BookNumber}
                  >
                    <TableCell style={{color: "white"}} align="center">{bookingIndex + 1}</TableCell>
                    <TableCell style={{color: "white"}} align="center">{booking.Time}</TableCell>
                    <TableCell style={{color: "white"}} align="center">{booking.Items}</TableCell>
                    <TableCell style={{color: "white"}} align="center">{booking.Pet}</TableCell>
                    <TableCell style={{color: "white"}} align="center">{booking.Breed === "NoBooking" ? <ReportProblemRoundedIcon/> : booking.Breed}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    ),
  [lists])

  const onDayTable = useMemo(() =>
    lists.length > 0 &&
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} style={{textAlign: "center", marginTop: 16}}>
          <p style={{fontSize: 28, fontWeight: 900}}>{Object.keys(lists[0])[0]}</p>
          {/* <p style={{fontSize: 28, fontWeight: 900}}>{lists[0]}</p> */}
        </Grid>
        { Object.values(lists[0])[0][0].Breed !== "NoBooking" ?
          Object.values(lists[0])[0].map((booking, bookingIndex) => 
            <Grid item xs={12} md={3} style={{padding: 8}} key={bookingIndex}>
              <Card className={booking.Breed !== "NoBooking" ? classes.cardRoot : classes.cardRootNoBooking}>
                <CardContent 
                  style={{
                    height: 228, 
                    overflowY: "scroll", 
                    marginBottom: 4, 
                    paddingBottom: 12, 
                    borderBottom: "solid #ededed 1px"
                  }}
                  className="cardContent"
                >
                  <Grid container style={{margin: "12px 0px"}}>
                    <Grid item xs={6}>
                      稱呼: {booking.Customer}
                    </Grid>
                    <Grid item xs={6} style={{borderLeft: "dotted #b7b7b7 2px", paddingLeft: 4}}>
                      電話: {booking.Phone}
                    </Grid>
                  </Grid>
                  <Grid container style={{margin: "12px 0px"}}>
                    <Grid item xs={6}>
                      寵物名: {booking.Pet}
                    </Grid>
                    <Grid item xs={6} style={{borderLeft: "dotted #b7b7b7 2px", paddingLeft: 4}}>
                      品種: {booking.Breed}
                    </Grid>
                  </Grid>
                  <Grid container style={{margin: "12px 0px"}}>
                    <Grid item xs={6}>
                      做法: {booking.Items}
                    </Grid>
                    <Grid item xs={6} style={{borderLeft: "dotted #b7b7b7 2px", paddingLeft: 4}}>
                      時間: {booking.Time}
                    </Grid>
                  </Grid>
                  <Grid container style={{margin: "12px 0px"}}>
                    <Grid item xs={6}>
                      登記人: {booking.Handler}
                    </Grid>
                    <Grid item xs={6} style={{borderLeft: "dotted #b7b7b7 2px", paddingLeft: 4}}>
                      備註: {booking.Notes}
                    </Grid>
                  </Grid>
                  <Grid container style={{margin: "12px 0px"}}>
                    <Grid item xs={12}>
                      價錢: {booking.Price}
                    </Grid>
                  </Grid>
                  <Grid container style={{margin: "12px 0px"}}>
                    <Grid item xs={12}>
                      參考編號: {booking.BookNumber}(#{booking.CustomerId})
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button 
                    size="small"
                    style={{
                      color: "red",
                      borderColor: "red",
                      width: booking.Valid === "Not Valid" && "100%"
                    }}
                    disabled={booking.Valid === "Not Valid"}
                    variant="outlined"
                    onClick={()=>{
                      let pickedDate = Object.keys(lists[0])[0]
                      console.log(pickedDate)
                      database.ref(`bookings/d${shop}/${pickedDate}`).orderByChild("BookNumber").equalTo(booking.BookNumber).once("value", (snapshot)=>{
                        let bookingKey = Object.keys(snapshot.val())[0]
                        let getBookingData = snapshot.val()[bookingKey]
                        let combinedData = {
                          [bookingKey] : {
                            ...getBookingData, PickedDate: pickedDate
                          }
                        }
                        openModal("deleteBooking", combinedData)
                      })
                    }}
                  >
                    {booking.Valid === "Not Valid" ? "Removed and Can't Change" : "Remove"}
                  </Button>
                  {booking.Valid === "Valid" && <Button 
                    size="small"
                    style={{
                      backgroundColor: Settings.mainBackgroundColor,
                      color: Settings.mainColor,
                    }}
                    onClick={()=>{
                      let pickedDate = Object.keys(lists[0])[0]
                      database.ref(`bookings/d${shop}/${pickedDate}`).orderByChild("BookNumber").equalTo(booking.BookNumber).once("value", (snapshot)=>{
                        console.log(snapshot.val())
                        console.log(pickedDate)
                        openModal("editBooking", {...snapshot.val(), ...{originalDate: pickedDate}})
                      })
                    }}
                  >
                    Edit
                  </Button>}
                </CardActions>
              </Card>
            </Grid>
          ) : 
          <Card className={classes.cardRootNoBooking} variant="elevation">
            <CardContent style={{textAlign: "center"}}>
              Today has no booking
            </CardContent>
          </Card>
        }
      </Grid>
    </Grid>,
  [lists])
  
  return (
    <>
      <Grid container style={{paddingBottom: 36}}>
        {(Array.isArray(lists) && lists.length !== 0) && ThreeDaysBookingTable}
        {(Array.isArray(lists) && lists.length !== 0) && onDayTable}
      </Grid>
      <Backdrop open={backdropOpen} style={{zIndex: 9999}} onClick={()=>console.log(null)}>  
        <CircularProgress style={{color: "white", display: "block"}}/>
        {/* <p style={{color: "white"}}>
          Loading...
        </p> */}
      </Backdrop>
      {/* <Confirmation
        handleClose={() => setOpen(false)}
        data={bookingData}
        mode={mode}
        open={open}
      /> */}
    </>
  )
})


function Bookings(props) {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("")
  const [activeShop, setActiveShop] = useState(1)
  const [bookingData, setBookingData] = useState({})

  const [viewBookingDate, setViewBookingDate] = useState(new Date())
  const { width } = props;
  const classes = useStyles();
  
  useEffect(() => {
    document.title = `DBB Order - Bookings`;
    // database.ref("customers").once("value", (snapshot) => {
    //   setCustomerData(Object.values(snapshot.val()).map(v => v.PersonalData))
    // })
  });
  
  const [profileNamem, setProfileName] = useState("")

  // const responseGoogle = (response) => {
  //   console.log(response)
  // }

  const handleClose = () => setOpen(false)

  const handleDateChange = (date) => {
    console.log(date)
    setViewBookingDate(date)
  };

  const openModal = (openMode, foundBooking) => {
    setMode(openMode)
    setOpen(true)
    // setEditBookingData({
    //   Phone: "",
    //   Customer: "Haha",
    //   Pet: "",
    //   Breed: "",
    //   Items: "",
    //   Notes: "",
    //   Date: (new Date()).getTime(),
    //   BookHour: "11",
    //   BookMin: "00",
    //   Handler: "",
    //   CustomerId: ""
    // })
    setBookingData(foundBooking)
    console.log(foundBooking)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} maxSnack={3}>
      <Zoom in={true} timeout={width === "xs" || width === "sm" ? 300 : 1}>
        <Grid container>
          <Grid item xs={12}>
            <div style={{
              width: width === "xs" || width === "sm" ? "100%" : "calc(100% - 250px)",
              paddingLeft: width === "xs" || width === "sm" ? 0 : 244
            }}>
              <div style={{padding: 8, borderBottom: "solid 1px gainsboro"}}>
                <h1><PetsIcon style={{color: "#587af7"}}/>Bookings</h1>
                <p style={{margin: 0, fontStyle: "italic"}}>“I think best in a hot bath, with my head tilted back and my feet up high.” - Elizabeth Jane Howard</p>
              </div>
              <Grid container style={{margin: "8px 0px"}}>
                <Grid item xs={12}>
                  <motion.button 
                    whileTap={{ scale: 0.9 }}
                    style={{
                      position: (width === "xs" || width === "sm") && "fixed",
                      right: (width === "xs" || width === "sm") && 20,
                      bottom: (width === "xs" || width === "sm") && 64,
                      zIndex: (width === "xs" || width === "sm") && 999
                    }}
                  >
                    <Button 
                      style={{
                        background: Settings.mainBackgroundColor,
                        color: Settings.mainColor,
                        marginLeft: width === "xs" || width === "sm" ? 4 : 0,
                      }}
                      onClick={()=>{
                        setMode("addBooking")
                        setOpen(true)
                      }}
                    >
                      <PetsIcon style={{marginRight: 4}}/>
                      {(width !== "xs" && width !== "sm") && `Add Booking (D${activeShop})`}
                    </Button>
                  </motion.button>
                  <hr/>
                  <div style={{display: "inline", padding: 6}}>
                    <ButtonGroup color="primary" aria-label="outlined secondary button group" style={{margin: "8px 0px"}}>
                      <Button 
                        className={activeShop === 1 ? classes.activeShopBtn : classes.notActiveShopBtn}
                        onClick={()=>setActiveShop(1)}
                      >
                        D1 置樂
                      </Button>
                      <Button 
                        className={activeShop === 4 ? classes.activeShopBtn : classes.notActiveShopBtn}
                        onClick={()=>setActiveShop(4)}
                      >
                        D4 寶怡
                      </Button>
                    </ButtonGroup>
                  </div>
                  <div style={{display: "inline", padding: 6}}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      label="Booking Date"
                      format="dd/MM/yyyy"
                      style={{
                        // marginTop: 0,
                        // width: "100%"
                      }}
                      value={viewBookingDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </div>
                  <Confirmation
                    handleClose={() => setOpen(false)}
                    mode={mode}
                    shop={activeShop}
                    open={open}
                    data={bookingData}
                    viewDate={viewBookingDate}
                  />
                </Grid>
              </Grid>
              {
                <Grid container>
                  <Grid item xs={12}>
                    <BookingsTable 
                      shop={activeShop}
                      viewDate={viewBookingDate}
                      childChangeDate={(date) => handleDateChange(date)}
                      openModal={(openMode, foundBooking) => openModal(openMode, foundBooking)}
                    />
                  </Grid>
                </Grid>
              }
            </div>
          </Grid>
          

          
        </Grid>
      </Zoom>
    </MuiPickersUtilsProvider>
  );
}

export default withWidth()(Bookings)