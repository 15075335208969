import React, { useState, useEffect } from 'react';
import "../style.css";
import { Button, Grid, Backdrop, Chip, Tooltip, Zoom } from '@material-ui/core';
import GoogleLogin from 'react-google-login';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import MaterialTable from 'material-table'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import withWidth from '@material-ui/core/withWidth';
import GradeIcon from '@material-ui/icons/Grade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

// components
import Confirmation from '../components/Confirmation';
import Settings from '../components/Settings'

// import Notify from '../components/Notify';
import { database, auth } from '../firebase';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function AttendanceRecord(props) {
  const classes = useStyles();
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0)
  const [done, setDone] = useState(true)
  const [doneMsg, setDoneMsg] = useState("")
  const [userData, setUserData] = useState([])
  const { width, userRole } = props;
  const [activeUser, setActiveUser] = useState("")
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [showMonth, setShowMonth] = useState(`${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().length === 1 ? (new Date().getMonth() + 1).toString().padStart(2, "0") : (new Date().getMonth() + 1).toString()}`)
  
  useEffect(() => {
    document.title = "DBB Order - Attendance record"
    database.ref("users").on("value", (data) => {
      setUserData(data.val())
      if (userRole === "staff") {
        setActiveUser(auth.getUid())
      }
      setBackdropOpen(false)
    })
    console.log(userRole)
    return () => {
      database.ref("users").off()
    }
  }, []);

  const getDateRange = () => {
    if (activeUser && userData[activeUser].Attendance) {
      let firstDay = Object.keys(userData[activeUser].Attendance)[0]
      let lastDay = Object.keys(userData[activeUser].Attendance)[Object.keys(userData[activeUser].Attendance).length - 1]
      let dateAry = []
      for (let date of Object.keys(userData[activeUser].Attendance)) {
        if (!dateAry.includes(date.substring(0,7))) {
          dateAry.unshift(date.substring(0,7))
        }
      }
      return( 
        dateAry.map(date => 
        <Chip
          label={date}
          key={date}
          clickable
          onClick={() => setShowMonth(date)}
          style={{
            margin: "8px 4px 0px 4px",
            background: date === showMonth ? "#5e5ecc" : "white",
            border: date !== showMonth ? "solid 1px #5e5ecc" : "none",
            color: date !== showMonth ? "#5e5ecc" : "white",
            // backgroundColor: "black"
          }}
        />)
      )
    }
  }

  // useEffect(() => {
  //   let lists = Object.keys(userData[activeUser].Attendance).map(date => ({
  //     date: date,

  //   }))
  // }, [activeUser])
  

  return (
    <Zoom in={true} timeout={width === "xs" || width === "sm" ? 300 : 1}>
      <Grid container style={{paddingBottom: 32}}>
        <Backdrop open={backdropOpen} style={{zIndex: 9999}}>  
          <CircularProgress style={{color: "white", display: "block"}}/>
        </Backdrop>
        <Grid item xs={12}>
          <div style={{
            width: width === "xs" || width === "sm" ? "100%" : "calc(100% - 250px)",
            paddingLeft: width === "xs" || width === "sm" ? 0 : 244
          }}>
            <div style={{padding: 8, borderBottom: "solid 1px gainsboro"}}>
              <h1><GradeIcon style={{color: Settings.mainBackgroundColor}}/>Attendance</h1>
            </div>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    {userData && userRole === "admin" && Object.keys(userData).filter(v => ["staff"].includes(userData[v].Role))?.map(v =>
                    <Tooltip title={userData[v].Email} key={userData[v].Email}>
                      <Chip
                        label={userData[v].Name}
                        clickable
                        onClick={() => setActiveUser(v)}
                        style={{
                          margin: "8px 4px 0px 4px",
                          background: activeUser === v ? "#5e5ecc" : "white",
                          border: activeUser !== v ? "solid 1px #5e5ecc" : "none",
                          color: activeUser !== v ? "#5e5ecc" : "white",
                          // backgroundColor: "black"
                        }}
                      />
                    </Tooltip>
                    )}

                    {userData && userRole === "staff" && Object.keys(userData)
                      .filter(v => ["staff"].includes(userData[v].Role) && v === auth.getUid())
                      ?.map(v =>
                        <Tooltip title={userData[v].Email} key={userData[v].Email}>
                          <Chip
                            label={userData[v].Name}
                            clickable
                            onClick={() => setActiveUser(v)}
                            style={{
                              margin: "8px 4px 0px 4px",
                              background: activeUser === v ? "#5e5ecc" : "white",
                              border: activeUser !== v ? "solid 1px #5e5ecc" : "none",
                              color: activeUser !== v ? "#5e5ecc" : "white",
                              // backgroundColor: "black"
                            }}
                          />
                        </Tooltip>
                    )}
                  </Grid>

                  <Grid item xs={12} style={{marginTop: 8}}>
                    {getDateRange()}
                  </Grid>

                  <Grid item xs={12}>
                    {activeUser && <p>User ID: {activeUser}</p>}
                  </Grid>
                </Grid>
                
                <MaterialTable
                  columns={[
                    { title: 'Date', field: 'date' },
                    { title: 'Check 1', field: 'check1' },
                    { title: 'Check 2', field: 'check2' },
                    // { title: 'Check 3', field: 'check3' },
                    // { title: 'Check 4', field: 'check4' },
                    { title: 'Minutes', field: 'minutes' },
                  ]}
                  options={{
                    exportButton: true
                  }}
                  // {
                  //   date: date,
                  //   shop: "D" + userData[activeUser].Attendance.date["Shop"] 
                  // }
                  data={(!!activeUser && !!showMonth && !!userData[activeUser].Attendance) ? Object.keys(userData[activeUser].Attendance)
                  .filter(date => date.substring(0,7) === showMonth)
                  .map(date => ({
                    date: date,
                    shop: userData[activeUser].Attendance[date].Shop,
                    check1: userData[activeUser].Attendance[date].check1 ? `${new Date(userData[activeUser].Attendance[date].check1.Timestamp).toTimeString().substring(0,8)}@D${userData[activeUser].Attendance[date].check1.Shop}` : "/",
                    check2: userData[activeUser].Attendance[date].check2 ? `${new Date(userData[activeUser].Attendance[date].check2.Timestamp).toTimeString().substring(0,8)}@D${userData[activeUser].Attendance[date].check2.Shop}` : "/",
                    minutes: (!!userData[activeUser].Attendance[date].check1 && !!userData[activeUser].Attendance[date].check2) ? Math.round(new Date((parseInt(userData[activeUser].Attendance[date].check2.Timestamp) - parseInt(userData[activeUser].Attendance[date].check1.Timestamp)))/60000, 2) : "Check Missing"
                    // check3: userData[activeUser].Attendance[date].check3 ? `${new Date(userData[activeUser].Attendance[date].check3.Timestamp).toTimeString().substring(0,8)}@D${userData[activeUser].Attendance[date].check3.Shop}` : "",
                    // check4: userData[activeUser].Attendance[date].check4 ? `${new Date(userData[activeUser].Attendance[date].check4.Timestamp).toTimeString().substring(0,8)}@D${userData[activeUser].Attendance[date].check4.Shop}` : "",
                  })) : []}
                  title={activeUser ? userData[activeUser].Name : "Please select a user"}
                />
              </Grid>
              <Grid item xs={12} style={{marginTop: 16}}>
                <Backdrop className={classes.backdrop} open={!done} onClick={() => setDone(false)}>
                  {/* <CircularProgress color="inherit" variant="static" value={progress}/> */}
                  <CircularProgress color="inherit"/>
                </Backdrop>
              </Grid>
              <Grid item xs={12} style={{marginTop: 16}}>
                {doneMsg}
              </Grid>
            </Grid>
            
          </div>
        </Grid>

        
      </Grid>
    </Zoom>
  );
}

export default withWidth()(AttendanceRecord)