import React from 'react';
import {
  BrowserRouter as Router,
  useLocation,
  Switch,
  Route,
  Link
} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {Grid, IconButton, Badge, Popover, Backdrop, CircularProgress} from '@material-ui/core';
import List from '@material-ui/core/List';
import {Divider} from '@material-ui/core';
import StoreIcon from '@material-ui/icons/Store';
import withWidth from '@material-ui/core/withWidth';
import FaceIcon from '@material-ui/icons/Face';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import PetsOutlinedIcon from '@material-ui/icons/PetsOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import { motion } from "framer-motion";
import styled from "styled-components";


import { database, auth } from '../firebase';
import Settings from '../components/Settings'



const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  buttonActive:{
    background: "white",
    color: "#5e5ecc",
    "&:hover":{
      background: "white",
    }
  }
});

function MobileNav(props) {
  const classes = useStyles();
  
  const {width, userRole} = props
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {
        auth.currentUser.email === "dbbpethouse@gmail.com" &&
        <Link to={"/admin/dashboard"}>
          <ListItem button className={location.pathname === "/admin/dashboard" ? classes.buttonActive : classes.button}>
            <ListItemIcon><GradeIcon style={{color: location.pathname === "/admin/dashboard" && "white"}}/></ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </Link>
      }
      <Link to={"/"}>
        <ListItem button className={location.pathname === "/" ? classes.buttonActive : classes.button}>
          <ListItemIcon><FaceOutlinedIcon style={{color: location.pathname === "/" && "white"}}/></ListItemIcon>
          <ListItemText primary={"Customers"} />
        </ListItem>
      </Link>
      <Divider/>
      {[0,1,3,4].map((shop, index) => (
        <Link to={"/orders/d" + shop} key={index}>
          <ListItem button key={shop} className={location.pathname === `/orders/d${shop}` ? classes.buttonActive : classes.button}>
            <ListItemIcon><StoreIcon style={{color: location.pathname === `/orders/d${shop}` && "white"}}/></ListItemIcon>
            <ListItemText primary={"D" + shop + " Order"} />
          </ListItem>
        </Link>
      ))}
      <Divider/>
      <Link to={"/bookings"}>
        <ListItem button>
          <ListItemIcon><PetsOutlinedIcon/></ListItemIcon>
          <ListItemText primary={"Bookings"} />
        </ListItem>
      </Link>
    </div>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          {["shop", "admin"].includes(userRole) && <Grid item xs={3} style={{textAlign: "center"}}>
            <Link to={"/"}>
              <motion.button whileTap={{ scale: 0.6 }}>
                <IconButton 
                  className={location.pathname === "/" && !open ? classes.buttonActive : classes.button}
                >
                  {location.pathname === "/" ? <FaceIcon/> : <FaceOutlinedIcon/>}
                </IconButton>
              </motion.button>
            </Link>
          </Grid>}

          {["shop", "admin"].includes(userRole) && <Grid item xs={3} style={{textAlign: "center"}}>
            <motion.button whileTap={{ scale: 0.6 }}>
              <IconButton 
                onClick={handleClick}
                className={open ? classes.buttonActive : classes.button}
              >
                <StoreIcon/>
              </IconButton>
            </motion.button>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>  
              <p style={{color: "white"}}>Choose your shop</p>
            </Backdrop>
            <Popover
              id="shops"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              style={{width: "100%", display: "flex"}}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  width: "100%",
                  boxShadow: "none",
                  background: "transparent",
                  overflowY: 'hidden'
                }
              }}
            >
              <Grid container>
                {[0,1,3,4].map((shop, index) => (
                  <Grid item xs={3} style={{textAlign: "center"}} key={"/orders/d" + shop}>
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ rotate: 360, scale: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                      }}
                    >
                      <Link to={"/orders/d" + shop} onClick={handleClose}>
                        <IconButton 
                          style={{
                            background: "white"
                          }}
                          className={location.pathname === "/notification" && !open ? classes.buttonActive : classes.button}
                        >
                          <StoreIcon/>
                        </IconButton>
                        <p style={{fontSize: 12, margin: 0, color: "white"}}>{"D" + shop}</p>
                      </Link>
                    </motion.div>
                  </Grid>
                ))}
                
              </Grid>
            </Popover>
          </Grid>}

          {["shop", "admin"].includes(userRole) && <Grid item xs={3} style={{textAlign: "center"}}>
            <Link to={"/bookings"}>
              <motion.button whileTap={{ scale: 0.6 }}>
                <IconButton 
                  className={location.pathname === "/bookings" && !open ? classes.buttonActive : classes.button}
                >
                  <PetsOutlinedIcon/>
                </IconButton>
              </motion.button>
            </Link>
          </Grid>}
          {["shop", "admin"].includes(userRole) && <Grid item xs={3} style={{textAlign: "center"}}>
            <Link to={"/notification"}>
              <motion.button whileTap={{ scale: 0.6 }}>
                <IconButton 
                  onClick={()=>console.log("haha")}
                  className={location.pathname === "/notification" && !open ? classes.buttonActive : classes.button}
                >
                  <Badge badgeContent={4} color="secondary">
                  {location.pathname === "/notification" ? <NotificationsActiveRoundedIcon/> : <NotificationsActiveOutlinedIcon/>}
                  </Badge>
                </IconButton>
              </motion.button>
            </Link>
          </Grid>}
          <Grid item xs={12} style={{textAlign: "center"}}>
            <Link to={"/attendance"}>
              <motion.button whileTap={{ scale: 0.6 }}>
                <IconButton 
                  onClick={()=>console.log("haha")}
                  className={location.pathname === "/attendance" && !open ? classes.buttonActive : classes.button}
                >
                  {location.pathname === "/attendance" ? <AccessAlarmsIcon/> : <AccessAlarmsIcon/>}
                </IconButton>
              </motion.button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withWidth()(MobileNav);