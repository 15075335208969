import * as firebase from "firebase";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDcsSMxmhJRZ2dQXj3xleIuUjaKhA1mUag",
  authDomain: "dbb-book-react-hook.firebaseapp.com",
  databaseURL: "https://dbb-book-react-hook-1688.asia-southeast1.firebasedatabase.app/",
  projectId: "dbb-book-react-hook",
  storageBucket: "dbb-book-react-hook.appspot.com",
  messagingSenderId: "59746542907",
  appId: "1:59746542907:web:045a121b35e21b7b02031e",
  measurementId: "G-NZ2HZKZ5G0"
};

const testingFirebaseConfig = {
  apiKey: "AIzaSyDcsSMxmhJRZ2dQXj3xleIuUjaKhA1mUag",
  authDomain: "https://dbb-book-react-hook.firebaseio.com/",
  databaseURL: "https://dbb-book-react-hook.firebaseio.com/",
  projectId: "dbb-book-react-hook",
  storageBucket: "dbb-book-react-hook.appspot.com",
  messagingSenderId: "59746542907",
  appId: "1:59746542907:web:045a121b35e21b7b02031e",
  measurementId: "G-NZ2HZKZ5G0"
};

//Testing env
if(window.location.hostname === "localhost"){
  // firebase.initializeApp(firebaseConfig)
  firebase.initializeApp(testingFirebaseConfig)
}
else{
  // firebase.initializeApp(testingFirebaseConfig)
  firebase.initializeApp(firebaseConfig)
}

export default firebase;
export const database = firebase.database();
export const auth = firebase.auth();