import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useRouteMatch, Route, useLocation, useParams } from 'react-router-dom';
import {Paper, Grid} from '@material-ui/core/';
import MaterialTable from 'material-table';

function ActivityLogTable(props) {
  const {data} = props

  useEffect(()=>{
    console.log("mounted activity")
  }, [data])

  const Table = useMemo(() => (
    <>
      <Grid item xs={12}>
        <h3 style={{textAlign: "center"}}>Activity Logs</h3>
      </Grid>
      <MaterialTable
        title=""
        style={{
          width: "100%"
        }}
        components={{
          Container: props => <Paper {...props} elevation={0}/>
        }}
        columns={[
          { 
            title: 'Time', 
            field: 'Time',
            render: rowData => <p>{new Date(rowData.Time).toLocaleString()}</p>
          },
          { title: 'Event', field: 'Event' },
          { title: 'Handler', field: 'Handler' }
        ]}
        data={data}        
        options={{
          exportButton: true,
          padding: "dense"
        }}
      />
    </>
  ), [data])

  return (
    <>
      {Table}
    </>
  )
}

export default ActivityLogTable