import React, { useState, useEffect, memo, useMemo } from 'react';
import { useRouteMatch, Route, useLocation } from 'react-router-dom';
import "../style.css";
import { Button, Grid, Avatar, Chip, Paper, Zoom, Backdrop, CircularProgress } from '@material-ui/core';
import GoogleLogin from 'react-google-login';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import MaterialTable from 'material-table'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import withWidth from '@material-ui/core/withWidth';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import { motion } from "framer-motion";

// components
import Confirmation from '../components/Confirmation';
import Settings from '../components/Settings'

// import Notify from '../components/Notify';
import { database, auth } from '../firebase';


const CustomersTable = memo(() => {

  const [lists, setLists] = useState([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("")
  const [editCustomerData, setEditCustomerData] = useState({})
  const [editCustomerKey, setEditCustomerKey] = useState("")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [backdropOpen, setBackdropOpen] = useState(false)
  let initialDataLoaded = false
  const match = useRouteMatch('/orders/:id')
  const location = useLocation()

  useEffect(() => {
    setBackdropOpen(true)
    let dataSnap = []
    let customerID = {}
    let personalData = {}
    let orderData = {}
    let count = 0

    database.ref("newCustomers").endAt().limitToLast(1).on("child_added", (data) => {
      if(initialDataLoaded && data.val().Account !== auth.currentUser.email){
        console.log(data.val())
        enqueueSnackbar(`${data.val().Name} was added by ${data.val().Handler}(${data.val().Account})`, { 
          variant: 'info',     
        })
      }
    })

    database.ref("newCustomers").on("value", (snapshot) => {
      dataSnap = Object.values(snapshot.val()).map((v) => {
        return { 
          id: v.id,
          Name: v.Name,
          FirstPhone: v.FirstPhone,
          SecondPhone: v.SecondPhone,
          ThirdPhone: v.ThirdPhone,
          Email: v.Email,
          Address: v.Address,
          PetName: v.PetName,
          PetBreed: v.PetBreed,
          LastEdit: v.LastEdit,
          Notes: v.Notes,
          Handler: v.Handler
        }
      })
      setLists(dataSnap)
      initialDataLoaded = true
      setBackdropOpen(false)
      // for(let customer in snapshot.val()){
      //   count += 1
      //   if(count < 100){
      //     customerID = {"id": parseInt(customer)}
      //     personalData = snapshot.val()[customer].PersonalData
      //     orderData = snapshot.val()[customer].Records && {"OrderRecord": snapshot.val()[customer].Records}
      //     console.log({...customerID, ...personalData, ...orderData})
      //     dataSnap.push({...customerID, ...personalData, ...orderData})
      //   }
      //   else if(count === 200){
      //     console.log(dataSnap)
      //   } 
      // }

      // for(let customer in snapshot.val()){
      //   customerID = {"id": parseInt(customer)}
      //   personalData = snapshot.val()[customer].PersonalData
      //   orderData = snapshot.val()[customer].Records && {"OrderRecord": snapshot.val()[customer].Records}
      //   database.ref("newCustomers").push({...customerID, ...personalData, ...orderData})
      // }
    })
    // .then(()=>{
    //   setLists(dataSnap)
    // })
    return () => {
      database.ref("newCustomers").endAt().limitToLast(1).off()
      database.ref("newCustomers").off()
    }
  }, [location])

  const Table = useMemo(() => (
    <MaterialTable
      style={{width: "100%"}}
      title="Customers"
      columns={[
        { 
          title: 'ID', 
          field: 'id',
          render: rowData => <Chip
            avatar={<Avatar style={{backgroundColor: Settings.mainBackgroundColor}}><LaunchRoundedIcon style={{color: "white"}}/></Avatar>}
            label={rowData.id}
            // clickable
            onClick={()=>{
              setMode("editCustomer")
              database.ref("newCustomers").orderByChild("id").equalTo(rowData.id).once("value", (snapshot)=>{
                setEditCustomerKey(Object.entries(snapshot.val())[0][0])
                setEditCustomerData(Object.entries(snapshot.val())[0][1])
                console.log(Object.entries(snapshot.val())[0][1])
              })
              setOpen(true)
            }}
            style={{backgroundColor: Settings.mainBackgroundColor, color: Settings.mainColor}}
          />
          // render: rowData => console.log(rowData)
        },
        { title: 'Name', field: 'Name' },
        {
          title: 'First Phone',
          field: 'FirstPhone',
          render: rowData => <a style={{color: "black"}} href={"tel:" + rowData.FirstPhone}>{rowData.FirstPhone}</a>
        },
        { 
          title: 'Second Phone', 
          field: 'SecondPhone',
          render: rowData => <a style={{color: "black"}} href={"tel:" + rowData.SecondPhone}>{rowData.SecondPhone}</a>
        },
        { 
          title: 'Third Phone', 
          field: 'ThirdPhone',
          render: rowData => <a style={{color: "black"}} href={"tel:" + rowData.ThirdPhone}>{rowData.ThirdPhone}</a>
        },
        { 
          title: 'Email', 
          field: 'Email',
          render: rowData => <a style={{color: "black"}} href={"tel:" + rowData.Email}>{rowData.Email}</a>
        },
        { 
          title: 'Address', 
          field: 'Address',
          render: rowData => rowData.Address && 
            <CopyToClipboard 
              text={rowData.Address}
              style={{cursor: "pointer"}} 
              onCopy={() => enqueueSnackbar("Copied to Clipboard!", { 
                variant: 'success',     
              })}
            >
            <p><FileCopyOutlinedIcon fontSize="small" style={{marginLeft: 4}}/>{rowData.Address}</p>
          </CopyToClipboard>
        },
        { title: 'Pet', field: 'PetName'},
        { title: 'Breed', field: 'PetBreed'},
        { title: 'LastEdit', field: 'LastEdit'},
        { title: 'Notes', field: 'Notes'},
        { title: 'Handler', field: 'Handler'},

      ]}
      data = {lists} 
      isLoading = {!Array.isArray(lists) || lists.length === 0}
      options={{
        filtering: false,
        minBodyHeight: 500,
        pageSize: 20,
        pageSizeOptions: [20,50,100],
        exportAllData: true,
        showTitle: false,
        headerStyle:{
          backgroundColor: Settings.mainBackgroundColor,
          color: Settings.mainColor
        },
        searchFieldAlignment: "left",
        loadingType: 'linear'
      }}
      // onRowClick={(e, rowData)=>{
      //   setMode("editCustomer")
      //   setEditCustomerData(rowData)
      //   setOpen(true)
      // }}
      components={{
        Container: props => <Paper {...props} elevation={0}/>
      }}
    />
  ), [lists])
  
  return (
    <>
      {Table}
      <Backdrop open={backdropOpen} style={{zIndex: 9999}} onClick={()=>console.log(null)}>  
        <CircularProgress style={{color: "white", display: "block"}}/>
        {/* <p style={{color: "white"}}>
          Loading...
        </p> */}
      </Backdrop>
      <Confirmation
        handleClose={() => setOpen(false)}
        editKey={editCustomerKey}
        data={editCustomerData}
        mode={mode}
        open={open}
      />
    </>
  )
})


function Customers(props) {
  
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("")
  const [customerData, setCustomerData] = useState([])
  const { width } = props;
  
  useEffect(() => {
    document.title = "DBB Order - Customer"
    // database.ref("customers").once("value", (snapshot) => {
    //   setCustomerData(Object.values(snapshot.val()).map(v => v.PersonalData))
    // })
  });
  
  const [profileNamem, setProfileName] = useState("")
  
  return (
    <Zoom in={true} timeout={width === "xs" || width === "sm" ? 300 : 1}>
      <Grid container style={{paddingBottom: 48}}>
        <Grid item xs={12}>
          <div style={{
            width: width === "xs" || width === "sm" ? "100%" : "calc(100% - 250px)",
            paddingLeft: width === "xs" || width === "sm" ? 0 : 244
          }}>
            <div style={{padding: 8, borderBottom: "solid 1px gainsboro"}}>
              <h1><FaceOutlinedIcon style={{color: Settings.mainBackgroundColor}}/>Customers</h1>
              <p style={{margin: 0, fontStyle: "italic"}}>"A brand for a company is like a reputation for a person."</p>
            </div>
            <Grid container style={{margin: "8px 0px"}}>
              <Grid item xs={12}>
              <motion.button 
                whileTap={{ scale: 0.9 }}
                style={{
                  position: (width === "xs" || width === "sm") && "fixed",
                  right: (width === "xs" || width === "sm") && 20,
                  bottom: (width === "xs" || width === "sm") && 64,
                  zIndex: (width === "xs" || width === "sm") && 999
                }}
              >
                <Button 
                  style={{
                    background: Settings.mainBackgroundColor,
                    color: Settings.mainColor,
                    marginLeft: width === "xs" || width === "sm" ? 4 : 0
                  }}
                  onClick={()=>{
                    setMode("addCustomer")
                    setOpen(true)
                  }}
                >
                  <AddCircleOutlineRoundedIcon style={{marginRight: 4}}/>
                  Add Customer
                </Button>
              </motion.button>
                <Confirmation
                  handleClose={() => setOpen(false)}
                  mode={mode}
                  open={open}
                />
              </Grid> 
            </Grid>
            
            <CustomersTable/>
          </div>
        </Grid>
      </Grid>
    </Zoom>
  );
}

export default withWidth()(Customers)