import React, { useState, useEffect, memo, useMemo } from 'react';
import { useParams, Route, useLocation } from 'react-router-dom';
import "../style.css";
import { Button, Grid, Avatar, Chip, Paper, Zoom, Tooltip, Backdrop, CircularProgress, Switch, FormControlLabel  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GoogleLogin from 'react-google-login';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import MaterialTable from 'material-table'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import withWidth from '@material-ui/core/withWidth';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import Moment from 'react-moment'
import { motion } from "framer-motion";
import SpeedDial from '@material-ui/lab/SpeedDial';


// components
import Confirmation from '../components/Confirmation';
import Settings from '../components/Settings'

// import Notify from '../components/Notify';
import { database, auth } from '../firebase';

const useStyles = makeStyles((theme) => ({
  backdrop:{
    zIndex: 9999
  }
}));

const OrdersTable = memo(() => {

  const [lists, setLists] = useState([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("")
  const classes = useStyles();
  const [editOrderData, setEditOrderData] = useState({})
  const [editOrderKey, setEditOrderKey] = useState("")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [showUndoneOnly, setShowUndoneOnly] = useState(false)
  const [fixedTable, setFixedTable] = useState(false)
  let initialDataLoaded = false
  const match = useParams('/orders/:id')
  const location = useLocation()

  useEffect(() => {
    setBackdropOpen(true)
    let customerID = {}
    let personalData = {}
    let orderData = {}
    let count = 0
    // console.log(match?.params.id.toLowerCase())

    database.ref(`d${match?.id.toLowerCase()}_orders_new`).endAt().limitToLast(1).on("child_added", (data) => {
      if(initialDataLoaded && data.val().Account !== auth.currentUser.email){
        console.log(data.val())
        enqueueSnackbar(`Order ${data.val().id} was added by ${data.val().Handler} just now`, { 
          variant: 'info',     
        })
      }
    })

    //debug use
    // database.ref(`d${match?.id.toLowerCase()}_orders_new`).orderByChild('id').equalTo("2108-4-1").once("value", (snapshot)=>{
    //   console.log("debug: ", snapshot.val())
    // })

    // database.ref(`current_order/exact_d1`).on("value", (snapshot)=>{
    //   console.log(snapshot.val())
    // })

    // database.ref(`current_order/exact_d1`).off()

    database.ref(`d${match?.id.toLowerCase()}_orders_new`).orderByChild('Timestamp').on("value", (snapshot) => {
      console.log("something changed")
      let dataSnap = []
      snapshot.forEach((childSnapshot, index) => {
        if(showUndoneOnly && !childSnapshot.val().Done){
          dataSnap.push({ 
            Date: childSnapshot.val().Timestamp,
            id: childSnapshot.val().id,
            Name: childSnapshot.val().Name,
            Phone: childSnapshot.val().Phone,
            Address: childSnapshot.val().Address,
            Items: childSnapshot.val().Items.split(/\n/g),
            Amount: childSnapshot.val().Amount,
            Paid: childSnapshot.val().Paid,
            Done: childSnapshot.val().Done,
            Delivery: childSnapshot.val().Delivery,
            Note: childSnapshot.val().Notes,
            Handler: childSnapshot.val().Handler,
          })
        }
        else if(!showUndoneOnly){
          dataSnap.push({ 
            Date: childSnapshot.val().Timestamp,
            id: childSnapshot.val().id,
            Name: childSnapshot.val().Name,
            Phone: childSnapshot.val().Phone,
            Address: childSnapshot.val().Address,
            Items: childSnapshot.val().Items.split(/\n/g),
            Amount: childSnapshot.val().Amount,
            Paid: childSnapshot.val().Paid,
            Done: childSnapshot.val().Done,
            Delivery: childSnapshot.val().Delivery,
            Note: childSnapshot.val().Notes,
            Handler: childSnapshot.val().Handler,
          })
        }
      })
      setLists(dataSnap.reverse())
      initialDataLoaded = true
      setBackdropOpen(false)
    })

    return () => {
      database.ref(`d${match?.id.toLowerCase()}_orders_new`).endAt().limitToLast(1).off()
      database.ref(`d${match?.id.toLowerCase()}_orders_new`).off()
    }
  }, [location, showUndoneOnly])

  const handleShowUndoneChange = (event) => {
    setShowUndoneOnly(event.target.checked)
  }

  const handleFixedTable = (event) => {
    setFixedTable(event.target.checked)
  }

  const Table = useMemo(() => (
    <>
    <FormControlLabel
      control={
        <Switch
          checked={showUndoneOnly}
          onChange={handleShowUndoneChange}
          name="ShowUndone"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />  
      }
      label={showUndoneOnly ? "Showing Undone Only" : "Showing All Now"}
    />
    <FormControlLabel
      control={
        <Switch
          checked={fixedTable}
          onChange={handleFixedTable}
          name="ShowUndone"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />  
      }
      label={fixedTable ? "Fixed Mode" : "Responsive Mode"}
    />
    <MaterialTable
      style={{width: "100%"}}
      // title="Customers"
      columns={[
        {
          title: 'Date',
          field: 'Date',
          width: 68,
          cellStyle: (cellValue, rowData) => ({borderLeft: rowData.Done ? "solid 12px #65cc5e" : "solid 12px #e67676"}),
          render: rowData => 
          <CopyToClipboard 
            text={`${rowData.id} ${rowData.Name} ${rowData.Phone} ${rowData.Address} ${rowData.Items.filter(v => v !== "" && v)} ${rowData.Paid !== "paid" ? `$${rowData.Amount}` : ""}`}
            onCopy={() => enqueueSnackbar(`Copied information of order #${rowData.id} to Clipboard!`, { 
              variant: 'success',     
            })}
          >
            <Tooltip title={new Date(rowData.Date).toDateString()} aria-label="date">
              <p style={{color: "black", cursor: "pointer"}}>
                <Moment format="DD/MM">
                  {rowData.Date}
                </Moment>
              </p>
            </Tooltip>
          </CopyToClipboard>
        },
        { 
          title: 'Order ID', 
          field: 'id',
          width: 128,
          render: rowData => <Chip
            avatar={<Avatar style={{backgroundColor: Settings.mainBackgroundColor}}><LaunchRoundedIcon style={{color: "white"}}/></Avatar>}
            label={rowData.id}
            // clickable
            onClick={()=>{
              setMode("editOrder")
              // database.ref("newCustomers").orderByChild("id").equalTo(rowData.id).once("value", (snapshot)=>{
              //   setEditCustomerKey(Object.entries(snapshot.val())[0][0])
              //   setEditCustomerData(Object.entries(snapshot.val())[0][1])
              // })
              database.ref(`d${match?.id.toLowerCase()}_orders_new`).orderByChild("id").equalTo(rowData.id).once("value", (snapshot) => {
                setEditOrderKey(Object.entries(snapshot.val())[0][0])
                setEditOrderData(Object.entries(snapshot.val())[0][1])
                console.log(Object.entries(snapshot.val())[0][1])
              })
              setOpen(true)
            }}
            style={{backgroundColor: Settings.mainBackgroundColor, color: Settings.mainColor}}
          />
          // render: rowData => console.log(rowData)
        },
        { 
          title: 'Name',
          field: 'Name',
          width: 108,
          render: rowData => {
            return (
              <>
                <p>{rowData.Name}</p>
                {/* <a style={{color: "black"}} href={"tel:" + rowData.Phone}>{rowData.Name} {rowData.Phone}</a> */}
              </>
            )
          }
        },
        {
          title: 'Phone',
          field: 'Phone',
          width: 108,
          render: rowData => <a style={{color: "black"}} href={"tel:" + rowData.Phone}>{rowData.Phone}</a>
        },
        { 
          title: 'Address', 
          field: 'Address',
          width: 300,
          render: rowData => rowData.Address && rowData.Delivery &&
            <CopyToClipboard 
              text={rowData.Address}
              style={{cursor: "pointer"}} 
              onCopy={() => enqueueSnackbar("Copied to Clipboard!", { 
                variant: 'success',     
              })}
            >
            <p><FileCopyOutlinedIcon fontSize="small" style={{marginLeft: 4}}/>{rowData.Address}</p>
          </CopyToClipboard>
        },
        {
          title: 'Items',
          field: 'Items',
          width: 500,
          render: rowData => rowData.Items.map((v,i) => v !== "" && 
          <p key={"items" + i} 
            style={{
              margin: "4px 0px", 
              borderBottom: "solid 1px #ededed",
              backgroundColor: v.includes("(v)") && "#e8c6ff"
            }}
          >
            {v}
          </p>)
        },
        { title: 'Amount', field: 'Amount', width: 88},
        {
          title: 'Paid',
          field: 'Paid',
          width: 68,
          render: rowData => rowData.Paid.includes("paid") ? <MonetizationOnRoundedIcon color="primary"/> : <MoneyOffRoundedIcon color="secondary"/>
        },
        {
          title: 'Delivery',
          field: 'Delivery',
          width: 68,
          render: rowData => rowData.Delivery ? <LocalShippingRoundedIcon color="primary"/> : <HighlightOffRoundedIcon color="secondary"/>
        },
        {
          title: 'Done',
          field: 'Done',
          width: 68,
          render: rowData => rowData.Done ? <AssignmentTurnedInRoundedIcon color="primary"/> : <HighlightOffRoundedIcon color="secondary"/>
        },
        { 
          title: 'Note', 
          field: 'Note',
          width: 88,
        },
        { 
          title: 'Handler',
          field: 'Handler',
          width: 68,
        },

      ]}
      data = {lists} 
      isLoading = {!Array.isArray(lists) || lists.length === 0}
      options={{
        filtering: false,
        minBodyHeight: 500,
        pageSize: 20,
        pageSizeOptions: [20,50,100],
        exportAllData: true,
        showTitle: false,
        headerStyle:{
          backgroundColor: Settings.mainBackgroundColor,
          color: Settings.mainColor,
          maxWidth: 50
        },
        cellStyle:{
          padding: 4
        },
        searchFieldAlignment: "left",
        tableLayout: fixedTable ? "fixed" : "auto",
        loadingType: 'linear',
        maxBodyHeight: "100%",
        rowStyle: rowData => {
          if(rowData.Done) {
            return {
              opacity: 0.5
            };
          }
          return {};
        }
        // doubleHorizontalScroll: true
      }}
      // onRowClick={(e, rowData)=>{
      //   setMode("editCustomer")
      //   setEditCustomerData(rowData)
      //   setOpen(true)
      // }}
      components={{
        Container: props => <Paper {...props} elevation={0}/>
      }}
    />
    </>
  ), [lists, showUndoneOnly, fixedTable])
  
  return (
    <>
      {Table}
      <Backdrop className={classes.backdrop} open={backdropOpen} onClick={()=>console.log(null)}>  
        <CircularProgress style={{color: "white", display: "block"}}/>
        {/* <p style={{color: "white"}}>
          Loading...
        </p> */}
      </Backdrop>
      <Confirmation
        handleClose={() => setOpen(false)}
        editKey={editOrderKey}
        data={editOrderData}
        mode={mode}
        open={open}
      />
    </>
  )
})


function Orders(props) {
  
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("")
  const [customerData, setCustomerData] = useState([])
  const { width } = props;
  const match = useParams('/orders/:id')
  
  useEffect(() => {
    console.log(match)
    document.title = "DBB Order - Order D" + match.id
    // database.ref("customers").once("value", (snapshot) => {
    //   setCustomerData(Object.values(snapshot.val()).map(v => v.PersonalData))
    // })
  });
  
  const [profileNamem, setProfileName] = useState("")
  
  return (
    <Zoom in={true} timeout={width === "xs" || width === "sm" ? 300 : 1}>
      <Grid container style={{paddingBottom: 48}}>
        <Grid item xs={12}>
          <div style={{
            width: width === "xs" || width === "sm" ? "100%" : "calc(100% - 250px)",
            paddingLeft: width === "xs" || width === "sm" ? 0 : 244
          }}>
            <div style={{padding: 8, borderBottom: "solid 1px gainsboro"}}>
              <h1><MonetizationOnRoundedIcon style={{color: "#587af7"}}/>D{match?.id.toUpperCase()} Orders</h1>
              <p style={{margin: 0, fontStyle: "italic"}}>Business opportunities are like buses, there's always another one coming." – Richard Branson</p>
            </div>
            <Grid container style={{margin: "8px 0px"}}>
              <Grid item xs={12}>
                <motion.button 
                  whileTap={{ scale: 0.9 }}
                  style={{
                    position: (width === "xs" || width === "sm") && "fixed",
                    right: (width === "xs" || width === "sm") && 20,
                    bottom: (width === "xs" || width === "sm") && 64,
                    zIndex: (width === "xs" || width === "sm") && 999
                  }}
                >
                  <Button 
                    style={{
                      background: Settings.mainBackgroundColor,
                      color: Settings.mainColor,
                      marginLeft: width === "xs" || width === "sm" ? 4 : 0,
                    }}
                    onClick={()=>{
                      setMode("addOrder")
                      setOpen(true)
                    }}
                  >
                    <MonetizationOnRoundedIcon style={{marginRight: 4}}/>
                    {(width !== "xs" && width !== "sm") && "Add an Order"}
                  </Button>
                </motion.button>
                <Confirmation
                  handleClose={() => setOpen(false)}
                  mode={mode}
                  open={open}
                />
              </Grid> 
            </Grid>
            
            <OrdersTable/>
          </div>
        </Grid>
      </Grid>
    </Zoom>
  );
}

export default withWidth()(Orders)