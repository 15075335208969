import React, { useState, useEffect } from 'react';
import "../style.css";
import { Button, Grid, Backdrop, Chip, Paper, Zoom } from '@material-ui/core';
import GoogleLogin from 'react-google-login';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import MaterialTable from 'material-table'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import withWidth from '@material-ui/core/withWidth';
import GradeIcon from '@material-ui/icons/Grade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

// components
import Confirmation from '../components/Confirmation';
import Settings from '../components/Settings'

// import Notify from '../components/Notify';
import firebase, { database, auth } from '../firebase';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0)
  const [done, setDone] = useState(true)
  const [doneMsg, setDoneMsg] = useState("")
  const [customerData, setCustomerData] = useState([])
  const { width } = props;
  const webCustomers = [
    {
        "email": "magneticadmin@magneticone.com",
        "phone": null,
        "firstName": "",
        "lastName": "",
        "address": ""
    },
    {
        "email": "jason050196@gmail.com",
        "phone": 93280213,
        "firstName": "Jason",
        "lastName": "Lee",
        "address": ""
    },
    {
        "email": "m131026kelvin@gmail.com",
        "phone": 93280213,
        "firstName": "Lee",
        "lastName": "Cheuk kiu",
        "address": ""
    },
    {
        "email": "momimiu@gmail.com",
        "phone": 31246,
        "firstName": "Miu",
        "lastName": "Miu",
        "address": "青海圍18 號, 1234"
    },
    {
        "email": "haonoddy@gmail.com",
        "phone": null,
        "firstName": "chow",
        "lastName": "winglam",
        "address": ""
    },
    {
        "email": "jennylamwc@gmail.com",
        "phone": 62705840,
        "firstName": "JENNY",
        "lastName": "LAM",
        "address": ""
    },
    {
        "email": "pigcandy520@gmail.com",
        "phone": 97530244,
        "firstName": "Chow",
        "lastName": "Candy",
        "address": ", 屯門新屯門中心2座18樓B室"
    },
    {
        "email": "iris_choi0205@yahoo.com.hk",
        "phone": 96506865,
        "firstName": "choi",
        "lastName": "ka yan",
        "address": ""
    },
    {
        "email": "jmanchung@yahoo.com.hk",
        "phone": 60306583,
        "firstName": "Chung",
        "lastName": "Jman",
        "address": "將軍澳馬遊塘村32號2樓, 將軍澳翠林邨彩林樓2916室"
    },
    {
        "email": "jumpingra@hotmail.com",
        "phone": 97270207,
        "firstName": "Wu",
        "lastName": "WC",
        "address": ""
    },
    {
        "email": "pohangsze711@live.hk",
        "phone": null,
        "firstName": "Po",
        "lastName": "Hang Sze",
        "address": ""
    },
    {
        "email": "tinachowwy@gmail.com",
        "phone": null,
        "firstName": "tina",
        "lastName": "chow",
        "address": ""
    },
    {
        "email": "clarayc12@gmail.com",
        "phone": null,
        "firstName": "Li",
        "lastName": "Clara",
        "address": ""
    },
    {
        "email": "irene@pldhk.com",
        "phone": null,
        "firstName": "Irene",
        "lastName": "Chung",
        "address": ""
    },
    {
        "email": "pokingpeggy@gmail.com",
        "phone": 66825837,
        "firstName": "Lee",
        "lastName": "Po King",
        "address": ""
    },
    {
        "email": "sun_ching@yahoo.com.hk",
        "phone": 63579123,
        "firstName": "6/6/2021 0:00",
        "lastName": "Ching",
        "address": ""
    },
    {
        "email": "florakitfungli@gmail.com",
        "phone": 60858611,
        "firstName": "Li",
        "lastName": "Flora",
        "address": "362-388 青山公路青山灣段, 恒順園9座5樓A室"
    },
    {
        "email": "amiee1018@gmail.com",
        "phone": null,
        "firstName": "6/5/2021 4:02",
        "lastName": "6/5/2021 4:02",
        "address": ""
    },
    {
        "email": "allison_wky@yahoo.com.hk",
        "phone": null,
        "firstName": "allison",
        "lastName": "wong",
        "address": ""
    },
    {
        "email": "aramis_hk@yahoo.com.hk",
        "phone": 51330550,
        "firstName": "LEE",
        "lastName": "6/4/2021 23:02",
        "address": ""
    },
    {
        "email": "chankayiucky@gmail.com",
        "phone": null,
        "firstName": "Chan",
        "lastName": "Kayiu",
        "address": ""
    },
    {
        "email": "ashhhhhh@gmail.com",
        "phone": 93175967,
        "firstName": "Lam",
        "lastName": "Ash",
        "address": "5C, 2-6 Foo Ming Street,, Po Ming Building"
    },
    {
        "email": "more.loveruby@gmail.com",
        "phone": 65333958,
        "firstName": "Ruby",
        "lastName": "Yang",
        "address": ""
    },
    {
        "email": "hangpig3344@yahoo.com.hk",
        "phone": null,
        "firstName": "Yip",
        "lastName": "Ka man",
        "address": ""
    },
    {
        "email": "Nataliefungky@gmail.com",
        "phone": 62825628,
        "firstName": "Fung",
        "lastName": "Ying",
        "address": "香港旺角西洋菜北街161號, 恆安樓4樓402室"
    },
    {
        "email": "mwy8315@gmail.com",
        "phone": 56309592,
        "firstName": "MOK",
        "lastName": "Zoe",
        "address": ""
    },
    {
        "email": "il.annod@gmail.com",
        "phone": null,
        "firstName": "Li",
        "lastName": "Donna",
        "address": ""
    },
    {
        "email": "kkchuxd@gmail.com",
        "phone": null,
        "firstName": "KAM",
        "lastName": "WINGKEI",
        "address": ""
    },
    {
        "email": "hinesamberly661@gmail.com",
        "phone": null,
        "firstName": "LxrFZNUTOcGt",
        "lastName": "ZzXAlHfk",
        "address": ""
    },
    {
        "email": "kk1992@live.hk",
        "phone": 92409699,
        "firstName": "CHAN",
        "lastName": "KIN KI",
        "address": "RM 1008 FU PING HOUSE, TAI WO HAU ESTATE"
    },
    {
        "email": "megumilau@gmail.com",
        "phone": 68938849,
        "firstName": "LAU",
        "lastName": "YUET MEI",
        "address": ""
    },
    {
        "email": "care1027@hotmail.com",
        "phone": 63361310,
        "firstName": "Chan",
        "lastName": "Claire",
        "address": ""
    },
    {
        "email": "zora.csn@gmail.com",
        "phone": null,
        "firstName": "Chan",
        "lastName": "Zora",
        "address": ""
    },
    {
        "email": "mak1022@gmail.com",
        "phone": 98717099,
        "firstName": "Mak",
        "lastName": "5/5/2021 0:00",
        "address": ""
    },
    {
        "email": "fion_llt@ymail.com",
        "phone": 92444408,
        "firstName": "Lee",
        "lastName": "Fion",
        "address": ""
    },
    {
        "email": "uranus0724@yahoo.com.hk",
        "phone": 61583713,
        "firstName": "Li",
        "lastName": "Albee",
        "address": ""
    },
    {
        "email": "anereel@ymail.com",
        "phone": 98102757,
        "firstName": "Lee",
        "lastName": "Erena",
        "address": ""
    },
    {
        "email": "popo1692000@yahoo.com.hk",
        "phone": 97117965,
        "firstName": "Po",
        "lastName": "TC",
        "address": ""
    },
    {
        "email": "mshm.ting@gmail.com",
        "phone": null,
        "firstName": "Wong",
        "lastName": "Krystal",
        "address": ""
    },
    {
        "email": "yee123wong@gmail.com",
        "phone": null,
        "firstName": "Wong",
        "lastName": "Ching yee",
        "address": ""
    },
    {
        "email": "waishan0828@yahoo.com.hk",
        "phone": 91526126,
        "firstName": "Wong",
        "lastName": "Wai Shan",
        "address": "Rm A, 18/F, Block 7,, City Point, 48 Wing Shun Sreet"
    },
    {
        "email": "johnsmithau.1801@gmail.com",
        "phone": null,
        "firstName": "John",
        "lastName": "Smith",
        "address": ""
    },
    {
        "email": "yam1122004@yahoo.com.hk",
        "phone": 95302304,
        "firstName": "tsui",
        "lastName": "Catherine",
        "address": "Sai Ying Pun, 6A High Street, 6/F"
    },
    {
        "email": "jasonthho@gmail.com",
        "phone": 91628568,
        "firstName": "Ho",
        "lastName": "Jason",
        "address": "詩歌舞街, 亮賢居28樓A室"
    },
    {
        "email": "coco142004@hotmail.com",
        "phone": 90230407,
        "firstName": "ko",
        "lastName": "hug",
        "address": ""
    },
    {
        "email": "tongkatherine520@gmail.com",
        "phone": 91544045,
        "firstName": "Tong",
        "lastName": "Katherine",
        "address": ""
    },
    {
        "email": "solam3026@gmail.com",
        "phone": null,
        "firstName": "So",
        "lastName": "Sophie",
        "address": ""
    },
    {
        "email": "adela_jojo@yahoo.com.hk",
        "phone": 97333936,
        "firstName": "Wang",
        "lastName": "Adela",
        "address": "元朗洪水橋, 丹桂村路65－89號"
    },
    {
        "email": "nataliemahc@gmail.com",
        "phone": 61333429,
        "firstName": "Ma",
        "lastName": "Natalie",
        "address": ""
    },
    {
        "email": "oiyu1031@gmail.com",
        "phone": 92280427,
        "firstName": "chan",
        "lastName": "noel",
        "address": ""
    },
    {
        "email": "lambacbac@hotmail.com",
        "phone": 93424603,
        "firstName": "Lam",
        "lastName": "Grendy",
        "address": ""
    },
    {
        "email": "crystalyuenyi_1212@yahoo.com.hk",
        "phone": 68754791,
        "firstName": "Yuen",
        "lastName": "Yi",
        "address": ""
    },
    {
        "email": "winnie.law96211@gmail.com",
        "phone": null,
        "firstName": "Law",
        "lastName": "Pui Ka",
        "address": ""
    },
    {
        "email": "maymaima@yahoo.com.hk",
        "phone": null,
        "firstName": "Chan",
        "lastName": "5/5/2021 0:00",
        "address": ""
    },
    {
        "email": "taiwanming0123@gmail.com",
        "phone": null,
        "firstName": "Yi",
        "lastName": "Min",
        "address": ""
    },
    {
        "email": "yo.cccky@gmail.com",
        "phone": 97834773,
        "firstName": "Choi",
        "lastName": "Yo",
        "address": ""
    },
    {
        "email": "puimanho315@gmail.com",
        "phone": 53162499,
        "firstName": "Ho",
        "lastName": "Jessica",
        "address": ""
    },
    {
        "email": "ipkapik321@gmail.com",
        "phone": null,
        "firstName": "Ip",
        "lastName": "Kapik",
        "address": ""
    },
    {
        "email": "paktszying_a_a@live.hk",
        "phone": 60935286,
        "firstName": "PAK",
        "lastName": "TSZYING",
        "address": ""
    },
    {
        "email": "Mayyi528@yahoo.com.hk",
        "phone": null,
        "firstName": "Kwok",
        "lastName": "Mei yi",
        "address": ""
    },
    {
        "email": "mogum27@icloud.com",
        "phone": 62916486,
        "firstName": "Kam",
        "lastName": "Hoi ki",
        "address": ""
    },
    {
        "email": "wailam116@gmail.com",
        "phone": 55123655,
        "firstName": "Cheung",
        "lastName": "William",
        "address": "Flat E，30/F，Blk 3, Kingston Terrace"
    },
    {
        "email": "hysiu2001@gmail.com",
        "phone": null,
        "firstName": "Siu",
        "lastName": "Yan",
        "address": ""
    },
    {
        "email": "yuanmi21515@gmail.com",
        "phone": null,
        "firstName": "圓",
        "lastName": "密",
        "address": ""
    },
    {
        "email": "chantszshan818@gmail.com",
        "phone": 51302552,
        "firstName": "chan",
        "lastName": "Tsz Shan",
        "address": ""
    },
    {
        "email": "yvonne_lky@yahoo.com.hk",
        "phone": 69328917,
        "firstName": "Lam",
        "lastName": "Yvonne",
        "address": "unit 2105-07, 21/F, Tower 2, Grand Central Plaza, 138 Shatin Rural Committee Rd., Shatin"
    },
    {
        "email": "sharon7313@gmail.com",
        "phone": null,
        "firstName": "Chung",
        "lastName": "Sharon",
        "address": ""
    },
    {
        "email": "chleeap@gmail.com",
        "phone": null,
        "firstName": "",
        "lastName": "",
        "address": ""
    },
    {
        "email": "infinitetsl@gamil.com",
        "phone": 52356121,
        "firstName": "tsui",
        "lastName": "szelam",
        "address": ""
    },
    {
        "email": "huicatherine@gmail.com",
        "phone": 91892821,
        "firstName": "Hui",
        "lastName": "Catherine",
        "address": ""
    },
    {
        "email": "alice50191@gmail.com",
        "phone": 61082111,
        "firstName": "Ng",
        "lastName": "Alice",
        "address": "荃灣楊屋道樂悠居60樓02室, 荃灣楊屋道樂悠居60樓02室"
    },
    {
        "email": "vanessa@cuhk.edu.hk",
        "phone": null,
        "firstName": "Tse",
        "lastName": "Vanessa",
        "address": ""
    },
    {
        "email": "y1230654897@yahoo.com.hk",
        "phone": 53002656,
        "firstName": "Chow",
        "lastName": "Yuen Yee",
        "address": ""
    },
    {
        "email": "cheunghl526@yahoo.com.hk",
        "phone": 65758032,
        "firstName": "Cheung",
        "lastName": "Hoilam",
        "address": ""
    },
    {
        "email": "katherine.tong@doggiebobo.com",
        "phone": 91544045,
        "firstName": "Katherine",
        "lastName": "Tong",
        "address": ""
    },
    {
        "email": "krisleekt@gmail.com",
        "phone": 68752134,
        "firstName": "Lee",
        "lastName": "Kristen",
        "address": ""
    },
    {
        "email": "c16kp@hotmail.com",
        "phone": 51358329,
        "firstName": "Lam",
        "lastName": "Kai yuk",
        "address": "FlatB,4/F,Block3,Hoi Tak Gardens,, 13-17Wing Fat Lane,"
    },
    {
        "email": "bee880201@gmail.com",
        "phone": 62747489,
        "firstName": "馮",
        "lastName": "燕貞",
        "address": ""
    },
    {
        "email": "mpy224@gmail.com",
        "phone": 53289120,
        "firstName": "Mo",
        "lastName": "Pui Yu",
        "address": ""
    },
    {
        "email": "siuchuloveming@gmail.com",
        "phone": 67701449,
        "firstName": "Chu",
        "lastName": "Vicky",
        "address": ""
    },
    {
        "email": "moonwolf1218@gmail.com",
        "phone": 61767745,
        "firstName": "李",
        "lastName": "婷宜",
        "address": ""
    },
    {
        "email": "katherinetong1026@gmail.com",
        "phone": null,
        "firstName": "katherine",
        "lastName": "tong",
        "address": ""
    },
    {
        "email": "pealpaul@yahoo.com.hk",
        "phone": null,
        "firstName": "Chan",
        "lastName": "Angela",
        "address": ""
    },
    {
        "email": "gabbychong@hotmail.com",
        "phone": 62102536,
        "firstName": "Chong",
        "lastName": "Gabby",
        "address": ""
    },
    {
        "email": "chloewong818@yahoo.com",
        "phone": 55990308,
        "firstName": "Wong",
        "lastName": "Chloe",
        "address": "Flat 1904, 19/F, Block A, Choi Hing Court"
    },
    {
        "email": "wongcherry20outlook.com@gmail.com",
        "phone": 98072520,
        "firstName": "wong",
        "lastName": "cheuk kwan",
        "address": ""
    },
    {
        "email": "tkaman212@gmail.com",
        "phone": null,
        "firstName": "",
        "lastName": "Catherine",
        "address": ""
    },
    {
        "email": "nanatsenana@gmail.com",
        "phone": 65091136,
        "firstName": "Tse",
        "lastName": "",
        "address": ""
    },
    {
        "email": "angus0809@yahoo.com.hk",
        "phone": 93858523,
        "firstName": "Tanapat",
        "lastName": "Angus",
        "address": ""
    },
    {
        "email": "nicko.hui@gmail.com",
        "phone": 93375083,
        "firstName": "Nicko",
        "lastName": "Hui",
        "address": ""
    },
    {
        "email": "east830919@gmail.com",
        "phone": null,
        "firstName": "巫",
        "lastName": "東正",
        "address": ""
    },
    {
        "email": "hocelia@msn.com",
        "phone": 98326484,
        "firstName": "Celia",
        "lastName": "Ho",
        "address": ""
    },
    {
        "email": "asurahk@hotmail.com",
        "phone": 98628348,
        "firstName": "Frederick",
        "lastName": "Lai",
        "address": ""
    },
    {
        "email": "lokyi125@gmail.com",
        "phone": 55959566,
        "firstName": "Lam",
        "lastName": "Lok",
        "address": "Flat 9E, Tower 8, The Laguna, The Bloomsway,, Tsing Ying Rd 18"
    },
    {
        "email": "verayeung1994@gmail.com",
        "phone": 56116095,
        "firstName": "yeung",
        "lastName": "wing man",
        "address": "小巴 彭屋站 林村蕉徑路, https://goo.gl/maps/eGhtbdjNxzR7dsuK8 (星期六﹑日)"
    },
    {
        "email": "yyw.hung@gmail.com",
        "phone": 93611160,
        "firstName": "洪",
        "lastName": "Yvonne",
        "address": ""
    },
    {
        "email": "owenchaneoin@yahoo.com",
        "phone": 69097797,
        "firstName": "Chan",
        "lastName": "Owen",
        "address": ""
    },
    {
        "email": "puiyocn@gmail.com",
        "phone": 92389546,
        "firstName": "Tang",
        "lastName": "Doris",
        "address": ""
    },
    {
        "email": "12_30@live.hk",
        "phone": 60271423,
        "firstName": "chan",
        "lastName": "iris",
        "address": ""
    },
    {
        "email": "chuipang222@gmail.com",
        "phone": 91084393,
        "firstName": "Pang",
        "lastName": "Kic",
        "address": ""
    },
    {
        "email": "whangleung@gmail.com",
        "phone": 55263368,
        "firstName": "leung",
        "lastName": "WH",
        "address": ""
    },
    {
        "email": "marianite0510@gmail.com",
        "phone": 98146978,
        "firstName": "Wang",
        "lastName": "Marian R.",
        "address": "Room A3, Flat A, 1/F, Clarence Court, 8-16 Clarence Terrace, Shek Tong Tsui, Sai Wan, Hong Kong"
    },
    {
        "email": "wingyan_koon@yahoo.com.hk",
        "phone": 61230825,
        "firstName": "管",
        "lastName": "小姐",
        "address": ""
    },
    {
        "email": "kylpauline48@gmail.com",
        "phone": 96672992,
        "firstName": "Kwok",
        "lastName": "Cindy",
        "address": ""
    },
    {
        "email": "boboma1111@yahoo.com.hk",
        "phone": 59952826,
        "firstName": "Chan",
        "lastName": "bowie",
        "address": ""
    },
    {
        "email": "queenise64@gmail.com",
        "phone": 94096134,
        "firstName": "Betty",
        "lastName": "Betty",
        "address": ""
    },
    {
        "email": "janice_lau@ymail.com",
        "phone": 97351228,
        "firstName": "Lau",
        "lastName": "Janice",
        "address": ""
    },
    {
        "email": "waiwai16e@gmail.com",
        "phone": 56164128,
        "firstName": "Wong",
        "lastName": "Hiu Wai",
        "address": ""
    },
    {
        "email": "yipkaman@gmail.com",
        "phone": 96698606,
        "firstName": "Yip",
        "lastName": "Carmen",
        "address": ""
    },
    {
        "email": "angela@eastern-hill.com.hk",
        "phone": 92286678,
        "firstName": "Morrow Fan",
        "lastName": "Angela PK",
        "address": ""
    },
    {
        "email": "minorilai@yahoo.com.hk",
        "phone": 98384275,
        "firstName": "Kan",
        "lastName": "Candy",
        "address": ""
    },
    {
        "email": "tangg19960411@gmail.com",
        "phone": 66068807,
        "firstName": "Tang",
        "lastName": "Chi Hong",
        "address": ""
    },
    {
        "email": "francesau0@gmail.com",
        "phone": null,
        "firstName": "Au",
        "lastName": "Frances",
        "address": ""
    },
    {
        "email": "fflsm0909@gmail.com",
        "phone": 64111492,
        "firstName": "Lee",
        "lastName": "Florence",
        "address": ""
    },
    {
        "email": "yeemeileung@ymail.com",
        "phone": null,
        "firstName": "Leung",
        "lastName": "Yee Mei",
        "address": ""
    },
    {
        "email": "remy_kwan@yahoo.com.hk",
        "phone": null,
        "firstName": "Kwan",
        "lastName": "",
        "address": ""
    },
    {
        "email": "nicho587@gmail.com",
        "phone": null,
        "firstName": "何",
        "lastName": "志良",
        "address": ""
    },
    {
        "email": "karinwaus@gmail.com",
        "phone": null,
        "firstName": "Wu",
        "lastName": "Karin",
        "address": ""
    },
    {
        "email": "cacalove14@hotmail.com",
        "phone": 65048444,
        "firstName": "KWOK",
        "lastName": "CASSIE",
        "address": "8號 擴業街, ROOM 21A TOWER 2 THE SPECTRA"
    },
    {
        "email": "mousse1995@gmail.com",
        "phone": 65330217,
        "firstName": "Lau",
        "lastName": "Joyce",
        "address": "9B, Block 5, La Grove, 83, Shap Pat Heung Road"
    },
    {
        "email": "joannlo1988@yahoo.com",
        "phone": 97300310,
        "firstName": "Lo",
        "lastName": "Joann",
        "address": ""
    },
    {
        "email": "pingting23@hotmail.com",
        "phone": 66110573,
        "firstName": "Chen",
        "lastName": "Becky",
        "address": "6 迎康街, 1908, Tower 6A, Century Link"
    },
    {
        "email": "hoiwahvincent@hotmail.com",
        "phone": 93670307,
        "firstName": "Lau",
        "lastName": "Hoi Wah",
        "address": ""
    },
    {
        "email": "g2374@yahoo.com.hk",
        "phone": null,
        "firstName": "Tam",
        "lastName": "PY",
        "address": ""
    },
    {
        "email": "joey122193@yahoo.com.hk",
        "phone": 98210404,
        "firstName": "Lam",
        "lastName": "Joey",
        "address": ""
    },
    {
        "email": "hohiuching303@gmail.com",
        "phone": null,
        "firstName": "Ho",
        "lastName": "Erica",
        "address": ""
    },
    {
        "email": "lsy01831@gmail.com",
        "phone": 54089331,
        "firstName": "Leung",
        "lastName": "Sumyee",
        "address": ""
    },
    {
        "email": "tseyuetkwan@hotmail.com",
        "phone": 65010442,
        "firstName": "Tse",
        "lastName": "Brandy",
        "address": "天耀邨耀民樓, 34樓3423室"
    },
    {
        "email": "hwymichelle@yahoo.clm.hk",
        "phone": null,
        "firstName": "Heung",
        "lastName": "Wing yan",
        "address": ""
    },
    {
        "email": "yukiwmw96@gmail.com",
        "phone": 63570073,
        "firstName": "Tina",
        "lastName": "Wu",
        "address": ""
    },
    {
        "email": "kathypanghy@gmail.com",
        "phone": 95729828,
        "firstName": "Pang",
        "lastName": "Kathy",
        "address": ""
    },
    {
        "email": "sherrychik06@gmail.com",
        "phone": 24620977,
        "firstName": "Chik",
        "lastName": "Sherry",
        "address": ""
    },
    {
        "email": "shipage@gmail.com",
        "phone": 97096709,
        "firstName": "Lam",
        "lastName": "Joe",
        "address": ""
    },
    {
        "email": "huiemomo@gmail.com",
        "phone": 93287473,
        "firstName": "hui",
        "lastName": "6/4/2021 19:02",
        "address": ""
    },
    {
        "email": "szesze_bb@yahoo.com.hk",
        "phone": 93103858,
        "firstName": "IP",
        "lastName": "PUI SZE",
        "address": ""
    },
    {
        "email": "siuyu0527@gmail.com",
        "phone": 56255278,
        "firstName": "Fan",
        "lastName": "Hiu Yu",
        "address": ""
    },
    {
        "email": "kellychanchan5986@gmail.com",
        "phone": 53288659,
        "firstName": "Chan",
        "lastName": "Tsz wai",
        "address": ""
    },
    {
        "email": "jocelynv3v@yahoo.com.hk",
        "phone": null,
        "firstName": "Lam",
        "lastName": "Yin ling",
        "address": "Flat 8E, Wah Po Bldg, 1 New praya street"
    },
    {
        "email": "ononlaw@gmail.com",
        "phone": 91782682,
        "firstName": "Law",
        "lastName": "Anthony",
        "address": ""
    },
    {
        "email": "tonywfcheng0127@gmail.com",
        "phone": 90172357,
        "firstName": "CHENG",
        "lastName": "TONY",
        "address": "Room 2512, Wah Min House, Wah Sum Estate"
    },
    {
        "email": "elsawmmak@gmail.com",
        "phone": 92220728,
        "firstName": "Mak",
        "lastName": "Wai Man",
        "address": ""
    },
    {
        "email": "hotohoir@yahoo.com.hk",
        "phone": 92424545,
        "firstName": "Cheung",
        "lastName": "Lai kwan",
        "address": ""
    },
    {
        "email": "jancheung14@gmail.com",
        "phone": 97064078,
        "firstName": "Cheung",
        "lastName": "1/5/2021 0:00",
        "address": ""
    },
    {
        "email": "lkctracy1221@gmail.com",
        "phone": 60766598,
        "firstName": "Leung",
        "lastName": "Tracy",
        "address": ""
    },
    {
        "email": "kelvinfan1229@yahoo.com.hk",
        "phone": 92810210,
        "firstName": "Fan",
        "lastName": "Ka Fai",
        "address": ""
    },
    {
        "email": "virgotsy@gmail.com",
        "phone": 56030158,
        "firstName": "tse",
        "lastName": "virgo",
        "address": ""
    },
    {
        "email": "cchancindyy@gmail.com",
        "phone": 51158717,
        "firstName": "Chan",
        "lastName": "Cindy",
        "address": ""
    },
    {
        "email": "william606@yahoo.com.hk",
        "phone": 95572978,
        "firstName": "wu",
        "lastName": "william",
        "address": ""
    },
    {
        "email": "mingdom510@gmail.com",
        "phone": 92511055,
        "firstName": "Tsang",
        "lastName": "Chung Ming",
        "address": ""
    },
    {
        "email": "hazelwong0703@gmail.com",
        "phone": 59016586,
        "firstName": "Wong",
        "lastName": "Hazel",
        "address": ""
    },
    {
        "email": "ysh3986@gmail.com",
        "phone": null,
        "firstName": "yeung",
        "lastName": "siu hong",
        "address": ""
    },
    {
        "email": "yk814053@gmail.com",
        "phone": null,
        "firstName": "楊",
        "lastName": "棱",
        "address": ""
    },
    {
        "email": "raymond19999@yahoo.com.hk",
        "phone": 64913303,
        "firstName": "Kwok",
        "lastName": "Raymond",
        "address": ""
    },
    {
        "email": "lp6971@gmail.com",
        "phone": null,
        "firstName": "Lai",
        "lastName": "Kevin",
        "address": "RM 226, KIN WA HOUSE, KIN MING ESTATE,, TSEUNG KWAN O, N.T."
    },
    {
        "email": "louise.tse@tvb.com.hk",
        "phone": 90318441,
        "firstName": "Tse",
        "lastName": "Louise",
        "address": "unit c 16f blk 2 serenity park, 18 tai Po tau road"
    },
    {
        "email": "stephy_rainrain@hotmail.com",
        "phone": 64830262,
        "firstName": "lam",
        "lastName": "wing",
        "address": "Flat B, 1/F, Block 8 Lok Hing Court,, Chi"
    },
    {
        "email": "melotizz530@gmail.com",
        "phone": 56226019,
        "firstName": "Lam",
        "lastName": "Melody",
        "address": ""
    },
    {
        "email": "estherorz@gmail.com",
        "phone": 62740328,
        "firstName": "Ho",
        "lastName": "Yuen Ting",
        "address": ""
    },
    {
        "email": "lamoiling@hotmail.com",
        "phone": null,
        "firstName": "Lam",
        "lastName": "Christy",
        "address": ""
    },
    {
        "email": "wankwankwan519@gmail.com",
        "phone": null,
        "firstName": "Wan",
        "lastName": "Lok Kwan",
        "address": ""
    },
    {
        "email": "kawo@live.hk",
        "phone": 97915154,
        "firstName": "chan",
        "lastName": "ka wo",
        "address": ""
    },
    {
        "email": "hym.cordia@yahoo.com",
        "phone": 61405367,
        "firstName": "ho",
        "lastName": "Cordia",
        "address": ""
    },
    {
        "email": "hsttttsos@gmail.com",
        "phone": 63822483,
        "firstName": "Tam",
        "lastName": "Hin sin",
        "address": ""
    },
    {
        "email": "q64876091@gmail.com",
        "phone": 54485296,
        "firstName": "WONG",
        "lastName": "KA YEE",
        "address": ""
    },
    {
        "email": "princeyan@hotmail.com",
        "phone": 95121913,
        "firstName": "Wong",
        "lastName": "Tracy",
        "address": "Flat 5, 6/F, Block 5, Goodview Garden, 7 Hoi Chu Road"
    },
    {
        "email": "carolrol47@hotmail.com",
        "phone": 69740747,
        "firstName": "Wong",
        "lastName": "Carol",
        "address": "24/F, Wealthy ind. Bldg, 22-26 Wing Yip Street"
    },
    {
        "email": "alan0685@gmail.com",
        "phone": 51303079,
        "firstName": "Yiu",
        "lastName": "Alan",
        "address": ""
    },
    {
        "email": "corrylee53@yahoo.com.hk",
        "phone": 93377330,
        "firstName": "Corry",
        "lastName": "Lee",
        "address": "3/F Yat Tak Ind. Bldg, 21 San On St"
    },
    {
        "email": "terrytong4812@mac.com",
        "phone": 65796831,
        "firstName": "唐",
        "lastName": "",
        "address": ""
    },
    {
        "email": "oovooovo123@gmail.com",
        "phone": 68558509,
        "firstName": "Yuen",
        "lastName": "Wa Ho",
        "address": ""
    },
    {
        "email": "carmanwhm@outlook.com",
        "phone": 90124166,
        "firstName": "Wong",
        "lastName": "Carman",
        "address": ""
    },
    {
        "email": "selinasfx@gmail.com",
        "phone": 51313677,
        "firstName": "Selina",
        "lastName": "Wong",
        "address": ""
    },
    {
        "email": "fat_corns@hotmail.com",
        "phone": 90951420,
        "firstName": "Ming Kin",
        "lastName": "Chuk",
        "address": ""
    },
    {
        "email": "lampuichi0730@gmail.com",
        "phone": 65965810,
        "firstName": "Pui Chi",
        "lastName": "Lam",
        "address": ""
    },
    {
        "email": "toffee1027@gmail.com",
        "phone": 60569761,
        "firstName": "Hing keung",
        "lastName": "Taang",
        "address": ""
    },
    {
        "email": "ccy200116@gmail.com",
        "phone": 66893896,
        "firstName": "Chun Yuen",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "kakachen91@gmail.com",
        "phone": 60803162,
        "firstName": "Kaka",
        "lastName": "Chen",
        "address": ""
    },
    {
        "email": "yungtsunwai@gmail.com",
        "phone": 63346178,
        "firstName": "tsun wai",
        "lastName": "yung",
        "address": "大水坑村, 1號A2樓"
    },
    {
        "email": "miniiilaiii@gmail.com",
        "phone": 52263222,
        "firstName": "Jovis",
        "lastName": "Wong",
        "address": ""
    },
    {
        "email": "ralchan1608@gmail.com",
        "phone": 668344933,
        "firstName": "Rachel",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "monicacym0107@hotmail.com",
        "phone": 63787679,
        "firstName": "Yuen man",
        "lastName": "Cheng",
        "address": ""
    },
    {
        "email": "pretty_eight@yahoo.com",
        "phone": 60550312,
        "firstName": "So",
        "lastName": "Frankie",
        "address": ""
    },
    {
        "email": "shirleymok970420@gmail.com",
        "phone": 92373999,
        "firstName": "Shirley",
        "lastName": "\\-",
        "address": ""
    },
    {
        "email": "s.hovej@gmail.com",
        "phone": 60741628,
        "firstName": "Siu",
        "lastName": "Isa",
        "address": ""
    },
    {
        "email": "zoecheung1211@yahoo.com.hk",
        "phone": 66778355,
        "firstName": "Zoe",
        "lastName": "Cheung",
        "address": ""
    },
    {
        "email": "ouyanghaulam@gmail.com",
        "phone": null,
        "firstName": "HAU LAM",
        "lastName": "OU YANG",
        "address": ""
    },
    {
        "email": "914336668@qq.com",
        "phone": 51113294,
        "firstName": "泳琳",
        "lastName": "潘",
        "address": "852FCL 大埔昌運中心順豐站, 香港大埔區大埔安慈路4號昌運中心地下A5 舖"
    },
    {
        "email": "xyinx0303@gmail.com",
        "phone": 67620995,
        "firstName": "Leo",
        "lastName": "Cheung",
        "address": ""
    },
    {
        "email": "frodofung@yahoo.com.hk",
        "phone": 98214198,
        "firstName": "Frodo",
        "lastName": "Fung",
        "address": "417 漆咸道北, 3D"
    },
    {
        "email": "shy@gmail.com",
        "phone": null,
        "firstName": "嘉",
        "lastName": "何",
        "address": ""
    },
    {
        "email": "kitpuisang@hotmail.com",
        "phone": 68923256,
        "firstName": "",
        "lastName": "曾",
        "address": ""
    },
    {
        "email": "rabbitlau625@yahoo.com.hk",
        "phone": 62289625,
        "firstName": "",
        "lastName": "Esther Lau",
        "address": ""
    },
    {
        "email": "arthur.lailai@gmail.com",
        "phone": 92055451,
        "firstName": "Wai Kin",
        "lastName": "Lai",
        "address": ""
    },
    {
        "email": "timothylam08@hotmail.com",
        "phone": 94742187,
        "firstName": "Tim",
        "lastName": "Lam",
        "address": ""
    },
    {
        "email": "kaykayamigo@gmail.com",
        "phone": 63892459,
        "firstName": "Earnest",
        "lastName": "Chung",
        "address": "Flat E, 29/F, Block 38, City One, Shatin"
    },
    {
        "email": "anson200706@yahoo.com.hk",
        "phone": 94411456,
        "firstName": "",
        "lastName": "Chiu",
        "address": ""
    },
    {
        "email": "rchanko@gmail.com",
        "phone": 53953940,
        "firstName": "國安",
        "lastName": "陳",
        "address": "254 德輔道中金融商業大廈, 1字樓A室"
    },
    {
        "email": "cherrywu0309@gmail.com",
        "phone": null,
        "firstName": "吳",
        "lastName": "紫瑄",
        "address": ""
    },
    {
        "email": "quby_ching@yahoo.com.hk",
        "phone": 96523937,
        "firstName": "Quby",
        "lastName": "Tang",
        "address": ""
    },
    {
        "email": "chui_yee130@hotmail.com",
        "phone": 67601307,
        "firstName": "",
        "lastName": "Kwan",
        "address": ""
    },
    {
        "email": "sukiiiiiyue@gmail.com",
        "phone": 96526636,
        "firstName": "Suki",
        "lastName": "Yue",
        "address": ""
    },
    {
        "email": "cfconnie@icloud.com",
        "phone": null,
        "firstName": "Fan",
        "lastName": "Connie",
        "address": ""
    },
    {
        "email": "siujendonutb@gmail.com",
        "phone": 63545389,
        "firstName": "Jennifer",
        "lastName": "Cheung",
        "address": ""
    },
    {
        "email": "angelyeung1213@outlook.com",
        "phone": 94430166,
        "firstName": "Angel",
        "lastName": "Yeung",
        "address": ""
    },
    {
        "email": "jerry.ckwing@gmail.com",
        "phone": 67601798,
        "firstName": "Chan",
        "lastName": "Ka Wing",
        "address": "Flat A, 10/F, Block 2, Parkland Villas,, No.1 Tuen On Lane"
    },
    {
        "email": "yoyohung1992@hotmail.com.hk",
        "phone": 60307533,
        "firstName": "YoYo",
        "lastName": "Hung",
        "address": ""
    },
    {
        "email": "sauw1015@gmail.com",
        "phone": 61538204,
        "firstName": "秀婷",
        "lastName": "黃",
        "address": ""
    },
    {
        "email": "evayi1126@gmail.com",
        "phone": 97725935,
        "firstName": "Sunny",
        "lastName": "Law",
        "address": ""
    },
    {
        "email": "iamevaar@gmail.com",
        "phone": 64883675,
        "firstName": "NG",
        "lastName": "EVA",
        "address": ""
    },
    {
        "email": "shiryng12@gmail.com",
        "phone": 60870906,
        "firstName": "Ng",
        "lastName": "Shiry",
        "address": "西貢清水灣, 容容路43號"
    },
    {
        "email": "pollylam227424@gmail.com",
        "phone": null,
        "firstName": "Lam",
        "lastName": "on ki",
        "address": ""
    },
    {
        "email": "shanshan701@hotmail.com",
        "phone": 64384452,
        "firstName": "",
        "lastName": "Lam",
        "address": ""
    },
    {
        "email": "eddy_wong0122@yahoo.com.hk",
        "phone": 97654331,
        "firstName": "Wong",
        "lastName": "Eddy",
        "address": ""
    },
    {
        "email": "wan_erico@yahoo.com.hk",
        "phone": 96968394,
        "firstName": "LI FUN ERICO",
        "lastName": "WAN",
        "address": ""
    },
    {
        "email": "wincy_lai1993@hotmail.com",
        "phone": 63766062,
        "firstName": "wingyi",
        "lastName": "lai",
        "address": ""
    },
    {
        "email": "doryiu@yahoo.com.hk",
        "phone": 97821897,
        "firstName": "doryiu",
        "lastName": "cheung",
        "address": ""
    },
    {
        "email": "katelam110@gmail.com",
        "phone": 96732980,
        "firstName": "Lam",
        "lastName": "Kate",
        "address": "Flat 05, 10/F, Block D, Luk Yeung Sun Chuen"
    },
    {
        "email": "patkyyu@gmail.com",
        "phone": 91728406,
        "firstName": "小姐",
        "lastName": "余",
        "address": ""
    },
    {
        "email": "cccmartin46@gmail.com",
        "phone": 60823404,
        "firstName": "Chan",
        "lastName": "Chung Chuen",
        "address": "38 興華街, 海華麗軒8樓C室"
    },
    {
        "email": "puiyanchu823@gmail.com",
        "phone": 93116412,
        "firstName": "Noel",
        "lastName": "Chu",
        "address": "Flat51A, Block 1, Coastal Skyline, Tung Chung"
    },
    {
        "email": "hazelmiu17@gmail.com",
        "phone": 67953891,
        "firstName": "",
        "lastName": "Mow",
        "address": ""
    },
    {
        "email": "ellen130214@gmail.com",
        "phone": 97064078,
        "firstName": "1/5/2021 0:00",
        "lastName": "cheung",
        "address": ""
    },
    {
        "email": "317509674@qq.com",
        "phone": 51348814,
        "firstName": "Manlin",
        "lastName": "Yuan",
        "address": ""
    },
    {
        "email": "rabbitblue@yahoo.com",
        "phone": 98071801,
        "firstName": "Hon",
        "lastName": "Coco",
        "address": ""
    },
    {
        "email": "jz836bb@yahoo.com.hk",
        "phone": 92873731,
        "firstName": "Ip",
        "lastName": "Celia",
        "address": "兆興里, 兆麟苑 瑞麟閣 18樓 5室"
    },
    {
        "email": "ahstarl@yahoo.com.hk",
        "phone": 96379437,
        "firstName": "Lam",
        "lastName": "Esther",
        "address": ""
    },
    {
        "email": "ccsy2000@yahoo.com",
        "phone": null,
        "firstName": "Chow",
        "lastName": "Miss",
        "address": ""
    },
    {
        "email": "tsztungh@gmail.com",
        "phone": 65952998,
        "firstName": "hon",
        "lastName": "tsz tung",
        "address": ""
    },
    {
        "email": "kayeefanny@gmail.com",
        "phone": 54058317,
        "firstName": "sham",
        "lastName": "ka yee fanny",
        "address": ""
    },
    {
        "email": "tinelsa@hotmail.com",
        "phone": 91295313,
        "firstName": "Elsa",
        "lastName": "Tin",
        "address": "Kwai Yi Road, 1"
    },
    {
        "email": "charliewong1128@gmail.com",
        "phone": 92446947,
        "firstName": "Ka Yiu",
        "lastName": "Wong",
        "address": ""
    },
    {
        "email": "sandramak@ymail.com",
        "phone": 93666193,
        "firstName": "Mak",
        "lastName": "Sandra",
        "address": ""
    },
    {
        "email": "chunho2k@yahoo.com.hk",
        "phone": 96311870,
        "firstName": "Chun Ho",
        "lastName": "Lun",
        "address": ""
    },
    {
        "email": "mob1604@gmail.com",
        "phone": null,
        "firstName": "Mob",
        "lastName": "Wu",
        "address": ""
    },
    {
        "email": "noel2000.nc@gmail.com",
        "phone": 51178350,
        "firstName": "chung",
        "lastName": "wing lam",
        "address": ""
    },
    {
        "email": "nyt_joyce@yahoo.com.hk",
        "phone": null,
        "firstName": "Ng",
        "lastName": "Yin Ting",
        "address": ""
    },
    {
        "email": "hailylo123@hotmail.com",
        "phone": 91399618,
        "firstName": "Lo",
        "lastName": "Haily",
        "address": "Flat A, 5/F, Block 16,, Hoi Tsui Mansion, Riviera Gardens"
    },
    {
        "email": "yeunghongyi@gmail.com",
        "phone": 69765590,
        "firstName": "Yeung",
        "lastName": "Sammi",
        "address": ""
    },
    {
        "email": "smallchan25@hotmail.com",
        "phone": null,
        "firstName": "陳",
        "lastName": "世豪",
        "address": ""
    },
    {
        "email": "atsang514@gmail.com",
        "phone": null,
        "firstName": "Tsang",
        "lastName": "Angel",
        "address": ""
    },
    {
        "email": "kaming1114@gmail.com",
        "phone": 63888626,
        "firstName": "Kaming",
        "lastName": "Lo",
        "address": ""
    },
    {
        "email": "watermonster.sos@gmail.com",
        "phone": 94831418,
        "firstName": "Harper",
        "lastName": "Law",
        "address": ""
    },
    {
        "email": "peggyjo@netvigator.com",
        "phone": 90228448,
        "firstName": "peggy",
        "lastName": "so",
        "address": ""
    },
    {
        "email": "pui032@yahoo.com",
        "phone": 94162915,
        "firstName": "Wong",
        "lastName": "Wong",
        "address": ""
    },
    {
        "email": "orangebig@gmail.com",
        "phone": 61261144,
        "firstName": "Yung",
        "lastName": "Big",
        "address": ""
    },
    {
        "email": "jenny.ppchan@gmail.com",
        "phone": null,
        "firstName": "Jenny",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "ling0x2v3v@gmail.com",
        "phone": null,
        "firstName": "Tse",
        "lastName": "Ling ling",
        "address": ""
    },
    {
        "email": "aliceyee04@yahoo.com.hk",
        "phone": 93168277,
        "firstName": "Wai Yee",
        "lastName": "Cheung",
        "address": "華景山路9號, 華景山莊18座19樓C室"
    },
    {
        "email": "siuyoi625@gmail.com",
        "phone": 69930931,
        "firstName": "yu man",
        "lastName": "ng",
        "address": ""
    },
    {
        "email": "sunnyyylai@gmail.com",
        "phone": null,
        "firstName": "耀揚",
        "lastName": "黎",
        "address": ""
    },
    {
        "email": "swing.kero@gmail.com",
        "phone": 91822626,
        "firstName": "SWING",
        "lastName": "MAN",
        "address": ""
    },
    {
        "email": "leeraymond78@gmail.com",
        "phone": null,
        "firstName": "Raymond",
        "lastName": "Li",
        "address": ""
    },
    {
        "email": "raraiam216@gmail.com",
        "phone": 51732828,
        "firstName": "Chan",
        "lastName": "Tsz lam",
        "address": ""
    },
    {
        "email": "shingyan1215@gmail.com",
        "phone": 93277351,
        "firstName": "Shayne",
        "lastName": "Cheung",
        "address": ""
    },
    {
        "email": "skywalker0315@gmail.com",
        "phone": 98052646,
        "firstName": "Ivan",
        "lastName": "Chong",
        "address": "Rm743 Leung Chi Hse, Leung King Est"
    },
    {
        "email": "yankwan127@gmail.com",
        "phone": 64229691,
        "firstName": "Kwan",
        "lastName": "Yannis",
        "address": ""
    },
    {
        "email": "ansoncph@hotmail.com",
        "phone": 93820102,
        "firstName": "先生",
        "lastName": "陳",
        "address": ""
    },
    {
        "email": "arpooh.2400.xp@gmail.com",
        "phone": null,
        "firstName": "Poon",
        "lastName": "Onki",
        "address": ""
    },
    {
        "email": "hiuchingfan@gmail.com",
        "phone": 96165682,
        "firstName": "Jackie",
        "lastName": "Fan",
        "address": ""
    },
    {
        "email": "marshmallow1356@gmail.com",
        "phone": null,
        "firstName": "Kwai ki",
        "lastName": "Tse",
        "address": ""
    },
    {
        "email": "poling0202@hotmail.com",
        "phone": null,
        "firstName": "黃",
        "lastName": "保寧",
        "address": ""
    },
    {
        "email": "paradisehousehk@gmail.com",
        "phone": 62825628,
        "firstName": "Na",
        "lastName": "Fung",
        "address": "香港旺角西洋菜北街161號, 恆安樓4樓402室"
    },
    {
        "email": "hoiyinglin@hotmail.com",
        "phone": 93361139,
        "firstName": "Hoi Ying",
        "lastName": "Lin",
        "address": "33號 高超道, LEI YUE MUN ESTATE"
    },
    {
        "email": "dannyhcho@gmail.com",
        "phone": 96836052,
        "firstName": "Ho",
        "lastName": "Danny",
        "address": ""
    },
    {
        "email": "adamkwok0330@gmail.com",
        "phone": 51991669,
        "firstName": "Hui",
        "lastName": "Kwok",
        "address": ""
    },
    {
        "email": "mungc88sinying@gmail.com",
        "phone": 92288227,
        "firstName": "Kimmy",
        "lastName": "Mung",
        "address": "G/F 25A Tseng Tau Chuen,, Tung Tze Road, Taipo"
    },
    {
        "email": "zchan53@yahoo.com.hk",
        "phone": 56169887,
        "firstName": "Winnie",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "manmanmak107@gmail.com",
        "phone": 60901446,
        "firstName": "",
        "lastName": "麥",
        "address": "1406,14/F,Leung Kit House, Leung King, Estate, Tuen Mun"
    },
    {
        "email": "sweet_time2000@hotmail.com",
        "phone": 61358513,
        "firstName": "Livia",
        "lastName": "Tang",
        "address": ""
    },
    {
        "email": "kwokdd1230@gmail.com",
        "phone": 90101441,
        "firstName": "Katherine",
        "lastName": "Kwok",
        "address": ""
    },
    {
        "email": "karentong9015@yahoo.com",
        "phone": 62369383,
        "firstName": "Hoi ko",
        "lastName": "Tong",
        "address": ""
    },
    {
        "email": "zita2005605@gmail.com",
        "phone": 54039402,
        "firstName": "Lau",
        "lastName": "Sze Wai",
        "address": ""
    },
    {
        "email": "smallymow@yahoo.com.hk",
        "phone": 61998880,
        "firstName": "iris",
        "lastName": "chan",
        "address": "362-388 青山公路青山灣段, 恆順園7座7樓A"
    },
    {
        "email": "mokjieyu@gmail.com",
        "phone": 96196201,
        "firstName": "Li",
        "lastName": "Vivian",
        "address": "Room E, 28/F, Block 3,, Tai Hing Gardens Phase II, Tuen Mun"
    },
    {
        "email": "heidipopo@gmail.com",
        "phone": 96298549,
        "firstName": "Heidi",
        "lastName": "Po",
        "address": ""
    },
    {
        "email": "kareytse@gmail.com",
        "phone": null,
        "firstName": "Tse",
        "lastName": "Karey",
        "address": ""
    },
    {
        "email": "kumi794.hk@gmail.com",
        "phone": 68215765,
        "firstName": "Kumi",
        "lastName": "Ho",
        "address": "Room J 8/F blk9, Fullview Garden, 18 Siu Sai Wan Road"
    },
    {
        "email": "yukingan3122000@gmail.com",
        "phone": null,
        "firstName": "Ngan",
        "lastName": "Yuki",
        "address": ""
    },
    {
        "email": "lny96379437@gmail.com",
        "phone": 96379437,
        "firstName": "Lam",
        "lastName": "Esther",
        "address": "Flat D, 8/F, Block 2,, Harvest Garden, 1 Wing Fat Lane, Tuen Mun, N.T."
    },
    {
        "email": "lodi1027@hotmail.com",
        "phone": 91617962,
        "firstName": "Lo",
        "lastName": "James",
        "address": ""
    },
    {
        "email": "michitws@gmail.com",
        "phone": 92400160,
        "firstName": "TSANG",
        "lastName": "WING SHAN",
        "address": ""
    },
    {
        "email": "kan@3key.net",
        "phone": 95877138,
        "firstName": "Kan",
        "lastName": "Ko",
        "address": "287 荔枝角道, 5/F"
    },
    {
        "email": "laamlaamchan@gmail.com",
        "phone": null,
        "firstName": "chan",
        "lastName": "nicole",
        "address": ""
    },
    {
        "email": "wahwahdeedee@gmail.com",
        "phone": 92363678,
        "firstName": "Fong",
        "lastName": "Ying",
        "address": ""
    },
    {
        "email": "speedrider33@hotmail.com",
        "phone": 60868894,
        "firstName": "mak",
        "lastName": "pat",
        "address": "37A Tower 1, Primrose Hill, 168 Kwok Shui road"
    },
    {
        "email": "kellylokalee@gmail.com",
        "phone": 67092128,
        "firstName": "Kelly",
        "lastName": "Lo",
        "address": ""
    },
    {
        "email": "chiukli2@gmail.com",
        "phone": null,
        "firstName": "Li",
        "lastName": "Cherrie",
        "address": ""
    },
    {
        "email": "jobywong24@yahoo.com.hk",
        "phone": null,
        "firstName": "Wong",
        "lastName": "Joby",
        "address": "6 天樂里, 3樓B室"
    },
    {
        "email": "annieimai@gmail.com",
        "phone": null,
        "firstName": "Cho",
        "lastName": "Annie",
        "address": ""
    },
    {
        "email": "judy0101hk@yahoo.com.hk",
        "phone": 98700040,
        "firstName": "Yeung",
        "lastName": "Judy",
        "address": ""
    },
    {
        "email": "sum591@hotmail.com",
        "phone": 51936808,
        "firstName": "Sum",
        "lastName": "Ip",
        "address": "尖沙咀東部科學館道14號新文華中心, 地下showcase G"
    },
    {
        "email": "kellycheuk@blahblahblah.hk",
        "phone": 64879570,
        "firstName": "Kelly",
        "lastName": "Cheuk",
        "address": ""
    },
    {
        "email": "w96763188@gmail.com",
        "phone": null,
        "firstName": "鄭",
        "lastName": "健榮",
        "address": ""
    },
    {
        "email": "monman22001@hotmail.com",
        "phone": 61506564,
        "firstName": "Chan",
        "lastName": "Miki",
        "address": ""
    },
    {
        "email": "amymouse@gmail.com",
        "phone": 92007206,
        "firstName": "WONG",
        "lastName": "CHING MAN AMY",
        "address": ""
    },
    {
        "email": "coco871679@gmail.com",
        "phone": 64174598,
        "firstName": "Chu",
        "lastName": "Coco",
        "address": ""
    },
    {
        "email": "monman22001@yahoo.com.hk",
        "phone": 85261506564,
        "firstName": "Pui Kwan",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "poohyeechan53@gmail.com",
        "phone": 56169887,
        "firstName": "Winnie",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "nicole_fsm@yahoo.com.hk",
        "phone": 61563073,
        "firstName": "Nicole",
        "lastName": "Fung",
        "address": ""
    },
    {
        "email": "allenkwk@gmail.com",
        "phone": 85265753520,
        "firstName": "Wai Kit",
        "lastName": "Kung",
        "address": ""
    },
    {
        "email": "wongtsztsingas@gmail.com",
        "phone": 95221789,
        "firstName": "Wong",
        "lastName": "Tsz Tsing",
        "address": ""
    },
    {
        "email": "angelringlingling@gmail.com",
        "phone": null,
        "firstName": "Li",
        "lastName": "Ling",
        "address": ""
    },
    {
        "email": "wongwong6201@gmail.com",
        "phone": null,
        "firstName": "ww",
        "lastName": "6/5/2021 17:02",
        "address": "w, w"
    },
    {
        "email": "catherinelo.0904@gmail.com",
        "phone": 59804605,
        "firstName": "Sin Yu",
        "lastName": "Lo",
        "address": ""
    },
    {
        "email": "nicolefsm@gmail.com",
        "phone": 61563073,
        "firstName": "Fung",
        "lastName": "Nicole",
        "address": ""
    },
    {
        "email": "fansy1@netvigator.com",
        "phone": null,
        "firstName": "Wong",
        "lastName": "Kenna",
        "address": ""
    },
    {
        "email": "yehmings@gmail.com",
        "phone": 92271937,
        "firstName": "Sin",
        "lastName": "Manting",
        "address": ""
    },
    {
        "email": "chanwingchun63@gmail.com",
        "phone": null,
        "firstName": "Joyce",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "viann319@hotmail.com",
        "phone": 54017524,
        "firstName": "Dash",
        "lastName": "Tang",
        "address": "43號 龍門路, 龍門居7座15D"
    },
    {
        "email": "account.manager@shopping-cart-migration.com",
        "phone": null,
        "firstName": "",
        "lastName": "",
        "address": ""
    },
    {
        "email": "1041497114@qq.com",
        "phone": null,
        "firstName": "",
        "lastName": "",
        "address": ""
    },
    {
        "email": "desmondhk1991@icloud.com",
        "phone": null,
        "firstName": "",
        "lastName": "",
        "address": ""
    },
    {
        "email": "rng55659@gmail.com",
        "phone": 51784879,
        "firstName": "俊延",
        "lastName": "吳",
        "address": ""
    },
    {
        "email": "f@gmail.com",
        "phone": 91544045,
        "firstName": "katherine",
        "lastName": "6/5/2021 0:02",
        "address": "1, 1"
    },
    {
        "email": "s@gmail.com",
        "phone": null,
        "firstName": "6/5/2021 13:02",
        "lastName": "6/5/2021 13:02",
        "address": "s, s"
    },
    {
        "email": "yan00271@hotmail.com",
        "phone": 67080252,
        "firstName": "Alley",
        "lastName": "Tsang",
        "address": ""
    },
    {
        "email": "loyvonnehy@yahoo.com.hk",
        "phone": 95558756,
        "firstName": "",
        "lastName": "Lo",
        "address": ""
    },
    {
        "email": "yukilam760@gmail.com",
        "phone": 69769027,
        "firstName": "kit",
        "lastName": "lam",
        "address": ""
    },
    {
        "email": "pchaaaaa@gmail.com",
        "phone": 61389552,
        "firstName": "詠琳",
        "lastName": "楊",
        "address": ""
    },
    {
        "email": "alice98427@gmail.com",
        "phone": 67680001,
        "firstName": "小姐",
        "lastName": "梁",
        "address": ""
    },
    {
        "email": "ee@gamil.com",
        "phone": null,
        "firstName": "ee",
        "lastName": "6/5/2021 2:02",
        "address": "e, e"
    },
    {
        "email": "lee.cheukkalee@gmail.com",
        "phone": null,
        "firstName": "Cheuk",
        "lastName": "Ka Lee Kelly",
        "address": ""
    },
    {
        "email": "timothy850830@gmail.com",
        "phone": 94483006,
        "firstName": "陸",
        "lastName": "穗添",
        "address": "屯門建生邨樂生樓, 18樓03室"
    },
    {
        "email": "immikimiki@yahoo.com.hk",
        "phone": null,
        "firstName": "Chan",
        "lastName": "Miki",
        "address": ""
    },
    {
        "email": "chanwingyin1982@yahoo.com.hk",
        "phone": 95207504,
        "firstName": "CHAN",
        "lastName": "WING YIN",
        "address": "RM3505, HIN TAK HSE.,, HIN KENG EST.,"
    },
    {
        "email": "jwhahome@gmail.com",
        "phone": 61319405,
        "firstName": "WILSON",
        "lastName": "HA",
        "address": ""
    },
    {
        "email": "ngtszchun02@gmail.com",
        "phone": 21333333,
        "firstName": "ng",
        "lastName": "ng",
        "address": ""
    },
    {
        "email": "ngtszchub@gmail.com",
        "phone": 21333333,
        "firstName": "na",
        "lastName": "na",
        "address": "na, na"
    },
    {
        "email": "ningchoijw@gmai.com",
        "phone": 62263637,
        "firstName": "CHOI",
        "lastName": "NING",
        "address": ""
    },
    {
        "email": "chancherlin@gmail.com",
        "phone": 63810056,
        "firstName": "Nancy",
        "lastName": "Chan",
        "address": "Flat D, 20/F, Tower 3A,, Le Pont, 99 So Kwun Wat Road,"
    },
    {
        "email": "jeromelcw@gmail.com",
        "phone": 65379256,
        "firstName": "",
        "lastName": "Leung",
        "address": ""
    },
    {
        "email": "jenny010170@gmail.com",
        "phone": 91205857,
        "firstName": "Jenny",
        "lastName": "蔡",
        "address": ""
    },
    {
        "email": "onki19971121@yahoo.com.hk",
        "phone": 64301036,
        "firstName": "Winnie",
        "lastName": "Fung",
        "address": ""
    },
    {
        "email": "chwterry@gmail.com",
        "phone": null,
        "firstName": "Terry",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "momoifa@icloud.com",
        "phone": 95386017,
        "firstName": "Fei",
        "lastName": "Lam",
        "address": ""
    },
    {
        "email": "dobbyszeto.1124@gmail.com",
        "phone": null,
        "firstName": "Szeto",
        "lastName": "Dobby",
        "address": ""
    },
    {
        "email": "steps061@gmail.com",
        "phone": 64339514,
        "firstName": "",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "kam231231@gmail.com",
        "phone": 94785893,
        "firstName": "Kam",
        "lastName": "Kelly",
        "address": ""
    },
    {
        "email": "ting8813@yahoo.com.hk",
        "phone": null,
        "firstName": "so",
        "lastName": "ting",
        "address": ""
    },
    {
        "email": "yan.yiu@doggiebobo.com",
        "phone": null,
        "firstName": "",
        "lastName": "",
        "address": ""
    },
    {
        "email": "kelvin.lee@doggiebobo.com",
        "phone": null,
        "firstName": "Kelvin",
        "lastName": "Lee",
        "address": ""
    },
    {
        "email": "dbb.katherine@gmail.com",
        "phone": null,
        "firstName": "",
        "lastName": "",
        "address": ""
    }
  ]

  const webCustomersTest = [
    {
        "email": "jeromelcw@gmail.com",
        "phone": 65379256,
        "firstName": "",
        "lastName": "Leung",
        "address": ""
    },
    {
        "email": "jenny010170@gmail.com",
        "phone": 91205857,
        "firstName": "Jenny",
        "lastName": "蔡",
        "address": ""
    },
    {
        "email": "onki19971121@yahoo.com.hk",
        "phone": 64301036,
        "firstName": "Winnie",
        "lastName": "Fung",
        "address": ""
    },
    {
        "email": "chwterry@gmail.com",
        "phone": null,
        "firstName": "Terry",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "momoifa@icloud.com",
        "phone": 95386017,
        "firstName": "Fei",
        "lastName": "Lam",
        "address": ""
    },
    {
        "email": "dobbyszeto.1124@gmail.com",
        "phone": null,
        "firstName": "Szeto",
        "lastName": "Dobby",
        "address": ""
    },
    {
        "email": "steps061@gmail.com",
        "phone": 64339514,
        "firstName": "",
        "lastName": "Chan",
        "address": ""
    },
    {
        "email": "kam231231@gmail.com",
        "phone": 94785893,
        "firstName": "Kam",
        "lastName": "Kelly",
        "address": ""
    },
    {
        "email": "ting8813@yahoo.com.hk",
        "phone": null,
        "firstName": "so",
        "lastName": "ting",
        "address": ""
    },
    {
        "email": "yan.yiu@doggiebobo.com",
        "phone": null,
        "firstName": "",
        "lastName": "",
        "address": ""
    },
    {
        "email": "kelvin.lee@doggiebobo.com",
        "phone": null,
        "firstName": "Kelvin",
        "lastName": "Lee",
        "address": ""
    },
    {
        "email": "dbb.katherine@gmail.com",
        "phone": null,
        "firstName": "",
        "lastName": "",
        "address": ""
    }
  ]
  
  useEffect(() => {
    document.title = "DBB Order - Dashboard"
    // database.ref("customers").once("value", (snapshot) => {
    //   setCustomerData(Object.values(snapshot.val()).map(v => v.PersonalData))
    // })
  });
  
  const [profileNamem, setProfileName] = useState("")

  // const responseGoogle = (response) => {
  //   console.log(response)
  // }

  const handleClose = () => setOpen(false)

  const transferCustomer = () => {
    let customerID = {}
    let personalData = {}
    let orderData = {}
    database.ref("customers").on("value", (snapshot) => {
      for(let customer in snapshot.val()){
        customerID = {"id": parseInt(customer)}
        personalData = snapshot.val()[customer].PersonalData
        orderData = snapshot.val()[customer].Records && {"OrderRecord": snapshot.val()[customer].Records}
        database.ref("newCustomers").push({...customerID, ...personalData, ...orderData})
      }
    })
  }

  const transferOrder = () => {
    let i = 0
    let orderID = {}
    let orderData = {}
    let orderPaid = {}
    let orderDelivery = {}
    let orderDone = {}
    let orderNotes = {}
    let orderTrackingRef = {}
    let orderPrepared = {}
    let orderDeliveryParty = {}
    let orderDeliveryCost = {}
    let orderAccount = {}
    let orderTimestamp = {}
    let orderNote = {}
    let orderCustomer = {}
    let orderCustomerId = {}
    let orderDeliveryDate = {}
    setDone(false)
    let shops = [0,1,2,3,4]
    let shop = 4

    database.ref(`d${shop}_orders`).once("value", (snapshot) => {
      let orderLength = Object.keys(snapshot.val()).length
      for(let order in snapshot.val()){
        i++
        orderID = {"id": order}
        orderData = snapshot.val()[order]
        orderPaid = {"Paid": (snapshot.val()[order]["Paid"] === "Yes" ? "paid" : "notPaid")}
        orderDelivery = {"Delivery": (snapshot.val()[order]["Delivery"] === "Yes" ? true : false)}
        orderDone = {"Done": (snapshot.val()[order]["Done"] === "Yes" ? true : false)}
        orderNotes = {"Notes": snapshot.val()[order]["Note"]}
        orderTrackingRef = {TrackingRef: ""}
        orderPrepared = {"Prepared": false}
        orderDeliveryParty = {"DeliveryParty": "myself"}
        orderDeliveryCost = {"DeliveryCost": 0}
        orderAccount = {"Account": `d${shop}@doggiebobo.com`}
        // orderTimestamp = {"Timestamp": (new Date()).getTime()}
        orderNote = {Note: null}
        orderCustomer = {Customer: null}
        orderCustomerId = {CustomerId: snapshot.val()[order]["Customer"] ? snapshot.val()[order]["Customer"] : 888}
        orderDeliveryDate = {"DeliveryDate": 788889540000}
        database.ref(`d${shop}_orders_new`).push({...orderID, ...orderData, ...orderPaid, ...orderDelivery, ...orderDone, ...orderNotes, ...orderTrackingRef, ...orderPrepared, ...orderDeliveryParty, ...orderDeliveryCost, ...orderAccount, ...orderNote, ...orderCustomer, ...orderCustomerId})
        // database.ref(`d${shop}_orders_new`).push({...orderID, ...orderData})
        // if(i % 30 === 0) setProgress((i/orderLength).toFixed(0))
        if(i === orderLength){
          setDone(true)
          setDoneMsg("Done processing " + orderLength + " items")
        }
      }
    })
  }

  const processCustomer = async () => {
      console.log(webCustomers[188])
      // let customers = await (database.ref("newCustomers").orderByChild("FirstPhone").equalTo("93280213").once("value")).val()
      // console.log(customers)
      // database.ref("newCustomers").orderByChild("FirstPhone").equalTo("60306583").once("value", (snapshot)=>{
      //   console.log(snapshot.val())
      // })

      webCustomers.forEach(v => {
        !!v.phone && database.ref("newCustomers").orderByChild("ThirdPhone").equalTo(v.phone.toString()).once("value", (snapshot)=>{
          // console.log(Object.values(snapshot.val())[0], v.email)
          // let newObj = {...Object.values(snapshot.val())[0], Email: v.email}
          // console.log(Object.keys(snapshot.val())[0])
          // console.log(newObj)
          !!snapshot.val() && database.ref(`newCustomers/${Object.keys(snapshot.val())[0]}`).set({
            ...Object.values(snapshot.val())[0], Email: v.email
          })
          .then(()=>{
            database.ref(`newCustomers/${Object.keys(snapshot.val())[0]}`).once("value", (snapshot) => console.log(v.email))
          })
        })
      })
      
  }

  return (
    <Zoom in={true} timeout={width === "xs" || width === "sm" ? 300 : 1}>
      <Grid container>
        <Grid item xs={12}>
          <div style={{
            width: width === "xs" || width === "sm" ? "100%" : "calc(100% - 250px)",
            paddingLeft: width === "xs" || width === "sm" ? 0 : 244
          }}>
            <div style={{padding: 8, borderBottom: "solid 1px gainsboro"}}>
              <h1><GradeIcon style={{color: Settings.mainBackgroundColor}}/>Dashboard</h1>
              <p style={{margin: 0, fontStyle: "italic"}}>“You have a life; make a success of it.” - Ifeanyi Enoch Onuoha</p>
            </div>
            <h3>Take Actions</h3>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  onClick={transferCustomer}
                  variant="outlined"
                >
                  Transfer Customer
                </Button>
              </Grid>
              <Grid item xs={12} style={{marginTop: 16}}>
                <Button
                  onClick={transferOrder}
                  variant="outlined"
                >
                  Transfer Order
                </Button>
                <Backdrop className={classes.backdrop} open={!done} onClick={() => setDone(false)}>
                  {/* <CircularProgress color="inherit" variant="static" value={progress}/> */}
                  <CircularProgress color="inherit"/>
                </Backdrop>
              </Grid>
              <Grid item xs={12} style={{marginTop: 16}}>
                <Button
                  onClick={processCustomer}
                  variant="outlined"
                >
                  Process Customer
                </Button>
              </Grid>
              <Grid item xs={12} style={{marginTop: 16}}>
                {doneMsg}
              </Grid>
            </Grid>
            
          </div>
        </Grid>

        
      </Grid>
    </Zoom>
  );
}

export default withWidth()(Dashboard)