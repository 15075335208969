import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useRouteMatch, Route, useLocation, useParams } from 'react-router-dom';
import {DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, Button, Grid, TextField, Paper, Input, InputAdornment, ListItemIcon, ListItemText, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Backdrop, CircularProgress} from '@material-ui/core/';
import Alert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { database, auth } from '../firebase';
import Draggable from 'react-draggable';
import Settings from './Settings'
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Favorite from '@material-ui/icons/Favorite';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import DateFnsUtils from '@date-io/date-fns';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Axios from '../axios'

import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import QRCode from "react-qr-code";


import ActivityLogTable from './ActivityLogTable'
import OrderRecordTable from './OrderRecordTable'
import BookingRecordTable from './BookingRecordTable'

const useStyles = makeStyles((theme) => ({
  root:{
    width: "100%",
    display: "contents"
  },
  formControl:{
    width: "100%"
  },
  backdrop:{
    zIndex: 9999
  }
}));

const Confirmation = (props) => {
  const {viewDate, editKey, data, open, mode, shop, handleClose} = props
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const classes = useStyles();
  const messages = {
    logout: {
      title: "Confirm Logout",
      message: "Are you sure to logout?",
      button: "Logout",
      modalSize: "sm"
    },
    addCustomer: {
      title: "Add A Customer",
      message: "Please enter all useful information to add a customer",
      button: "Add",
      modalSize: "md"
    },
    editCustomer: {
      title: "Edit A Customer",
      message: "Please enter all useful information to edit a customer",
      button: "Save Edit",
      modalSize: "md"
    },
    addOrder: {
      title: "Add An Order",
      message: "Please enter all useful information to add an order",
      button: "Add",
      modalSize: "md"
    },
    editOrder: {
      title: "Edit An Order",
      message: "Please enter all useful information to edit an order",
      button: "Save Edit",
      modalSize: "md"
    },
    addBooking: {
      title: "Add A Booking",
      message: "Please enter all useful information to add a booking",
      button: "Add",
      modalSize: "md"
    },
    editBooking: {
      title: "Edit A Booking",
      message: "Please enter all useful information to edit a booking",
      button: "Save Edit",
      modalSize: "md"
    },
    deleteBooking: {
      title: "Delete A Booking",
      message: "Are you sure to delete this booking?",
      button: "DELETE",
      modalSize: "md"
    },
  }

  const [currentID, setCurrentID] = useState(0)
  const [loadCustomer, setLoadCustomer] = useState({})
  const [dense, setDense] = React.useState(false);
  const match = useParams('/orders/:id')

  const NameRef = useRef(null)
  const FirstPhoneRef = useRef(null)
  const SecondPhoneRef = useRef(null)
  const ThirdPhoneRef = useRef(null)
  const EmailRef = useRef(null)
  const NotesRef = useRef(null)
  const AddressRef = useRef(null)
  const PetNameRef = useRef(null)
  const PetBreedRef = useRef(null)
  const HandlerRef = useRef(null)

  const OrderNameRef = useRef(null)
  const OrderPhoneRef = useRef(null)
  const OrderAddressRef = useRef(null)
  const OrderItemsRef = useRef(null)
  const OrderNotesRef = useRef(null)
  const OrderDeliveryRef = useRef(null)
  const OrderAmountRef = useRef(null)
  const OrderHandlerRef = useRef(null)
  const OrderPaidRef = useRef(null)
  const OrderDoneRef = useRef(null)
  const OrderTrackingRef = useRef(null)
  const OrderDeliveryCostRef = useRef(null)
  // const PetBreedRef = PetNameRef = AddressRef = NotesRef = useRef(null)

  const initialCustomerData = {
    Name: "",
    Email: "",
    FirstPhone: "",
    SecondPhone: "",
    ThirdPhone: "",
    Address: "",
    PetName: "",
    PetBreed: "",
    Notes: "",
    Handler: "",
    Account: auth?.currentUser.email,
    AddedTime: ""
  }

  const newCustomerData = {
    Name: "",
    FirstPhone: "",
    SecondPhone: "",
    ThirdPhone: "",
    Address: "",
    PetName: "",
    PetBreed: "",
    Notes: "",
    Handler: "",
    Account: auth?.currentUser.email,
    AddedTime: ""
  }

  const initialOrderData = {
    Address: "",
    Amount: 0,
    Account: auth?.currentUser.email,
    CustomerId: "",
    Delivery: false,
    Done: false,
    DeliveryDate: (new Date()).getTime(),
    DeliveryParty: "myself",
    DeliveryCost: 0,
    Handler: "",
    id: "",
    Items: "",
    Name: "",
    Notes: "",
    Phone: "",
    Prepared: false,
    Paid: "notPaid",
    TrackingRef: "",
    Timestamp: 0,
  }

  const initialBookingData = {
    Account: "",
    Phone: "",
    Customer: "",
    Pet: "",
    Breed: "",
    Items: "",
    Notes: "",
    Date: (new Date()).getTime(),
    BookHour: "11",
    BookMin: "00",
    Handler: "",
    CustomerId: "",
    Valid: true,
    Price: ""
  }

  const [addCustomerData, setAddCustomerData] = useState(initialCustomerData)
  const [addOrderData, setAddOrderData] = useState(initialOrderData)
  const [addBookingData, setAddBookingData] = useState(initialBookingData)
  const [originalBookingDate, setOriginalBookingDate] = useState("")
  const [customerPoints, setCustomerPoints] = useState(0)
  const [customerAccumulatedPoints, setCustomerAccumulatedPoints] = useState(0)
  const [addPoints, setAddPoints] = useState("")
  const [rewardList, setRewardList] = useState([])

  const [hasTherese, setHasTherese] = useState(false)
  const [thereseList, setThereseList] = useState([])
  
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [warningMsg, setWarningMsg] = useState("")
  const [oldItems, setOldItems] = useState([])
  const [foundOrder, setFoundOrder] = useState({})

  useEffect(()=> {
    // console.log(addBookingData)
    // console.log(format(new Date(), 'dd/MM/yyyy'))
    // console.log(typeof(new Date()))
    // console.log('lol', addCustomerData)
    if(addOrderData.Items.toLowerCase().includes("therese")) {
      setHasTherese(true)
      setThereseList(addOrderData.Items.split("\n").filter(v => v.toLowerCase().includes("therese")))
      console.log(addOrderData.Items.split("\n").filter(v => v.toLowerCase().includes("therese")))
    }
    else {
      setHasTherese(false)
    }
  }, [addCustomerData, addOrderData, addBookingData, warningMsg, hasTherese])

  useEffect(()=>{
    warningMsg && console.log(warningMsg)
  }, [warningMsg])

  // useEffect(()=>{
  //   // console.log("Confirmation: " + viewDate)
  // }, [viewDate])

  useEffect(()=>{
    // setAddOrderData({
    //   ...addOrderData,
    //   Name: props.data.Name
    // })
    // console.log(props.mode)
    if(props.mode === "editOrder"){
      setAddOrderData({
        ...props.data, 
        DeliveryDate: new Date(props.data.DeliveryDate),
        Handler: ""
      })
    }
    else if(props.mode === "addBooking"){
      // console.log("adding booking")
      setAddBookingData(initialBookingData)
    }
    else if(props.mode === "editBooking"){
      // console.log("editing booking")
      // console.log(Object.keys(props.data)[0])
      // console.log(props.data.originalDate)
      let foundBooking = Object.values(props.data)[0]
      // console.log(viewDate)
      setWarningMsg("")
      setOriginalBookingDate(props.data.originalDate)
      setAddBookingData({
        ...foundBooking,
        BookHour: foundBooking.Time.split(":")[0],
        BookMin: foundBooking.Time.split(":")[1],
        Date: viewDate,
        Handler: ""
      })
    }
    else if(props.mode === "editCustomer"){
      if(props.open){
        setAddPoints("")
        // setBackdropOpen(true)
        Promise.all([
          Axios.get(`getUserPoint/${data.Email}/default`), 
          Axios.get(`getUserPoint/${data.Email}/member`), 
          Axios.get(`getRewards/default`)
        ])
        .then((values) =>{
          // setBackdropOpen(false)
          console.log(values)
          setCustomerPoints(values[0].data[0].value)
          setCustomerAccumulatedPoints(values[1].data[0].value)
          setRewardList(values[2].data)
        })
      }
    }
  }, [props.open, props.data, viewDate])

  const showLog = () => {
    console.log(loadCustomer)
  }

  const returnOrderNumber = (currentNum, shopId) => {
    let year = new Date().getYear() - 100
    let month = new Date().getMonth() + 1
    return (
      `${year}${(month.toString()).padStart(2, "0")}-${shopId}-${currentNum + 1}`
    )
  }

  const handleDateChange = (date) => {
    console.log(date)
    setAddOrderData({
      ...addOrderData,
      DeliveryDate: date
    })
  };

  const handleBookingDateChange = (date) => {
    setAddBookingData({
      ...addBookingData,
      Date: date
    })
  };

  const handleConfirm = async () => {
    setBackdropOpen(true)
    console.log("clicked save")
    switch(mode){
      case "logout":
        auth.signOut()
        enqueueSnackbar("Goodbye and see you again", { 
          variant: 'success',
        })
        setBackdropOpen(false)
        break

      case "addCustomer":
        console.log(addCustomerData)
        database.ref("currentCustomerID").once("value").then((snapshot)=>{
          let newID = snapshot.val() + 1
          addCustomerData["id"] = newID
          addCustomerData["AddedTime"] = (new Date()).toString()
          addCustomerData["logs"] = {
            [(new Date()).getTime()] : {
              Event: "Creation",
              Handler: addCustomerData.Handler,
              Time: (new Date()).toString()
            }
          }
          database.ref("newCustomers").push(addCustomerData)
          database.ref("currentCustomerID").set(newID, ()=> enqueueSnackbar(`Added ${addCustomerData.Name}: ${newID}`, { 
            variant: 'success',
          }))
          console.log(addCustomerData)
          handleClose()
          setBackdropOpen(false)
        })
        break

      case "editCustomer":
        if(HandlerRef.current.value !== ""){
          database.ref(`newCustomers/${editKey}`).update({
            Name: NameRef.current.value,
            FirstPhone: FirstPhoneRef.current.value,
            SecondPhone: SecondPhoneRef.current.value,
            ThirdPhone: ThirdPhoneRef.current.value,
            Email: EmailRef.current.value,
            Address: AddressRef.current.value,
            PetName: PetNameRef.current.value,
            PetBreed: PetBreedRef.current.value,
            Notes: NotesRef.current.value
          }, function(error){
            if(error){
              enqueueSnackbar("Cannot update customer, please try again", { 
                variant: 'error',
              })
              setBackdropOpen(false)
              handleClose()
            }
            else{
              database.ref(`newCustomers/${editKey}/logs/${(new Date()).getTime()}`).set({
                Event: "Edited personal information",
                Handler: HandlerRef.current.value,
                Time: (new Date()).toString()
              }, () => enqueueSnackbar("Successfully updated customer", { 
                variant: 'success',
              }))
              setBackdropOpen(false)
              handleClose()
            }
          })
        }
        else{
          enqueueSnackbar("Please enter your name in 'Handler' for saving data", { 
            variant: 'error',   
          })
          setBackdropOpen(false)
        }
        break

      case "addOrder":
        console.log("adding an order")

        if(hasTherese) {
          console.log("Send email to Therese")
          let list = thereseList.map(v => v.split("/"))
          Axios.post('/notify-therese', {
            data: list.map(v => (
              {
                code: v[1],
                name: v[2],
                phone: v[3],
                notes: v[4]
              }
            ))
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        }

        let getCurrentOrderNum = new Promise((resolve, reject) => {
          database.ref(`current_order/exact_d${match.id}`).once("value", (snapshot)=>{
            resolve({
              orderNum: returnOrderNumber(snapshot.val(), match.id),
              exact: snapshot.val()
            })
          })
        })

        let getCurrentCustomerId = new Promise((resolve, reject) => {
          database.ref("currentCustomerID").once("value", (snapshot) => {
            resolve(snapshot.val() + 1)
          })
        })


        let findingCustomerId = new Promise((resolve, reject) => {
          database.ref("newCustomers").orderByChild("id").equalTo(addOrderData.CustomerId).once("value", (snapshot)=>{
            resolve(snapshot.val())
          })
        })

        if(addOrderData.Handler !== "" && addOrderData.Name !== "" && addOrderData.Phone && addOrderData.Items !== ""){
          Promise.all([getCurrentOrderNum, getCurrentCustomerId, findingCustomerId]).then((values) => {
            //[{...}, 5913, null] or [{...}, 5913, {-MNxsyt9HSAAYhEJU0G6: {…}}] 
            if(!!values[2]) console.log(Object.keys(values[2])[0])
            console.log(values)
            //add order record for returned customer
            if(!!values[2]){
              console.log("returned customer")
              database.ref(`d${match.id}_orders_new`).push({
                ...addOrderData, 
                Timestamp: new Date().getTime(), 
                id: values[0].orderNum,
                DeliveryDate: (new Date(addOrderData.DeliveryDate)).getTime()
              }).then(()=>{
                database.ref(`current_order/exact_d${match.id}`).set(values[0].exact + 1)
              })
              database.ref(`newCustomers/${Object.keys(values[2])[0]}/OrderRecord/${values[0].orderNum}`).set({
                ...addOrderData, 
                Timestamp: new Date().getTime()
              }).then(()=>{
                database.ref(`newCustomers/${Object.keys(values[2])[0]}/logs/${(new Date()).getTime()}`).set({
                  Event: `Added an order #${values[0].orderNum}`,
                  Handler: addOrderData.Handler,
                  Time: (new Date()).toString()
                })
                enqueueSnackbar("Added order and updated customer record", { 
                  variant: 'success',
                })
                setOldItems([])
                setFoundOrder({})
                setWarningMsg("")
                setAddOrderData(initialOrderData)
              })
              setBackdropOpen(false)
              handleClose()
            }
            //add new order for new customer
            else{
              console.log("new customer")
              database.ref(`d${match.id}_orders_new`).push({
                ...addOrderData, 
                Timestamp: new Date().getTime(), 
                id: values[0].orderNum,
                CustomerId: values[1],
                DeliveryDate: (new Date(addOrderData.DeliveryDate)).getTime()
              })
              database.ref("newCustomers").push({
                ...newCustomerData,
                id: values[1],
                Name: addOrderData.Name,
                FirstPhone: addOrderData.Phone,
                Address: addOrderData.Address,
                AddedTime: (new Date()).getTime(),
                logs: {
                  [(new Date()).getTime()] : {
                    Event: `Creation On Order #${values[0].orderNum}`,
                    Handler: addOrderData["Handler"],
                    Time: (new Date()).toString()
                  }
                },
                OrderRecord:{
                  [values[0].orderNum]: {
                    ...addOrderData,
                    Timestamp: new Date().getTime(), 
                    id: values[0].orderNum,
                    CustomerId: values[1],
                    DeliveryDate: (new Date(addOrderData.DeliveryDate)).getTime()
                  }
                }
              }).then(()=>{
                database.ref(`current_order/exact_d${match.id}`).set(values[0].exact + 1)
                database.ref(`currentCustomerID`).set(values[1] + 1)
                enqueueSnackbar("Added order and added a new customer", { 
                  variant: 'success',
                })
                setOldItems([])
                setFoundOrder({})
                setWarningMsg("")
                setAddOrderData(initialOrderData)
              })
              setBackdropOpen(false)
              handleClose()
            }
          });
        }
        else{
          enqueueSnackbar("Please enter 'Name', 'Phone', 'Items' and 'Handler' for saving data", { 
            variant: 'error',     
          })
          setBackdropOpen(false)
        }
        break

      case "editOrder":
        console.log(addOrderData)
        if(addOrderData.Handler !== "" && addOrderData.Name !== "" && addOrderData.Phone !== "" && addOrderData.Items &&editKey){
          database.ref(`d${match.id}_orders_new/${editKey}`).update(addOrderData)
          database.ref("newCustomers").orderByChild("id").equalTo(parseInt(addOrderData.CustomerId)).once("value", (snapshot)=>{
            let customerObj = snapshot.val()
            database.ref(`newCustomers/${Object.keys(customerObj)[0]}/logs/${(new Date()).getTime()}`).set({
              Event: `Edited order #${addOrderData.id}`,
              Handler: addOrderData["Handler"],
              Time: (new Date()).toString()
            })
            database.ref(`newCustomers/${Object.keys(customerObj)[0]}/OrderRecord/${addOrderData.id}`).set(addOrderData)
          })
          .then(()=>{
            enqueueSnackbar(`Edited order #${addOrderData.id}`, { 
              variant: 'success',
            })
          })
          setBackdropOpen(false)
          handleClose()
        }
        else{
          enqueueSnackbar(`Please enter 'Name', 'Phone', 'Items' and 'Handler' for saving data`, { 
            variant: 'error',
          })
          setBackdropOpen(false)
        }
        console.log(addOrderData)
        break

      case "addBooking":
        console.log(addBookingData)
        try {
          if (!!addBookingData.Phone && !!addBookingData.Customer && !!addBookingData.Handler && !!addBookingData.Items) {
            const findingBookingCustomerId = (await database.ref("newCustomers").orderByChild("id").equalTo(addBookingData.CustomerId).once("value")).val()
    
            let currentTime = (new Date()).getTime()
            if (!!findingBookingCustomerId) {
              console.log(findingBookingCustomerId)
              let foundCustomerKey = Object.keys(findingBookingCustomerId)[0]
              console.log(Object.keys(findingBookingCustomerId)[0])
              console.log("Existing customer")
              await database.ref(`bookings/d${shop}/${new Date(addBookingData.Date).toISOString().substring(0,10)}`).push().set({
                Account: auth.currentUser.email,
                Phone: addBookingData.Phone,
                Customer: addBookingData.Customer,
                CustomerId: addBookingData.CustomerId,
                Pet: addBookingData.Pet,
                Breed: addBookingData.Breed,
                Items: addBookingData.Items,
                Notes: addBookingData.Notes,
                Date: new Date(addBookingData.Date).toISOString().substring(0,10),
                BookNumber: currentTime,
                Time: `${addBookingData.BookHour}:${addBookingData.BookMin}`,
                Price: addBookingData.Price,
                Handler: addBookingData.Handler,
                Valid: true
              })
              await database.ref(`newCustomers/${foundCustomerKey}/BookingRecord/${currentTime}`).set({
                Date: new Date(addBookingData.Date).toISOString().substring(0,10),
                PetName: addBookingData.Pet,
                PetBreed: addBookingData.Breed,
                Items: addBookingData.Items,
                Time: `${addBookingData.BookHour}:${addBookingData.BookMin}`,
                Notes: addBookingData.Notes,
                Price: addBookingData.Price,
                Handler: addBookingData.Handler,
              })
              await database.ref(`newCustomers/${foundCustomerKey}/logs/${currentTime}`).set({
                Event: `Added a booking`,
                Handler: addBookingData["Handler"],
                Time: (new Date()).toString()
              })
              enqueueSnackbar(`Added booking for ${addBookingData.Customer}`, { 
                variant: 'success',
              })
              setBackdropOpen(false)
              handleClose()
            } else {
              console.log("New customer")
              await database.ref(`bookings/d${shop}/${new Date(addBookingData.Date).toISOString().substring(0,10)}`).push().set({
                Account: auth.currentUser.email,
                Phone: addBookingData.Phone,
                Customer: addBookingData.Customer,
                CustomerId: addBookingData.CustomerId,
                Pet: addBookingData.Pet,
                Breed: addBookingData.Breed,
                Items: addBookingData.Items,
                Notes: addBookingData.Notes,
                Date: new Date(addBookingData.Date).toISOString().substring(0,10),
                BookNumber: currentTime,
                Time: `${addBookingData.BookHour}:${addBookingData.BookMin}`,
                Price: addBookingData.Price,
                Handler: addBookingData.Handler,
                Valid: true
              })
              const currentCustomerID = await database.ref("currentCustomerID").once("value")
              const newID = parseInt(currentCustomerID.val()) + 1
              await database.ref("newCustomers").push().set({
                Name: addBookingData.Customer,
                FirstPhone: addBookingData.Phone,
                SecondPhone: "",
                ThirdPhone: "",
                Address: "",
                PetName: addBookingData.Pet,
                PetBreed: addBookingData.Breed,
                Notes: "",
                Handler: addBookingData.Handler,
                Account: auth?.currentUser.email,
                AddedTime: (new Date()).toString(),
                id: newID,
                logs: {
                  [currentTime] : {
                    Date: new Date(addBookingData.Date).toISOString().substring(0,10),
                    Event: "Creation On Booking",
                    Handler: addBookingData.Handler,
                    Time: (new Date()).toString()
                  }
                },
                BookingRecord:{
                  [currentTime]:{
                    Date: new Date(addBookingData.Date).toISOString().substring(0,10),
                    PetName: addBookingData.Pet,
                    PetBreed: addBookingData.Breed,
                    Items: addBookingData.Items,
                    Time: `${addBookingData.BookHour}:${addBookingData.BookMin}`,
                    Price: addBookingData.Price,
                    Handler: addBookingData.Handler,
                  }
                }
              })
              await database.ref("currentCustomerID").set(newID)
              enqueueSnackbar(`Added ${addBookingData.Customer}: #${newID} and a new booking for him/her`, { 
                variant: 'success',
              })
              setBackdropOpen(false)
              handleClose()
            }
          }
          else{
            enqueueSnackbar(`Please enter 'Name', 'Phone', 'Items' and 'Handler' for saving data`, { 
              variant: 'error',
            })
            setBackdropOpen(false)
          }
        } catch (err) {
          enqueueSnackbar(err.message ?? err, { 
            variant: 'error',
          })
          setBackdropOpen(false)
        }
        
        break

      case "editBooking":
        if(!!addBookingData.Handler && !!addBookingData.Customer && !!addBookingData.Phone){
          database.ref(`bookings/d${shop}/${new Date(addBookingData.Date).toISOString().substring(0,10)}/${Object.keys(data)[0]}`).set({
            ...addBookingData,
            Date: new Date(addBookingData.Date).toISOString().substring(0,10),
            Time: `${addBookingData.BookHour}:${addBookingData.BookMin}`
          })
          .then(()=>{
            if(new Date(addBookingData.Date).toISOString().substring(0,10) !== originalBookingDate){
              database.ref(`bookings/d${shop}/${originalBookingDate}/${Object.keys(data)[0]}`).remove()
              .then(()=>{
                enqueueSnackbar(`Edited booking for ${addBookingData.Customer}'s date`, { 
                  variant: 'success',
                })
                setBackdropOpen(false)
                handleClose()
              })
            }
            else{
              enqueueSnackbar(`Edited booking for ${addBookingData.Customer}`, { 
                variant: 'success',
              })
              setBackdropOpen(false)
              handleClose()
            }
          })
        }
        else{
          enqueueSnackbar(`Please enter 'Name', 'Phone', 'Items' and 'Handler' for saving data`, { 
            variant: 'error',
          })
          setBackdropOpen(false)
        }
        break

      case "deleteBooking":
        console.log(data)
        console.log(`bookings/d${shop}/${new Date(data[Object.keys(data)[0]].PickedDate).toISOString().substring(0,10)}/${Object.keys(data)[0]}`)
        database.ref(`bookings/d${shop}/${new Date(data[Object.keys(data)[0]].PickedDate).toISOString().substring(0,10)}/${Object.keys(data)[0]}`).update({
          Valid: false
        })
        .then(()=>{
          enqueueSnackbar(`Booking removed successfully`, { 
            variant: 'success',
          })
          setBackdropOpen(false)
          handleClose()
        })
        break
    }
  }

  let handlePointsChange = useCallback((field) => ({ target: { value }}) =>{
    setAddPoints(value)
  })

  let handleChange = useCallback((field) => ({ target: { value }}) =>{
    setAddCustomerData({
      ...addCustomerData,
      [field]: value
    })
    // console.log(addCustomerData)
  }, [addCustomerData])

  let handleOrderChange = useCallback((field) => ({ target: { value }}) =>{
    setAddOrderData({
      ...addOrderData,
      [field]: value
    })
  }, [addOrderData])

  let handleHandlerAuto = useCallback((field, value, mode) =>{
    if(mode === "order"){
      setAddOrderData({
        ...addOrderData,
        [field]: value
      })
    }
    else{
      setAddBookingData({
        ...addBookingData,
        [field]: value
      })
    }
  }, [addOrderData, addBookingData])

  let handleBookingChange = useCallback((field) => ({ target: { value }}) =>{
    setAddBookingData({
      ...addBookingData,
      [field]: value
    })
  }, [addBookingData])

  let handlePrepared = (event) =>{
    // console.log(event.target.checked)
    if(event.target.checked){
      setAddOrderData({
        ...addOrderData,
        "Prepared": event.target.checked
      })
    }
    else{
      setAddOrderData({
        ...addOrderData,
        "Prepared": event.target.checked,
        DeliveryDate: null
      })
    }
  }

  let findCustomer = useCallback(
    ()=>{
      // console.log("left")
      let found = false
      let count = 0
      setBackdropOpen(true)
      database.ref("newCustomers").once("value", (snapshot)=>{
        let customers = snapshot.val()
        for(let customer in customers){
          if(parseInt(customers[customer].FirstPhone) === parseInt(addOrderData.Phone) || 
            parseInt(customers[customer].SecondPhone) === parseInt(addOrderData.Phone) || 
            parseInt(customers[customer].ThirdPhone) === parseInt(addOrderData.Phone)){
            setAddOrderData({
              ...addOrderData,
              Name: customers[customer].Name,
              Address: customers[customer].Address,
              CustomerId: customers[customer].id
            })
            setWarningMsg("Customer found, id: " + customers[customer].id)
            if(!!customers[customer].OrderRecord){
              showRecords(customers[customer].OrderRecord)
              setBackdropOpen(false)
            }
            else{
              setOldItems(<Alert severity="warning">This customer has no order record</Alert>)
              setBackdropOpen(false)
            }
            found = true
            break
          }
          count++
          if(count === Object.keys(customers).length && !found){
            setWarningMsg("This is a new customer")
            setAddOrderData({
              ...addOrderData,
              Address: "",
              Amount: 0,
              Account: auth?.currentUser.email,
              CustomerId: "",
              Delivery: false,
              Done: false,
              DeliveryDate: null,
              DeliveryParty: "myself",
              DeliveryCost: 0,
              Handler: "",
              id: "",
              Items: "",
              Name: "",
              Notes: "",
              Prepared: false,
              Paid: "notPaid",
              TrackingRef: "",
              Timestamp: 0,
            })
            setOldItems(<Alert severity="warning">New customer doesn't have order record</Alert>)
            setFoundOrder({})
            // console.log("This is a new customer")
            setBackdropOpen(false)
          }
        }
      })
    }, [addOrderData, warningMsg]
  )

  let findCustomerForBooking = useCallback(
    ()=>{
      // console.log("findCustomerForBooking")
      let found = false
      let count = 0
      setBackdropOpen(true)
      database.ref("newCustomers").once("value", (snapshot)=>{
        let customers = snapshot.val()
        for(let customer in customers){
          if(parseInt(customers[customer].FirstPhone) === parseInt(addBookingData.Phone) || 
            parseInt(customers[customer].SecondPhone) === parseInt(addBookingData.Phone) || 
            parseInt(customers[customer].ThirdPhone) === parseInt(addBookingData.Phone)){
              setAddBookingData({
              ...addBookingData,
              Customer: customers[customer].Name,
              Pet: customers[customer].PetName,
              Breed: customers[customer].PetBreed,
              CustomerId: customers[customer].id
            })
            setWarningMsg("Customer found, id: " + customers[customer].id)
            setBackdropOpen(false)
            // if(!!customers[customer].BookingRecord){
            //   console.log("")
            //   showRecords(customers[customer].BookingRecord)
            //   setBackdropOpen(false)
            // }
            // else if(!!customers[customer].BookingRecord){
            //   setBackdropOpen(false)
            // }
            // else{
            //   setOldItems(<Alert severity="warning">This customer has no order record</Alert>)
            //   setBackdropOpen(false)
            // }
            found = true
            break
          }
          count++
          if(count === Object.keys(customers).length && !found){
            setWarningMsg("This is a new customer")
            setOldItems(<Alert severity="warning">New customer doesn't have order record</Alert>)
            setBackdropOpen(false)
          }
        }
      })
    }, [addBookingData, warningMsg]
  )

  const showRecords = (items) => {    
    let itemsAry = Object.keys(items).map((v,i) => 
      <Button
        key={i}
        onClick={()=>{
          let shop = v.split("-")
          database.ref(`d${shop[1]}_orders_new`).orderByChild("id").equalTo(v).once("value", (snapshot)=>{
            if(!!snapshot.val()){
              setFoundOrder(Object.values(snapshot.val())[0])
              // console.log(Object.values(snapshot.val())[0])
            }
          })
        }}
      >
        {v}
        <SearchOutlinedIcon/>
      </Button>
    )
    setOldItems(itemsAry)
  }

  const renderCustomerLogsTable = useMemo(() => 
    mode === 'editCustomer' ?
      <ActivityLogTable
        data={
          Object.entries(data.logs ?? {}).map(([key, value]) => ({
            "Timestamp": key,
            ...(value ?? {})
          })).sort((a,b) => parseInt(b.Timestamp) - parseInt(a.Timestamp))
        }
      />
      : null
  , [data, mode])

  const renderOrderRecordTable = useMemo(() =>
    mode === "editCustomer"?
      <OrderRecordTable customerKey={editKey}/>
      : null
  , [editKey, mode])

  const renderBookingRecordTable = useMemo(() =>
    mode === "editCustomer"?
      <BookingRecordTable customerKey={editKey}/>
      : null
  , [editKey, mode])

  // const renderCustomerLogs = (customerMode) => {
  //   // console.log(customerMode)
  //   console.log(data.logs)
  //   if(customerMode === "editCustomer"){ 
  //     return(
  //       <div>
  //         {data.logs ? Object.keys(data.logs).sort((a,b) => data.logs[b].Time.localeCompare(data.logs[a].Time)).map(v => {
  //           return(
  //             <Grid item xs={12} key={data.logs[v].Time}>
  //               <div className={classes.demo}>
  //                 <List dense={dense}>
  //                   <ListItem>
  //                     <ListItemIcon>
  //                       {data.logs[v].Event.includes("Creation") ? <AddCircleOutlineRoundedIcon /> : data.logs[v].Event.includes("Edit") ? <EditRoundedIcon/> : <AddCircleOutlineRoundedIcon />}
  //                     </ListItemIcon>
  //                     <ListItemText
  //                       primary = {`${data.logs[v].Event} - by ${data.logs[v].Handler}`}
  //                       secondary={(new Date(data.logs[v].Time)).toLocaleString()}
  //                     />
  //                   </ListItem>
  //                 </List>
  //               </div>
  //             </Grid>
  //           )
  //         }) : "This customer has no logs"}
  //       </div>
  //     )
  //   }
  // }

  //open a customer form

  const customerFormTables = useMemo(() =>
    <>
      <Grid item xs={12} style={{borderTop: "solid 1px #ededed", marginTop: 32, background: "#e8e8e8"}}>
        <Grid container>
          {renderCustomerLogsTable}
        </Grid>
      </Grid>
      
      <Grid item xs={12} style={{borderTop: "solid 1px #ededed", marginTop: 32, background: "#e8e8e8"}}>
        <Grid container>
          {renderOrderRecordTable}
        </Grid>
      </Grid>

      <Grid item xs={12} style={{borderTop: "solid 1px #ededed", marginTop: 32, background: "#e8e8e8"}}>
        <Grid container>
          {renderBookingRecordTable}
        </Grid>
      </Grid>
    </>,
    [renderCustomerLogsTable, renderOrderRecordTable, renderBookingRecordTable, mode]
  )
  const customerForm = useMemo(() => (
    <Grid container>
      <form className={classes.root} noValidate autoComplete="off">
        {
          data?.Email && 
            <Grid item xs={12} style={{
              display: "contents"
            }}>
              <div
                style={{
                  width: 100
                }}
              >
                { data?.id && <QRCode value={"dbb" + data?.id?.toString()} size={88}/>}
              </div>
              <div
                style={{
                  width: "calc(100% - 100px)"
                }}
              >
                <p>Email: {data?.Email}</p>
                <Input
                  id="standard-adornment-weight"
                  value={addPoints}
                  onChange={handlePointsChange('points')}
                  endAdornment={<InputAdornment position="end">Points</InputAdornment>}
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 8,
                    background: "#18845c",
                    color: "white"
                  }}
                  onClick={()=>{
                    console.log(addPoints)
                    setBackdropOpen(true)
                    Axios.get(`addUserPoint/${data.Email}/member/${addPoints}`)
                    .then((res)=>{
                      Axios.get(`addUserPoint/${data.Email}/default/${addPoints}`)
                      console.log(res)
                      setCustomerPoints(res.data[0].value)
                      setBackdropOpen(false)
                    })
                  }}
                >
                  <AddCircleOutlineOutlinedIcon/> Add
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 8,
                    background: "#b62626",
                    color: "white"
                  }}
                  onClick={()=>{
                    console.log(addPoints)
                    setBackdropOpen(true)
                    Promise.all([
                      Axios.get(`addUserPoint/${data.Email}/default/-${addPoints}`),
                      Axios.get(`addUserPoint/${data.Email}/member/-${addPoints}`)
                    ])
                    .then((values)=>{
                      setCustomerPoints(values.data[0].data[0].value)
                      setCustomerAccumulatedPoints(values.data[1].data[0].value)
                      console.log(values)
                      setBackdropOpen(false)
                    })
                  }}
                >
                  <IndeterminateCheckBoxOutlinedIcon/> Substract
                </Button>
                {/* <Button onClick={()=>
                  Axios.get(`getAllCustomers`).then((res) => {
                    console.log(res)
                  })
                }>
                  Get Customers
                </Button> */}
                <p>Standard Points: {customerPoints}</p>
                <p>Accumulated Points: {customerAccumulatedPoints}</p>
                
              </div>
            </Grid>
        }
        {
          data?.Email &&
          rewardList.map(v =>
            <Grid item xs={6} md={2} key={v.id} style={{padding: 4}}>
              <img 
                src={v.lws_woorewards_pro_unlockables_freeproduct_thumbnail_url ? v.lws_woorewards_pro_unlockables_freeproduct_thumbnail_url : v.lws_woorewards_unlockables_coupon_thumbnail_url}
                style={{
                  width: "100%"
                }}
              />
              <p style={{fontSize: 14}}>{v.description}</p>
            </Grid>  
          )
        }
        <Grid item xs={12} md={6} style={{padding: 4}}>
          <TextField 
            id="name" label={"Name*"} 
            style={{width: "100%"}}
            onChange={handleChange('Name')}
            defaultValue={mode === "editCustomer" ? data.Name : ""}
            inputRef={NameRef}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{padding: 4}}>
          <TextField 
            id="FisrtPhone" type="number" label="First Phone*" 
            style={{width: "100%"}}
            onChange={handleChange('FirstPhone')}
            defaultValue={mode === "editCustomer" ? data.FirstPhone : ""}
            inputRef={FirstPhoneRef}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{padding: 4}}>
          <TextField 
            id="SecondPhone" type="number" label="Second Phone" 
            style={{width: "100%"}}
            onChange={handleChange('SecondPhone')}
            defaultValue={mode === "editCustomer" ? data.SecondPhone : ""}
            inputRef={SecondPhoneRef}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{padding: 4}}>
          <TextField 
            id="ThirdPhone" type="number" label="Third Phone" 
            style={{width: "100%"}}
            onChange={handleChange('ThirdPhone')}
            defaultValue={mode === "editCustomer" ? data.ThirdPhone : ""}
            inputRef={ThirdPhoneRef}
          />
        </Grid>
        <Grid item xs={12} style={{padding: 4}}>
          <TextField 
            id="Email" label="Email" 
            style={{width: "100%"}}
            onChange={handleChange('Email')}
            defaultValue={mode === "editCustomer" ? data.Email : ""}
            inputRef={EmailRef}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{padding: 4}}>
          <TextField 
            id="Address" label="Address" 
            style={{width: "100%"}}
            onChange={handleChange('Address')}
            defaultValue={mode === "editCustomer" ? data.Address : ""}
            inputRef={AddressRef}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{padding: 4}}>
          <TextField 
            id="PetName" label="Pet Name" 
            style={{width: "100%"}}
            onChange={handleChange('PetName')}
            defaultValue={mode === "editCustomer" ? data.PetName : ""}
            inputRef={PetNameRef}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{padding: 4}}>
          <TextField 
            id="PetBreed" label="Pet Breed" 
            style={{width: "100%"}}
            onChange={handleChange('PetBreed')}
            defaultValue={mode === "editCustomer" ? data.PetBreed : ""}
            inputRef={PetBreedRef}
          />
        </Grid>
        <Grid item xs={12} style={{padding: 4}}>
          <TextField 
            id="Notes" 
            label="Notes" 
            multiline
            rows={4}
            rowsMax={6}
            style={{width: "100%"}}
            onChange={handleChange('Notes')}
            inputRef={NotesRef}
            defaultValue={mode === "editCustomer" ? data.Notes : ""}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{padding: 4}}>
          <TextField 
            id="Handler" 
            label="Handler" 
            style={{width: "100%"}}
            onChange={handleChange('Handler')}
            inputRef={HandlerRef}
            // defaultValue={mode === "editCustomer" ? data.Handler : ""}
          />
        </Grid>
      </form>
      {customerFormTables}
    </Grid>
  ), [classes, handleChange, data, mode, customerFormTables, customerPoints, addPoints, rewardList])

  //open an order form
  const orderForm = useMemo(() => (orderMode) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} maxSnack={3}>
      <Grid container>
        {warningMsg && <Alert style={{width: "100%"}} severity="info">{warningMsg}</Alert>}
        {(addOrderData.CustomerId && orderMode === "editOrder") && <Alert style={{width: "100%"}} severity="info">Editing Customer ID: {addOrderData.CustomerId}</Alert>}
        {/* <p>{orderMode}</p> */}
        <form className={classes.root} noValidate autoComplete="off">
          <Grid item xs={12} md={4} style={{padding: 4}}>
            <TextField 
              id="Phone" type="number" label="Phone*"
              style={{width: "100%"}}
              value={addOrderData.Phone}
              onBlur={()=> {
                if(addOrderData.Phone !== "" && addOrderData.Phone.length === 8 && orderMode === "addOrder"){
                  findCustomer()
                }
                else{
                  return
                }
              }}
              onChange={handleOrderChange('Phone')}
              // defaultValue={orderMode === "editOrder" ? data.Phone : ""}
              // inputRef={OrderPhoneRef}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{padding: 4}}>
            <TextField 
              id="name" label={"Name*"}
              style={{width: "100%"}}
              value={addOrderData.Name}
              onChange={handleOrderChange('Name')}  
              // defaultValue={orderMode === "editOrder" ? data.Name : ""}
              // inputRef={OrderNameRef}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{padding: 4}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="deliveryLabel">Delivery</InputLabel>
              <Select
                labelId="deliveryLabel"
                id="delivery"
                value={addOrderData.Delivery}
                onChange={handleOrderChange('Delivery')}
                // defaultValue = {orderMode === "editOrder"? data.Delivery : ""}
                // inputRef={OrderDeliveryRef}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid 
            container
            style={{
              display: addOrderData.Delivery ? "contents" : "none"
            }}
          >
            <Grid item xs={12} md={addOrderData.Prepared ? 6 : 12} style={{padding: 4}}>
              <TextField 
                id="address" label={"Address"} 
                style={{width: "100%"}}
                value={addOrderData.Address}
                onChange={handleOrderChange('Address')}
                // inputRef={OrderAddressRef}
                // defaultValue={orderMode === "editOrder" ? data.Address : ""}
              />
              <FormControlLabel
                control={<Checkbox 
                  checked={addOrderData.Prepared}
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  onChange={handlePrepared} 
                  name="Prepared" />
                }
                // style={{
                //   marginTop: 4
                // }}
                label="可安排送貨日期"
              />
            </Grid>
            
            {addOrderData.Prepared &&
              <Grid item xs={12} md={6} style={{padding: 4}}>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  label="Delivery Date"
                  format="dd/MM/yyyy"
                  style={{
                    // marginTop: 0,
                    width: "100%"
                  }}
                  value={addOrderData.DeliveryDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            }

            <Grid 
              container
              style={{
                display: addOrderData.Prepared ? "contents" : "none"
              }}
            >
              <Grid 
                item xs={12} 
                md={addOrderData.DeliveryParty === "sfexpress" ? 3 : 6} 
                style={{padding: 4}}
              >
                <FormControl className={classes.formControl}>
                  <InputLabel id="deliveryPartyLabel">Delivery Party</InputLabel>
                  <Select
                    labelId="deliveryPartyLabel"
                    id="deliveryParty"
                    value={addOrderData.DeliveryParty}
                    onChange={handleOrderChange('DeliveryParty')}
                  >
                    <MenuItem value={"myself"}>Jason</MenuItem>
                    <MenuItem value={"pang"}>呀鵬</MenuItem>
                    <MenuItem value={"sfexpress"}>順豐</MenuItem>
                    <MenuItem value={"others"}>其他</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item 
                xs={12} 
                md={6}
                style={{
                  padding: 4,
                  display: addOrderData.DeliveryParty === "sfexpress" ? "block" : "none"
                }}
              >
                <TextField 
                  id="tackingRef" label={"Tracking Reference"} 
                  style={{width: "100%"}}
                  onChange={handleOrderChange('TackingRef')}
                  defaultValue={orderMode === "editOrder" ? data.TrackingRef : ""}
                  inputRef={OrderTrackingRef}
                />
              </Grid>

              <Grid item 
                xs={12} 
                md={addOrderData.DeliveryParty === "sfexpress" ? 3 : 6}
                style={{padding: 4}}
              >
                <TextField 
                  id="deliveryCost" label={"Delivery Cost"} 
                  style={{width: "100%"}}
                  type="number"
                  onChange={handleOrderChange('DeliveryCost')}
                  defaultValue={orderMode === "editOrder" ? data.DeliveryCost : ""}
                  inputRef={OrderDeliveryCostRef}
                />
              </Grid>
            </Grid>
          </Grid>

            <Grid container>
              <Grid item xs={12} md={orderMode === "editOrder" ? 12 : 6} style={{padding: 4}}>
                <TextField 
                  id="Items" 
                  label="Items*" 
                  multiline
                  rows={4}
                  rowsMax={6}
                  style={{width: "100%"}}
                  onChange={handleOrderChange('Items')}
                  value={addOrderData.Items}
                  // inputRef={OrderItemsRef}
                  // defaultValue={orderMode === "editOrder" ? data.Items : ""}
                />
                {/* <Button
                  variant="outlined"
                  style={{
                    margin: "4px 0px"
                  }}
                  onClick={()=>{
                    setAddOrderData({
                      ...addOrderData,
                      Items: "Therese/code/name/phone/notes\n" + addOrderData.Items
                    })
                  }}
                >
                  <AddCircleOutlineOutlinedIcon/> 訂購Therese牌
                </Button> */}
                {foundOrder.Items?.split(/\n/g).map(v => v !== "" &&
                  <Button
                    key={v}
                    onClick={()=>{
                      setAddOrderData({
                        ...addOrderData,
                        Items: addOrderData.Items + v + "\n"
                      })
                    }}
                  >
                    {v}<AddCircleOutlineOutlinedIcon style={{fontSize: 16}}/>
                  </Button>
                )}
              </Grid>
              {
              orderMode === "addOrder" &&
                <Grid item xs={12} md={6} style={{padding: 4}}>
                  <p style={{borderBottom: "solid 1px #ededed"}}>Order Record</p>
                  {oldItems}
                </Grid>
              }
            </Grid>
            {
              orderMode === "addOrder" && hasTherese &&
              <Grid container>
                <Grid item xs={12} style={{padding: 4}}>
                  We will send an order to Therese:
                </Grid>
                {
                  <ul>
                    {
                      // thereseList.map(v => v.split("/").map(k => <li>Code: {k[0]}, Name: {k[1]}, Phone: {k[2]}</li>))
                      thereseList.map(v => 
                        <li>
                          Code: <span style={{background: v.split("/")[1] === "code" && "red", color: v.split("/")[1] === "code" && "white"}}>{v.split("/")[1]}</span>, Name: <span style={{background: v.split("/")[2] === "name" && "red", color: v.split("/")[2] === "name" && "white"}}>{v.split("/")[2]}</span>, Phone: <span style={{background: v.split("/")[3] === "phone" && "red", color: v.split("/")[3] === "phone" && "white"}}>{v.split("/")[3]}</span>, Notes: <span style={{background: v.split("/")[4] === "notes" && "red", color: v.split("/")[4] === "notes" && "white"}}>{v.split("/")[4]}</span>
                        </li>  
                      )
                    }
                  </ul>
                }
              </Grid>
            }
            <Grid container>
              <Grid item xs={12} style={{padding: 4}}>
                <TextField 
                  id="Notes" 
                  label="Notes" 
                  multiline
                  rows={4}
                  rowsMax={6}
                  style={{width: "100%"}}
                  value={addOrderData.Notes}
                  onChange={handleOrderChange('Notes')}
                  // inputRef={OrderNotesRef}
                  // defaultValue={orderMode === "editOrder" ? data.Notes : ""}
                />
                <Button
                  onClick={()=>{
                    console.log(addOrderData.Notes+ "待入機(J)\n")
                    setAddOrderData({
                      ...addOrderData,
                      Notes: "待入機(J)\n" + addOrderData.Notes
                    })
                  }}
                >
                  <AddCircleOutlineOutlinedIcon/> 待入機(J)
                </Button>
                {orderMode === "editOrder" && <Button
                  onClick={()=>{
                    setAddOrderData({
                      ...addOrderData,
                      Notes: `${new Date().getDate()}/${(new Date().getMonth() + 1)} called\n` + addOrderData.Notes
                    })
                  }}
                >
                  <AddCircleOutlineOutlinedIcon/> {new Date().getDate()}/{(new Date().getMonth() + 1)} called
                </Button>}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={4} style={{padding: 4}}>
                <TextField 
                  id="Amount" type="number" label="Amount"
                  style={{width: "100%"}}
                  // value={addOrderData.Amount}
                  onChange={handleOrderChange('Amount')}
                  defaultValue={orderMode === "editOrder" ? data.Amount : ""}
                  inputRef={OrderAmountRef}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{padding: 4}}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="paidLabel">Paid?</InputLabel>
                  <Select
                    labelId="paidLabel"
                    id="paid"
                    // value={addOrderData.Paid}
                    onChange={handleOrderChange('Paid')}
                    defaultValue={orderMode === "editOrder" ? data.Paid : ""}
                    inputRef={OrderPaidRef}
                  >
                    <MenuItem value={"notPaid"}>未付</MenuItem>
                    <MenuItem value={"partial"}>已付部份/訂金</MenuItem>
                    <MenuItem value={"paid"}>已付(舖內付款)</MenuItem>
                    {!["3"].includes(match.id) && <MenuItem value={"paidByCards"}>已付(網上信用卡)</MenuItem>}
                    {!["3"].includes(match.id) && <MenuItem value={"paidByPaymeFPS"}>已付(Payme / FPS Jason)</MenuItem>}
                    {!["3"].includes(match.id) && <MenuItem value={"paidByHSBC"}>已付(HSBC Jason)</MenuItem>}
                    {!["3"].includes(match.id) && <MenuItem value={"paidBySCB"}>已付(SCB DBB)</MenuItem>}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{padding: 4}}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="doneLabel">Status</InputLabel>
                  <Select
                    labelId="doneLabel"
                    id="done"
                    // value={addOrderData.Done}
                    onChange={handleOrderChange('Done')}
                    defaultValue={orderMode === "editOrder" ? data.Done : ""}
                    inputRef={OrderDoneRef}
                  >
                    <MenuItem value={false}>未取/送貨</MenuItem>
                    <MenuItem value={true}>已完成</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={4} style={{padding: 4}}>
                {/* <TextField 
                  id="Handler" 
                  label="Handler*"
                  style={{width: "100%"}}
                  value={addOrderData.Handler}
                  onChange={handleOrderChange('Handler')}
                  inputRef={OrderHandlerRef}
                /> */}
                <Autocomplete
                  id="handler"
                  options={Settings.staffList}
                  getOptionLabel={(option) => option.name}
                  style={{
                    width: 300
                  }}
                  // onChange={(_, value) => handleOrderChange('Handler', value.name)}
                  onChange={(_, value) => handleHandlerAuto('Handler', value.name, 'order')}
                  renderInput={(params) => <TextField {...params} label="Handler*"
                    value={addOrderData.Handler}
                    inputRef={OrderHandlerRef}
                  />}
                />
              </Grid>
            </Grid>
        </form>
      </Grid>
    </MuiPickersUtilsProvider>
  ), [classes, handleOrderChange, data, warningMsg, oldItems, foundOrder, addOrderData, hasTherese, thereseList])

  //open an booking form
  const bookingForm = useMemo(() => (orderMode) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} maxSnack={3}>
      <Grid container>
        {warningMsg && <Alert style={{width: "100%"}} severity="info">{warningMsg}</Alert>}
        <form className={classes.root} noValidate autoComplete="off">
          <Grid item xs={12} md={6} style={{padding: 4}}>
            <TextField 
              id="Phone" type="number" label="Phone*"
              style={{width: "100%"}}
              value={addBookingData.Phone}
              onBlur={()=> {
                if(addBookingData.Phone !== "" && addBookingData.Phone.length === 8 && orderMode === "addBooking"){
                  findCustomerForBooking()
                }
                else{
                  return
                }
              }}
              onChange={handleBookingChange('Phone')}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{padding: 4}}>
            <TextField 
              id="Customer" type="text" label="Customer*"
              style={{width: "100%"}}
              value={addBookingData.Customer}
              onChange={handleBookingChange('Customer')}
            />
          </Grid>
          <Grid item xs={6} md={3} style={{padding: 4}}>
            <TextField 
              id="Pet" type="text" label="Pet*"
              style={{width: "100%"}}
              value={addBookingData.Pet}
              onChange={handleBookingChange('Pet')}
            />
          </Grid>
          <Grid item xs={6} md={3} style={{padding: 4}}>
            <TextField 
              id="Breed" type="text" label="Breed*"
              style={{width: "100%"}}
              value={addBookingData.Breed}
              onChange={handleBookingChange('Breed')}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{padding: 4}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="itemsLabel">Items</InputLabel>
              <Select
                labelId="itemsLabel"
                id="items"
                value={addBookingData.Items}
                onChange={handleBookingChange('Items')}
                // defaultValue = {orderMode === "editOrder"? data.Delivery : ""}
                // inputRef={OrderDeliveryRef}
              >
                <MenuItem value={"Bath"}>Bath</MenuItem>
                <MenuItem value={"HC"}>H/C</MenuItem>
                <MenuItem value={"Nail"}>Nail</MenuItem>
                <MenuItem value={"Ear"}>Ear</MenuItem>
                <MenuItem value={"Paw"}>Paw</MenuItem>
                <MenuItem value={"Multiple"}>More Than One</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} style={{padding: 4}}>
            <TextField 
              id="Price" type="text" label="Price"
              style={{width: "100%"}}
              value={addBookingData.Price}
              onChange={handleBookingChange('Price')}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{padding: 4}}>
            <TextField 
              id="Notes" type="text" label="Notes(選填)"
              style={{width: "100%"}}
              value={addBookingData.Notes}
              onChange={handleBookingChange('Notes')}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{padding: 4}}>
            <KeyboardDatePicker
              id="booking-date-picker-dialog"
              label="Book DATE"
              format="dd/MM/yyyy"
              style={{
                // marginTop: 0,
                width: "100%"
              }}
              value={addBookingData.Date}
              onChange={handleBookingDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            {/* <p>Date: {addBookingData.Date}</p> */}
          </Grid>
          <Grid item xs={6} md={3} style={{padding: 4}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="BookHour">Book time(時)</InputLabel>
              <Select
                labelId="BookHour"
                id="BookHourLabel"
                value={addBookingData.BookHour}
                onChange={handleBookingChange('BookHour')}
                // defaultValue = {orderMode === "editOrder"? data.Delivery : ""}
                // inputRef={OrderDeliveryRef}
              >
                {["11","12","13","14","15","16","17","18","19","20"].map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3} style={{padding: 4}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="BookMin">Book time(分)</InputLabel>
              <Select
                labelId="BookMin"
                id="BookMinLabel"
                value={addBookingData.BookMin}
                onChange={handleBookingChange('BookMin')}
                // defaultValue = {orderMode === "editOrder"? data.Delivery : ""}
                // inputRef={OrderDeliveryRef}
              >
                {["00", "15", "30", "45"].map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} style={{padding: 4}}>
            {/* <TextField 
              id="Handler" type="text" label="Handler** "
              style={{width: "100%"}}
              value={addBookingData.Handler}
              onChange={handleBookingChange('Handler')}
            /> */}
            <Autocomplete
              id="handler"
              options={Settings.staffList}
              getOptionLabel={(option) => option.name}
              style={{
                width: 300
              }}
              // onChange={(_, value) => handleOrderChange('Handler', value.name)}
              onChange={(_, value) => handleHandlerAuto('Handler', value.name, 'booking')}
              renderInput={(params) => <TextField {...params} label="Handler*"
                value={addBookingData.Handler}
                // inputRef={OrderHandlerRef}
              />}
            />
          </Grid>
        </form>
      </Grid>
    </MuiPickersUtilsProvider>
  ), [classes, handleBookingChange, data, addBookingData, warningMsg])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={messages[mode]?.modalSize}
        fullWidth={true}
        disableBackdropClick
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="draggable-confirmation" style={{background: "linear-gradient(40deg, rgba(105,68,156,1) 0%, rgba(56,54,129,1) 54%, rgba(81,84,217,1) 100%)", color: "white"}}>{messages[mode]?.title}{mode === "editCustomer" && `${": " + data.id}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messages[mode]?.message}
          </DialogContentText>
          {(mode === "addCustomer" || mode === "editCustomer") &&
            <>
              {customerForm}
            </>}
          {(mode === "addOrder" || mode === "editOrder") && orderForm(mode)}
          {(mode === "addBooking" || mode === "editBooking") && bookingForm(mode)}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setOldItems([])
              setFoundOrder({})
              setWarningMsg("")
              setAddOrderData(initialOrderData)
              handleClose()
              // console.log("closing")
            }} 
            color="primary"
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handleConfirm()} 
            // onClick = {() => console.log("clicked")}
            onDoubleClick={() => console.log("double clicked")}
            color="primary" 
            style={{
              backgroundColor: Settings.mainBackgroundColor, 
              color: Settings.mainColor
            }} 
            autoFocus
          >
            {messages[mode]?.button}
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={backdropOpen} onClick={()=>console.log(null)}>
        <div>

        <CircularProgress style={{color: "white", display: "block", margin: "auto"}}/>
        <p style={{color: "white"}}>
          Loading...
        </p>
        </div>
      </Backdrop>
    </div>
  );
}

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-confirmation" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default Confirmation