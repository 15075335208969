import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect 
} from "react-router-dom";
import "./style.css";
import { Backdrop, LinearProgress, Zoom, Grid } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
// import SpotifyPlayer from 'react-spotify-web-playback';


// pages
import Login from './pages/Login'
import Main from './pages/Main';
import Customers from './pages/Customers';
import Bookings from './pages/Bookings';
import Orders from './pages/Orders';
import Dashboard from './pages/Dashboard';
import D3Need from './pages/D3Need'
import VetOrder from './pages/VetOrder'
import Inventory from './pages/Inventory';
import Attendance from './pages/AttendanceRecord';
import Users from './pages/Users';

// components
import { database, auth } from './firebase';
import SideNav from './components/SideNav';
import TopBar from './components/TopBar';
import Chatbot from './components/Chatbot';

function App() {
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const [userInfo, setUserInfo] = useState([])
  const [role, setRole] = useState("")

  useEffect(() => {
    auth.onAuthStateChanged(function(user) {
      if (user) {
        setLoggedIn(true)
        setUserInfo(user)
        console.log(user.uid)
        database.ref(`users/${user.uid}/Role`).once("value", (snapshot) => {
          setRole(snapshot.val())
        })
      } else {
        setLoggedIn(false)
      }
      setLoading(false)
    })
  }, [])

  return (
    loading ? <LinearProgress/> :
    <SnackbarProvider maxSnack={3}>
      {/* { loggedIn && <Chatbot/> } */}
      {/* <SideNav/> */}
      <Router>
        <Switch>
          {loggedIn ? 
            <>
              <TopBar user={userInfo} userRole={role}/>
              <SideNav user={userInfo} userRole={role}/>
              {/* <SpotifyPlayer
                token="BQDF_OTi71sOuKklsqZbQrCL41o2mfXwy4lQGIOWvc_oo9UJjwnzcdz6VUtH3OTjxjdcM1fbRH1JI0QbXGUoZj1GXxM1n0Z7Yb2STD8eRADd2-HncewhUnf6jzHD4vtWYirRH9_GHM-j0OEi7w7dGoERzQuE7Zwpjee-vbFPNUi1E06ClXs-pr8D33-YO3Q2lTgq6oO3VFPb7gtQJg"
                uris={"http://open.spotify.com/track/6rqhFgbbKwnb9MLmUQDhG6"}
                styles={{
                  activeColor: '#fff',
                  bgColor: '#333',
                  color: '#fff',
                  loaderColor: '#fff',
                  sliderColor: '#2d91e3',
                  trackArtistColor: '#ccc',
                  trackNameColor: '#fff',
                }}
              /> */}
              {/* <Route exact path="/"><Main user={userInfo}/></Route> */}
              {["shop", "admin", "staff"].includes(role) && 
                <Route exact path="/">
                  {role === "staff" ? <Attendance user={userInfo} userRole={role}/> : <Customers user={userInfo}/>}
                </Route>
              }
              {["shop", "admin"].includes(role) && <Route exact path="/bookings"><Bookings user={userInfo}/></Route>}
              {["shop", "admin"].includes(role) && <Route exact path="/orders/d:id"><Orders user={userInfo}/></Route>}
              {["shop", "admin"].includes(role) && <Route exact path="/admin/dashboard"><Dashboard user={userInfo}/></Route>}
              {["shop", "admin"].includes(role) && <Route exact path="/d3-need"><D3Need user={userInfo}/></Route>}
              {["shop", "admin"].includes(role) && <Route exact path="/vet-order"><VetOrder user={userInfo}/></Route>}
              {["shop", "admin"].includes(role) && <Route exact path="/inventory"><Inventory user={userInfo}/></Route>}
              {["staff", "admin"].includes(role) && <Route exact path="/attendance"><Attendance user={userInfo} userRole={role}/></Route>}
              {["admin"].includes(role) && <Route exact path="/users"><Users user={userInfo} userRole={role}/></Route>}
            </>
            :
            <Route path="/"><Login /></Route>
          }
        </Switch>
        {/* {loggedIn ? <Switch>
          <Route path="/">
            <Main user={userInfo}/>
          </Route>
        </Switch>
        :
        <Switch>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
        } */}
        {/* <Route path="/">
          <Main />
        </Route> */}
      </Router>
    </SnackbarProvider>
  );
}

export default App