import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect 
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {TextField, Grid, Button, Hidden, LinearProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from "firebase/app";
import "firebase/auth";
import { database, auth } from '../firebase';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
// }));



export default function Login(){
  // const classes = useStyles();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loginMsg, setLoginMsg] = useState("")
  const [loading, setLoading] = useState(false)

  const [user, error] = useAuthState(firebase.auth());

  const login = () => {
    setLoginMsg("")
    setLoading(true)
    firebase.auth().signInWithEmailAndPassword(email, password)
    .catch((error) => {
      setLoginMsg(error.message)
      setLoading(false)
    })
  };
  const logout = () => {
    firebase.auth().signOut();
  };
  
  // const logStatus = () => console.log(email)

  return(
    <Grid container 
      style={{
        backgroundImage: "url('/img/bg.jpg')", 
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 8
      }}
    >
      <Hidden xsDown>
        <Grid item sm={1} md={4}></Grid>
      </Hidden>

      <Grid item xs={12} sm={10} md={4} >
        <form 
          // className={classes.root} 
          noValidate autoComplete="off"
          style={{
            textAlign:"center",
            boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            borderRadius: 5,
            // padding: "48px 16px",
            marginTop: 32,
            backgroundColor: "#fffffff2",
          }}
        >
          {loading && <LinearProgress color="primary" />}

          {loginMsg && 
          <Grid container>
            <Grid item xs={12}style={{padding: 8}}>
              <Alert variant="filled" severity="error">{loginMsg}</Alert>
            </Grid>
          </Grid> }

          <img src="/img/logo.png" style={{width: "100%", marginTop: 32, maxWidth: 348}}/>

          <Grid container>
            <Grid item xs={12}style={{padding: 16}}>
              <TextField 
                id="email"
                label="Email" 
                type="text"
                style={{width:"100%", margin: "16px 0px"}}
                onChange={(e)=>{
                  setEmail(e.target.value)
                  // logStatus()
                }}
                onKeyPress={(e) => {
                  if(e.key === "Enter") login()
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}style={{padding: 16}}>
              <TextField 
                id="password"
                label="Password" 
                type="password"
                style={{width:"100%", margin: "0px 0px 16px 0px"}}
                onChange={(e)=>setPassword(e.target.value)}
                onKeyPress={(e) => {
                  if(e.key === "Enter") login()
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}style={{padding: 16}}>
              <Button 
                variant="contained"
                style={{
                  marginBottom: 16,
                  borderRadius: 50,
                  background: loading ? "#cacaca" : "linear-gradient(40deg, rgba(105,68,156,1) 0%, rgba(56,54,129,1) 54%, rgba(81,84,217,1) 100%)",
                  color: "white",
                  width: "100%",
                  maxWidth: 348
                }}
                // onClick={() => console.log(email + " : " + password)}
                onClick={() => login()}
                disabled= {loading}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
        
      </Grid>

      <Hidden xsDown>
        <Grid item sm={1} md={4}></Grid>
      </Hidden>
    </Grid>
  )
}