module.exports = {
  mainBackgroundColor: "#5e5ecc",
  mainColor: "white",
  staffList: [
    { name: 'BL'},
    { name: 'Clement'},
    { name: 'Frankie'},
    { name: 'Jason'},
    { name: 'Kel'},
    { name: 'Ace of Wall'},
    { name: 'Yan'},
    { name: '諾欣'},
    { name: '芝'},
  ]
}