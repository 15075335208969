import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useLocation,
  Switch,
  Route,
  Link
} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';

import { database, auth } from '../firebase';

import ActivityLogTable from './ActivityLogTable'
import OrderRecordTable from './OrderRecordTable'

const theme = {
  background: '#f5f8fb',
  // fontFamily: 'Helvetica Neue',
  headerBgColor: '#5e5ecc',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#5e5ecc',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

function BookingCard(props){
  return(
    <ActivityLogTable data={[
      { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
      { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
    ]}/>
  )

}

function SearchCustomer(props){
  const {phone} = props

  // database.ref("newCustomers").once("value", (snapshot)=>{
    // let count = 0
    // let customers = snapshot.val()
    // for(let customer in customers){
    //   count ++
    //   if(parseInt(customers[customer].FirstPhone) === parseInt(phone) || 
    //     parseInt(customers[customer].SecondPhone) === parseInt(phone) || 
    //     parseInt(customers[customer].ThirdPhone) === parseInt(phone)){
    //       return(<p>{customers[customer].Name}</p>)
    //   }
    //   if (count === snapshot.val()){
    //     return <p>Not found Phone: {phone}</p>
    //   }
    // }
    
  // })
  return <p>Not found Phone: {phone}</p>
}

function Chatbot(props){
  useEffect(() => {
    console.log("reloaded the chat")
  }, [])

  return(
    <ThemeProvider theme={theme}>
      <ChatBot
        // style={{
        //   width: 'calc(100vw - 200px)'
        // }}
        width={500}
        botAvatar={"/favicon.png"}
        floating={true}
        headerTitle={"Booking Helper"}
        opened={false}
        steps={[
          {
            id: '1',
            message: 'Hey there, what is your name?',
            trigger: '2',
          },
          {
            id: '2',
            user: true,
            validator: (value) => {
              if (value === "") {
                return 'Please enter a value';
              }
              // console.log(value)
              return true;  
            },
            trigger: '3',
          },
          {
            id: '3',
            message: "Hi {previousValue}, I am still in training but I can tell you that I can show you some history of customers",
            trigger: '4'
          },
          {
            id: '4',
            message: "like this:",
            trigger: '5'
          },
          {
            id: '5',
            options: [
              { value: 'Customer', label: 'Customer', trigger: '6' },
              { value: 'Order', label: 'Order', trigger: '7' },
              { value: 'Booking', label: 'Booking', trigger: '7' },
            ],
          },
          {
            id: '6',
            component: <OrderRecordTable customerKey={"-MUgcEiKNEWRgm4TcyMX"}/>,
            trigger: '1'
          },
          {
            id: '7',
            component: <SearchCustomer phone={"{previousValue}"}/>,
            trigger: '5'
          },
        ]}
      />
    </ThemeProvider>
  )
}

export default Chatbot;
