import React, { useState, useEffect } from 'react';
import "../style.css";
import { Button, Grid, Backdrop, Chip, Paper, Zoom } from '@material-ui/core';
import GoogleLogin from 'react-google-login';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import MaterialTable from 'material-table'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import withWidth from '@material-ui/core/withWidth';
import GradeIcon from '@material-ui/icons/Grade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

// components
import Confirmation from '../components/Confirmation';
import Settings from '../components/Settings'

// import Notify from '../components/Notify';
import { database, auth } from '../firebase';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function D3Need(props) {
  const classes = useStyles();
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0)
  const [done, setDone] = useState(true)
  const [doneMsg, setDoneMsg] = useState("")
  const [customerData, setCustomerData] = useState([])
  const { width } = props;
  
  

  
  
  useEffect(() => {
    document.title = "DBB Order - Dashboard"
    // database.ref("customers").once("value", (snapshot) => {
    //   setCustomerData(Object.values(snapshot.val()).map(v => v.PersonalData))
    // })
  });
  
  const [backdropOpen, setBackdropOpen] = useState(true)

  return (
    <Zoom in={true} timeout={width === "xs" || width === "sm" ? 300 : 1}>
      <Grid container style={{paddingBottom: 32}}>
        <Backdrop open={backdropOpen} style={{zIndex: 9999}}>  
          <CircularProgress style={{color: "white", display: "block"}}/>
        </Backdrop>
        <Grid item xs={12}>
          <div style={{
            width: width === "xs" || width === "sm" ? "100%" : "calc(100% - 250px)",
            paddingLeft: width === "xs" || width === "sm" ? 0 : 244
          }}>
            <div style={{padding: 8, borderBottom: "solid 1px gainsboro"}}>
              <h1><GradeIcon style={{color: Settings.mainBackgroundColor}}/>Vet Order</h1>
            </div>
            <Grid container>
              <Grid item xs={12}>
                <iframe 
                  style={{
                    width: "100%", 
                    height: "100vh",
                    border: "none"
                  }}
                  onLoad={() => setBackdropOpen(false)}
                  src="https://docs.google.com/spreadsheets/d/1MMCc_OUcm1Ejh6nv76Nm-yklsjGrZIeyIWoDqyiapnA/edit?usp=sharing"
                ></iframe>
              </Grid>
              <Grid item xs={12} style={{marginTop: 16}}>
                <Backdrop className={classes.backdrop} open={!done} onClick={() => setDone(false)}>
                  {/* <CircularProgress color="inherit" variant="static" value={progress}/> */}
                  <CircularProgress color="inherit"/>
                </Backdrop>
              </Grid>
              <Grid item xs={12} style={{marginTop: 16}}>
                {doneMsg}
              </Grid>
            </Grid>
            
          </div>
        </Grid>

        
      </Grid>
    </Zoom>
  );
}

export default withWidth()(D3Need)