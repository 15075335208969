import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {DialogTitle, Button, Dialog, DialogContent, DialogContentText, DialogActions, Grid, CircularProgress ,Hidden, ButtonGroup } from '@material-ui/core/';
import QRCode from "react-qr-code";
import { database, auth } from '../firebase';
import QrReader from 'react-qr-reader'
import Axios from '../axios'
import { useSnackbar } from 'notistack';

const shops = [0,1,3,4]

const Attendance = (props) => {
  const { open, handleAttendanceClose, user, userRole } = props
  const [uuid, setUuid] = useState("")
  const [selectedShop, setSelectedShop] = useState("")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleAttendanceClose = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    console.log(userRole)
    if (userRole === "staff") {
      database.ref(`attendance/d${selectedShop}`).on("child_added", (data) => {
        setUuid(data.val().uuid)
        enqueueSnackbar("QR code generated", { 
          variant: 'success',     
        })
      })
  
      database.ref(`attendance/d${selectedShop}`).on("child_removed", (data) => {
        setUuid("")
        enqueueSnackbar("QR code removed", { 
          variant: 'info',     
        })
      })
  
      return () => {
        database.ref(`attendance/d${selectedShop}`).off()
      }
    }
    else if (userRole === "shop") {
      database.ref(`attendance/${user.email.substring(0, 2)}`).on("child_added", (data) => {
        setUuid(data.val().uuid)
        enqueueSnackbar("QR code generated", { 
          variant: 'success',     
        })
      })
  
      database.ref(`attendance/${user.email.substring(0, 2)}`).on("child_removed", (data) => {
        setUuid("")
        enqueueSnackbar("QR code removed", { 
          variant: 'info',     
        })
      })
  
      return () => {
        database.ref(`attendance/${user.email.substring(0, 2)}`).off()
      }
    }
    
  }, [open, selectedShop])

  const generateCode = () => {
    if (shops.includes(selectedShop)) {
      console.log(selectedShop)
      Axios.get(`generateCode/d${selectedShop}`)
      .then(res => {
        if(res) {
          // enqueueSnackbar("Code generated", { 
          //   variant: 'success',     
          // })
        }
      })
    }
    else {
      enqueueSnackbar("Select a shop first", { 
        variant: 'error',     
      })
    }
  }

  const handleScan = data => {
    if(data) {
      Axios.get(`takeAttendance/d${selectedShop}/${data}/${user.uid}`)
      .then(res => {
        if(res) {
          enqueueSnackbar(res.data.result, { 
            variant: 'success',     
          })
          handleAttendanceClose()
        }
      })
    }
  }

  const handleError = (err) => {
    console.error(err)
  }

  return (
    <Dialog
      open={open}
      onClose={handleAttendanceClose}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle 
        id="alert-dialog-title"
        style={{background: "linear-gradient(40deg, rgba(105,68,156,1) 0%, rgba(56,54,129,1) 54%, rgba(81,84,217,1) 100%)", color: "white"}}
      >
        Take Attendance
      </DialogTitle>
      <DialogContent style={{backgroundColor: new Date().getHours() >= 18 ? "#272727" : "white"}}>
          <Grid container>
            <Hidden smUp><Grid item xs={2}></Grid></Hidden>
            <Grid item xs={8} md={4} style={{padding: 8}}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "flex-end"
                }}
              >
                <img 
                  src={new Date().getHours() >= 18 ? "/img/bye.png" : "/img/hello.png"}
                  style={{
                    width: "100%"
                  }}
                />
              </div>
            </Grid>
            <Hidden smUp><Grid item xs={2}></Grid></Hidden>

            {["staff"].includes(userRole) && <Grid item xs={12} style={{padding: 8}}>
              <ButtonGroup  aria-label="small outlined button group">
                {
                  shops.map(v => 
                    <Button 
                      onClick={() => setSelectedShop(v)}
                      style={{
                        backgroundColor: selectedShop === v ? "black" : "white",
                        color: selectedShop === v ? "white" : "black"
                      }}
                    >
                      D{v}
                    </Button>)
                }
              </ButtonGroup>
            </Grid>}

            <Grid item xs={12} md={8} style={{padding: 8}}>
              {/* {!occupying ? <h2>Open your app on the phone, select <span style={{color: "red", textTransform: "uppercase"}}>{user.email.substring(0, 2)}</span> on your phone to generate the QR code</h2> : <h2>This attendance is being used by another person, who the hell are you?</h2>} */}
              {/* {occupying && <CircularProgress color="secondary" />} */}


              {["staff"].includes(userRole) && uuid === "" && <Button
                onClick={generateCode}
                style={{
                  backgroundColor: new Date().getHours() >= 18 ? "white" : "black",
                  color: new Date().getHours() >= 18 ? "black" : "white",
                  marginBottom: 8
                }}
              >
                Generate QR Code
              </Button>}

              {["shop"].includes(userRole) ? 
                <div>
                  
                  <h4 style={{color: new Date().getHours() >= 18 ? "white" : "black"}}>
                    Open the app on your phone, select <span style={{color: "red", textTransform: "uppercase"}}>{user.email.substring(0, 2)}</span> on your phone to generate the QR code
                  </h4>
                  {!!uuid ? <div style={{border: "solid 2px white", width: "fit-content"}}><QRCode size={200} value={uuid + user?.email.substring(0, 2)} /></div>: <CircularProgress/>}
                </div> : 
                <QrReader
                  delay={500}
                  style={{
                    width: "100%",
                    border: "solid 1px white"
                  }}
                  facingMode={"environment"}
                  onScan={handleScan}
                  onError={() => enqueueSnackbar("An error occurred on QR reader", { 
                    variant: 'error',     
                  })}
                />
              }
              
              
              {/* <QRCode value={"dbb" } size={188}/> */}
              {/* <CircularProgress color="secondary" /> */}
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAttendanceClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Attendance