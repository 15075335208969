import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useLocation,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Button, Hidden} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Settings from './Settings';

import { database, auth } from '../firebase';

const useStyles = makeStyles({
  sideNav:{
    background: "linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 85%)), url('https://images.pexels.com/photos/36372/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260')",
    backgroundPosition: "center",
    width: "238px",
    height: "calc(100vh)",
    position: "fixed",
    color: "white",
    textAlign: "left",
    top: "0px",
    paddingTop: 40,
    zIndex: 1,
    overflowY: "scroll"
  },
  sideLogo:{
    width: "100%"
  },
  buttonActive:{
    margin: "8px",
    width: "calc( 100% - 16px)",
    background: "white",
    color: Settings.mainBackgroundColor,
    fontSize: "16px",
    borderRadius: "40px",
    "&:hover":{
      background: "white",
    }
  },
  button:{
    margin: "8px",
    width: "calc( 100% - 16px)",
    background: "#ffffff00",
    color: Settings.mainColor,
    boxShadow: "unset",
    fontSize: "16px",
    borderRadius: "40px",
    "&:hover":{
      background: "white",
      color: Settings.mainBackgroundColor
    }
  },
});

function SideNav(props) {
  const classes = useStyles()
  const { user, userRole } = props
  const location = useLocation()
  const [testing, setTesting] = useState(false)

  useEffect(()=>{
    database.ref("testing").once("value", (snapshot) => setTesting(snapshot.val()))
    console.log(userRole)
  }, [testing])

  return(
    <Hidden smDown>
      <div className={classes.sideNav} id="sideNav">
        <div style={{padding: 8, marginBottom: 16}}>
          <img src="/img/logo_white.png" className={classes.sideLogo}/>
        </div>
        {testing && <p style={{textAlign: 'center', fontSize: 12, padding: "12px 0px", backgroundColor: "red"}}>TESTING MODE</p>}
        <p style={{textAlign: 'center', fontSize: 10}}>Version: 2.0.14</p>
        <p style={{textAlign: 'center', fontSize: 10}}>Last Update: 16 September, 2023</p>
        <hr style={{borderBottom: "none", borderTop: "solid white 1px", opacity: 0.3}}/>
        <div>
          {/* {
            auth.currentUser.email === "dbbpethouse@gmail.com" && 
            <Link to="/admin/dashboard">
              <Button className={location.pathname === "/admin/dashboard" ? classes.buttonActive : classes.button}>
                Dashboard
              </Button>
            </Link>
          } */}
          <Link to="/">
            <Button className={location.pathname === "/" ? classes.buttonActive : classes.button}>
              Customers
            </Button>
          </Link>
          {[0,1,3,4,5].map((v,i) => <Link to={"/orders/d" + v} key={i}>
            <Button className={location.pathname === "/orders/d" + v ? classes.buttonActive : classes.button}>
              D{v} Orders
            </Button>
          </Link>)}
          <Link to="/bookings">
            <Button className={location.pathname === "/bookings" ? classes.buttonActive : classes.button}>
              Bookings
            </Button>
          </Link>
          <Link to="/d3-need">
            <Button className={location.pathname === "/d3-need" ? classes.buttonActive : classes.button}>
              D3 Need
            </Button>
          </Link>
          <Link to="/vet-order">
            <Button className={location.pathname === "/vet-order" ? classes.buttonActive : classes.button}>
              Vet Order
            </Button>
          </Link>
          <Link to="/inventory">
            <Button className={location.pathname === "/inventory" ? classes.buttonActive : classes.button}>
              Inventory
            </Button>
          </Link>
          {["staff", "admin"].includes(userRole) && <Link to="/attendance">
            <Button className={location.pathname === "/attendance" ? classes.buttonActive : classes.button}>
              Attendance
            </Button>
          </Link>}
          {["admin"].includes(userRole) && <Link to="/users">
            <Button className={location.pathname === "/users" ? classes.buttonActive : classes.button}>
              Users
            </Button>
          </Link>}
        </div>
        <hr style={{borderBottom: "none", borderTop: "solid white 1px", opacity: 0.3}}/>
        <p style={{textAlign: 'center', fontSize: 10}}>Copyright © Doggie Bo Bo {new Date().getFullYear()}</p>
      </div>
    </Hidden>
  )
}

export default SideNav